import { SharedUser, User, CgUser } from './types'

export const serializeSharedWithUsers = (cgUserList: SharedUser[]) =>
  cgUserList.map(cgUser => {
    return {
      ...cgUser,
      value: cgUser.contact_id,
      company: cgUser.vendor.name,
      firstName: cgUser.first_name,
      lastName: cgUser.last_name,
      cgUserId: cgUser.id,
      label: `${cgUser.first_name} ${cgUser.last_name}`
    }
  })

export const toCgUsers = (sharedWithUsers: CgUser[]) => {
  const _toCgUsers = (options: User[]) => {
    return options.map(o => ({
      value: o.user_id || o.id,
      label: o.full_name,
      email: o.primary_email,
      company: o.org.name,
      firstName: o.given_name,
      lastName: o.family_name,
      cgUserId: o.cg_user_id,
      isDisabled:
        (o.org?.file_sharing_limit_exceeded || false) &&
        !sharedWithUsers.find(s => s?.vendor?.id === o?.org?.id)
    }))
  }
  return _toCgUsers
}
