import { useState, useMemo, useEffect } from 'react'
import s from './EventChangesStep.scss'
import { DataTableWrapper, Tooltip } from 'simple-core-ui'
import { Rule, Cell, RuleToUpdate } from '../types'
import { timezoneDate, timezoneUtils } from 'utils/helpers'
import { format } from 'date-fns'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import cn from 'classnames'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import capitalize from 'lodash/capitalize'

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const initialParams = {
  pageSize: Infinity,
  ordering: { columnKey: 'date', isDesc: false },
  search: '',
  page: 1,
  category: 'all'
}

interface Props {
  events: Rule[]
  selectedRows: number[]
  allRowsSelected: boolean
  selectAllRows: () => void
  selectRow: (id: number) => void
  eventsToUpdate: RuleToUpdate[]
  timezone: string
}

const EventChangesStep = ({
  events,
  selectedRows,
  allRowsSelected,
  selectAllRows,
  selectRow,
  eventsToUpdate,
  timezone
}: Props) => {
  const [params, setParams] = useState(initialParams)
  const [localEvents, setRules] = useState(events)

  useEffect(() => {
    setRules(events)
  }, [events])

  const toggleExpand = (id: number) => {
    const updatedEvents = localEvents.map(event => {
      if (event.id === id) {
        return {
          ...event,
          expanded: !event.expanded
        }
      }
      return event
    })
    setRules(updatedEvents)
  }

  const columns = useMemo(() => {
    return [
      {
        columnKey: 'prevDate',
        content: <b>Old Date</b>,
        isSortable: true,
        isFilterable: true,
        style: { width: '200px' },
        render: (_cell: Cell, row: Rule) => {
          const eventToUpdate = eventsToUpdate.find(e => e.id === row.id)

          return eventToUpdate?.old_date
            ? format(timezoneDate(eventToUpdate?.old_date, timezone), 'MM/dd/yyyy hh:mm a') +
                ' ' +
                (timezone || timezoneUtils.getUserTimezone())
            : '----'
        }
      },
      {
        columnKey: 'date',
        content: <b>New Date</b>,
        isSortable: true,
        isFilterable: true,
        style: { width: '200px' },
        render: (cell: Cell, row: Rule) => {
          const eventToUpdate = eventsToUpdate.find(e => e.id === row.id)
          return (
            <>
              {format(timezoneDate(cell.content, timezone), 'MM/dd/yyyy hh:mm a') +
                ' ' +
                (timezone || timezoneUtils.getUserTimezone())}
              {(eventToUpdate?.event_date_changed || eventToUpdate?.do_not_recalculate) && (
                <Tooltip
                  trigger={<AiOutlineInfoCircle className={s.info} />}
                  content={
                    eventToUpdate?.do_not_recalculate
                      ? 'This event requires court permission to be recalculated. Check the box to include the event in the update.'
                      : 'Event date was manually edited, check the box to include the date'
                  }
                  placement="top"
                />
              )}
            </>
          )
        }
      },
      {
        columnKey: 'type',
        content: <b>Event Changes</b>,
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: Rule) => {
          const eventToUpdate = eventsToUpdate.find(e => e.id === row.id)
          return eventToUpdate ? 'Update' : capitalize(cell.content)
        }
      },
      {
        columnKey: 'description',
        content: <b>Events</b>,
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: Rule) => {
          return (
            <div
              className={cn(s.description, {
                [s.descriptionCollapsed]: !row.expanded
              })}
            >
              {cell.content}
            </div>
          )
        }
      },
      {
        columnKey: 'expanded',
        isSortable: false,
        isFilterable: false,
        content: '',
        render: (_cell: Cell, row: Rule) => {
          return row.expanded ? (
            <FaChevronUp className={s.arrow} onClick={() => toggleExpand(row.id)} />
          ) : (
            <FaChevronDown className={s.arrow} onClick={() => toggleExpand(row.id)} />
          )
        }
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localEvents, eventsToUpdate])

  const updateTable = (params: Params) => {
    setParams(params)
  }

  return (
    <div style={{ marginTop: 10 }}>
      <p style={{ fontWeight: 600, fontSize: 16, position: 'relative', top: 15, left: 10 }}>
        {selectedRows.length}/{events.length} Court Rule Events selected
      </p>
      <DataTableWrapper
        params={params}
        categories={[]}
        rows={localEvents}
        columns={columns}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        hasTooltip
        categoryKey="events"
        hasActionsHeader={false}
        hasPagination={false}
        selectAllRows={selectAllRows}
        selectRow={row => selectRow(row.id)}
        selectedRows={new Set(selectedRows)}
        allRowsSelected={allRowsSelected}
        checkboxSize="md"
      />
    </div>
  )
}

export default EventChangesStep
