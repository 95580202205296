import { useState, useEffect, useMemo, useContext } from 'react'
import { AxiosError } from 'axios'
import pluralize from 'pluralize'
import moment from 'moment'
import { format, startOfMonth, endOfMonth, endOfWeek, startOfWeek } from 'date-fns'
import {
  Button,
  DataTableWrapper,
  AvatarList,
  ButtonDropdown,
  Ellipsis,
  useLoading,
  ModalContainer,
  TextInput,
  UpdateToPremiumLabel
} from 'simple-core-ui'
import s from './Tasks.scss'
import { Tabs } from './Tabs'
import {
  canEditTask,
  getSubtaskIds,
  createQueryParamsUrl,
  isTaskCalendarSyncEnabled,
  getCategoryFromUrl,
  createFiltersObjectFromQueryString
} from './utils'
import { ActionsPopover } from './ActionsPopover'
import { Task, Subtask, TaskFilters, BulkEditValues, Cell, Status, Params } from './types'
import { useImmer, Updater } from 'use-immer'
import { toTasks, fromTask, serializeBulkEditOptions } from './serializers'
import {
  hex2rgba,
  openLink,
  sortAlphabeticallyByProperty,
  isBasicTaskManagementPlan,
  removeLabelsFromURL,
  updateUrlFragment,
  deleteQueryParam,
  getUrlWithSubtab
} from 'utils/helpers'
import { BsPersonPlus } from 'react-icons/bs'
import { MdOutlineComment, MdOutlineAttachFile, MdDashboard } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import APP_ACT from 'app/actions'
import { BiSubdirectoryRight } from 'react-icons/bi'
import cn from 'classnames'
import { FaList, FaEyeSlash, FaCalendarAlt } from 'react-icons/fa'
import { AddTask } from './AddTask'
import { makeGetRequest, makeDeleteRequest, makePostRequest, makePatchRequest } from 'utils/api'
import { ConfirmationDialog } from './ConfirmationDialog'
import cloneDeep from 'lodash/cloneDeep'
import { Filters } from './Filters'
import ReactTooltip from 'react-tooltip'
import { CopyMethods } from './CopyMethods'
import { BulkEditModal } from './BulkEditModal'
import { BULK_EDIT_INITIAL_VALUES } from './constants'
import { AddTaskFromTemplateModal } from './AddTaskFromTemplateModal'
import { Link } from 'react-router-dom'
import qs, { ParsedQuery } from 'query-string'
import ThemeContext from 'context/ThemeContext'
import { CalendarSyncModal } from 'common/CalendarSyncModal'
import { CalendarView } from './CalendarView'
import { Board } from './Board'
import { Task as CalendarTask } from './CalendarView/types'
import { toTasks as toCalendarTasks } from './CalendarView/serializers'

interface Props {
  scopeId?: string
  readOnly?: boolean
  scopeName?: string
  context: 'workbench' | 'matter'
  baseUrl: string
  restrictAllActions?: boolean
}

const BULK_OPTIONS = [
  { label: 'Edit', value: 'edit' },
  { label: 'Delete', value: 'delete' }
]

const bulkEditInitialState = {
  isBulkEditDialog: false,
  options: BULK_EDIT_INITIAL_VALUES,
  isBulkEditConfirmationDialog: false
}

const updateUrlHash = (id: number | string) => {
  const parsedQuery = qs.parse(window.location.search)
  const parsedHash = qs.parse(window.location.hash, { decode: false })
  const mergedQuery = {
    ...parsedQuery,
    id
  }
  const queryString = qs.stringify(mergedQuery)
  const hashString = qs.stringify(parsedHash, { encode: false })

  window.history.replaceState(null, '', `?${queryString}${hashString ? `#${hashString}` : ''}`)
}

const isBasicPlan = isBasicTaskManagementPlan()

const Tasks = ({
  scopeId = '',
  readOnly = false,
  scopeName = '',
  context,
  baseUrl,
  restrictAllActions
}: Props) => {
  const initialTaskFilters = {
    taskType: null,
    assignees: null,
    followers: null,
    status: null,
    priority: null,
    id: null,
    createdBy: null,
    comments: null,
    fileAttachments: null,
    dueDate: null,
    createdDate: null,
    taskId: null,
    ...(context === 'workbench' ? { relatedMatter: null } : {})
  }

  const [localState, setLocalState] = useState({
    params: {
      pageSize: context === 'workbench' ? 50 : 10,
      ordering: { columnKey: 'dueDate', isDesc: false },
      search: '',
      page: 1,
      category: getCategoryFromUrl() || 'all'
    }
  })
  const { params } = localState
  const [selectedTab, setSelectedTab] = useState(params.category)
  const [oldSelectedTab, setOldSelectedTab] = useState(params.category)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [tasks, setTasks]: [Task[], Updater<Task[]>] = useImmer<Task[]>([])
  const dispatch = useDispatch()
  const [view, setView] = useState('list')
  const [oldView, setOldView] = useState('list')
  const [isAddTaskVisible, setIsAddTaskVisible] = useState(false)
  const [isAddTaskFromTemplateModalVisible, setIsAddTaskFromTemplateModalVisible] = useState(false)
  const [editedTask, setEditedTask] = useState<Task | null>(null)
  const [oldTask, setOldTask] = useState<Task | null>(null)
  const [isLoading, withLoadingLocksGetTasks] = useLoading()
  const [, withLoadingLocks] = useLoading()
  const [subTaskToDelete, setSubTaskToDelete] = useState<Task | undefined>(undefined)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showCopyMethods, setShowCopyMethods] = useState(false)
  const [showBulkDeleteConfirmation, setShowBulkDeleteConfirmation] = useState(false)
  const [showBulkDeleteNotAllowed, setShowBulkDeleteNotAllowed] = useState(false)
  const [taskFilters, setTaskFilters] = useState<TaskFilters>({
    ...initialTaskFilters,
    ...createFiltersObjectFromQueryString(window.location.href, context)
  })
  const [oldTaskFilters, setOldTaskFilters] = useState<TaskFilters>(taskFilters)
  const [bulkEdit, setBulkEdit] = useState(bulkEditInitialState)
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const [_scopeId, setScopeId] = useState(scopeId)
  const { state } = useContext(ThemeContext)
  const [totalEntries, setTotalEntries] = useState(0)
  const [filteredTotal, setFilteredTotal] = useState(0)
  const [myFollowingCount, setMyFollowingCount] = useState(0)
  const [myRequestsCount, setMyRequestsCount] = useState(0)
  const [myTasksCount, setMyTasksCount] = useState(0)
  const [isCalendarSyncModalVisible, setIsCalendarSyncModalVisible] = useState(false)
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)

  const [boardHasPagination, setBoardHasPagination] = useState<Record<string, boolean>>({
    0: false,
    1: false,
    2: false,
    3: false
  })
  const [boardColumnTotalEntries, setBoardColumnTotalEntries] = useState<Record<string, number>>({
    0: 0,
    1: 0,
    2: 0,
    3: 0
  })
  const [detailsSubtab, setDetailsSubtab] = useState('')

  // calendar
  const [calendarTasks, setCalendarTasks] = useState<CalendarTask[]>([])
  const [calendarDate, setCalendarDate] = useState(new Date())
  const [currentCalendarView, setCurrentCalendarView] = useState('month')

  const clearAllSelectedRows: () => void = () => {
    setSelectedRows([])
    setAllRowsSelected(false)
  }

  const editTask = async (task: Task | Subtask) => {
    try {
      if (task.id) {
        const tsk = await withLoadingLocks(makeGetRequest(`${baseUrl}/tasks/${task.id}/`))
        setIsAddTaskVisible(true)
        setEditedTask(toTasks([tsk])[0])
        setOldTask(toTasks([tsk])[0])
        updateUrlHash(task.id)
      } else {
        setIsAddTaskVisible(true)
        setEditedTask(task as Task)
      }
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 404) {
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'The task no longer exists',
            message: `We couldn't find the task you referenced. It may have been deleted.`,
            level: 'error'
          }
        })
      } else {
        dispatch({ type: 'API_ERROR', error })
      }
    }
    clearAllSelectedRows()
  }

  const openDetailsSubtab = (id: number, subtab: string) => {
    setDetailsSubtab(subtab)
    editTask({ id } as Task)
  }

  const columns = useMemo(() => {
    return [
      {
        columnKey: 'name',
        content: 'Task Name',
        isSortable: true,
        isFilterable: true,
        style: { maxWidth: '400px', position: 'relative' },
        render: (cell: Cell, row: Task) => {
          return (
            <>
              {row?.parent && <BiSubdirectoryRight className={s.subTaskBigIcon} />}
              <div style={{ float: 'left' }} className={cn({ [s.nameWrapper]: row?.parent })}>
                {row?.parent && (
                  <div className={s.parentName}>
                    {row.parent.isPrivate && !tasks.find(t => t.id === row.parent?.id) ? (
                      <i>
                        <FaEyeSlash className={s.hiddenIcon} />
                        Private: Parent task hidden
                      </i>
                    ) : (
                      <Ellipsis
                        className={cn(s.nameCell, {
                          [s.smallerWidth]: state.isMenuExpanded,
                          [s.workbench]: context === 'workbench'
                        })}
                        lines={1}
                      >
                        {row.parent.name}
                      </Ellipsis>
                    )}
                  </div>
                )}
                <a style={{ cursor: 'pointer' }} onClick={() => row && editTask(row)}>
                  <Ellipsis
                    className={cn(s.nameCell, {
                      [s.smallerWidth]: state.isMenuExpanded,
                      [s.workbench]: context === 'workbench'
                    })}
                  >
                    {typeof cell.content === 'string' && cell.content}
                  </Ellipsis>
                </a>
              </div>
              {!!row?.children &&
                tasks.some(t => {
                  return row?.subtasks.map(s => +s.id).includes(t.id)
                }) && (
                  <div className={s.subTasksCountWrapper}>
                    <BiSubdirectoryRight className={s.subTaskIcon} />
                    <span className={s.subTasksCount}>{row?.children}</span>
                    <span
                      onClick={() =>
                        setTasks(draft => {
                          const index = draft.findIndex(t => t.id === row?.id)
                          draft[index].expanded = !draft[index].expanded
                        })
                      }
                      className={s.plusSign}
                    >
                      {row.expanded ? '-' : '+'}
                    </span>
                  </div>
                )}
            </>
          )
        }
      },
      {
        columnKey: 'taskId',
        content: 'Task ID',
        isSortable: true,
        isFilterable: true
      },
      ...(context === 'workbench'
        ? [
            {
              columnKey: 'relatedMatter',
              content: 'Related To',
              isSortable: true,
              isFilterable: true,
              filterableBy: 'label',
              render: (cell: Cell) => {
                if (typeof cell.content === 'object' && 'label' in cell.content) {
                  return (
                    <Link to={`/v2/matters/${cell.content.value}?fromTasksWorkbench=true`}>
                      <Ellipsis width={120} lines={1}>
                        {cell.content.label}
                      </Ellipsis>
                    </Link>
                  )
                }
                return '--'
              }
            }
          ]
        : []),
      {
        columnKey: 'assignees',
        content: 'Assignee',
        isSortable: false,
        isFilterable: true,
        filterableBy: 'label',
        render: (cell: Cell, row: Task) => {
          return Array.isArray(cell.content) && !cell.content.length ? (
            !readOnly && row && canEditTask(row, context) ? (
              <span onClick={() => editTask(row)} className={s.noAssignee}>
                <BsPersonPlus />
              </span>
            ) : (
              '--'
            )
          ) : (
            <AvatarList
              size={Array.isArray(cell.content) && cell.content.length > 1 ? 'sm' : 'md'}
              limit={2}
              avatarStyles={{ marginLeft: 0 }}
              entries={sortAlphabeticallyByProperty(
                Array.isArray(cell.content) ? cell.content : [],
                'label'
              )}
            />
          )
        }
      },
      {
        columnKey: 'status',
        content: 'Status',
        isSortable: true,
        isFilterable: true,
        filterableBy: 'name',
        style: { maxWidth: '140px' },
        render: (cell: Cell, row: Task) => {
          if (cell.content === '----') {
            return '--'
          }
          const color = row?.status?.color
          return (
            <div
              className={s.level}
              style={{
                backgroundColor: color && hex2rgba(color, 0.15),
                border: `1px solid ${color}`
              }}
            >
              <Ellipsis width={100} lines={1}>
                {typeof cell.content === 'object' && 'name' in cell.content && cell.content.name}
              </Ellipsis>
            </div>
          )
        }
      },
      {
        columnKey: 'dueDate',
        content: 'Due',
        isSortable: true,
        isFilterable: true,
        render: (cell: Cell, row: Task) => {
          return (
            <span
              style={
                moment(cell.content as string).isBefore(new Date()) &&
                row?.status?.phase !== 'Complete'
                  ? { color: '#bb342f' }
                  : {}
              }
            >
              {cell.content === '----'
                ? '--'
                : moment(cell.content as string).format(
                    moment(cell.content as string).isSame(new Date(), 'year')
                      ? 'MMM DD'
                      : 'MMM DD YYYY'
                  )}
            </span>
          )
        }
      },
      {
        columnKey: 'priority',
        content: 'Priority',
        isSortable: true,
        isFilterable: true,
        filterableBy: 'name',
        style: { maxWidth: '140px' },
        render: (cell: Cell, row: Task) => {
          if (cell.content === '----') {
            return '--'
          }
          const color = row?.priority?.color
          return (
            <div
              className={s.level}
              style={{
                backgroundColor: color && hex2rgba(color, 0.15),
                border: `1px solid ${color}`
              }}
            >
              <Ellipsis width={100} lines={1}>
                {typeof cell.content === 'object' && 'name' in cell.content && cell.content.name}
              </Ellipsis>
            </div>
          )
        }
      },
      {
        columnKey: 'taskType',
        content: 'Task Type',
        isSortable: true,
        isFilterable: true,
        filterableBy: 'name',
        render: (cell: Cell) => {
          return cell.content === '----' ? (
            '--'
          ) : (
            <Ellipsis width={150} lines={1}>
              {typeof cell.content === 'object' && 'name' in cell.content && cell.content.name}
            </Ellipsis>
          )
        }
      },
      {
        columnKey: 'commentsCount',
        content: <MdOutlineComment style={{ fontSize: 20, position: 'relative', top: 5 }} />,
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: Task) => {
          const content = cell.content as string
          return (
            <div onClick={() => openDetailsSubtab(row.id, 'comments')}>
              <MdOutlineComment className={s.tableIcons} data-for={`comments-${row.id}`} data-tip />
              {+cell.content > 10 ? (
                <>
                  <span className={s.linkColor}>10+</span>
                  <ReactTooltip
                    id={`comments-${row.id}`}
                    type="light"
                    effect="solid"
                    place="top"
                    border
                  >
                    {content}
                  </ReactTooltip>
                </>
              ) : cell.content === '----' ? (
                <span className={s.linkColor}>0</span>
              ) : (
                <span className={s.linkColor}>{content}</span>
              )}
            </div>
          )
        }
      },
      {
        columnKey: 'fileAttachmentsCount',
        content: <MdOutlineAttachFile style={{ fontSize: 20, position: 'relative', top: 5 }} />,
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: Task) => {
          const content = cell.content as string
          return (
            <div onClick={() => openDetailsSubtab(row.id, 'files')}>
              <MdOutlineAttachFile className={s.tableIcons} data-for={`files-${row.id}`} data-tip />
              {+cell.content > 10 ? (
                <>
                  <span className={s.linkColor}>10+</span>
                  <ReactTooltip
                    id={`files-${row.id}`}
                    type="light"
                    effect="solid"
                    place="top"
                    border
                  >
                    {content}
                  </ReactTooltip>
                </>
              ) : cell.content === '----' ? (
                <span className={s.linkColor}>0</span>
              ) : (
                <span className={s.linkColor}>{content}</span>
              )}
            </div>
          )
        }
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, tasks])

  if (taskFilters !== oldTaskFilters && view !== 'calendar') {
    setOldTaskFilters(taskFilters)
  }

  const isFiltered = useMemo(() => {
    return Object.values(taskFilters).some(f => f !== null)
  }, [taskFilters])

  const filteredTasks = useMemo(() => {
    return tasks.filter(t => !t.hidden)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, taskFilters])

  useEffect(() => {
    setLocalState(state => ({
      ...state,
      params: {
        ...state.params,
        page: 1,
        category: selectedTab
      }
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskFilters, selectedTab, view])

  useEffect(() => {
    clearAllSelectedRows()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskFilters, selectedTab, localState.params.search, view])

  const updateUrl = (queryString: string) => {
    const frg = updateUrlFragment(queryString)

    window.history.replaceState(null, '', frg)
  }

  const getFilterTasksUrl = async (queryString: string) => {
    const splitParams = (obj: ParsedQuery<string>) => {
      const baseKeys = ['columnKey', 'isDesc', 'page_number', 'page_size', 'category']

      const base: { [key: string]: string | (string | null)[] | null } = {}
      const filters: { [key: string]: string | (string | null)[] | null } = {}

      Object.keys(obj).forEach(key => {
        if (baseKeys.includes(key)) {
          base[key] = obj[key]
        } else {
          filters[key] = obj[key]
        }
      })

      return [base, filters]
    }

    if (`${baseUrl}/tasks/?${queryString}`.length > 2083) {
      const [baseParams, filters] = splitParams(qs.parse(queryString))
      const { filter_id } = await makePostRequest('/task-management/tasks/filters', filters)

      return `${baseUrl}/tasks/?${qs.stringify({ ...baseParams, filter_id })}`
    }
    return `${baseUrl}/tasks/?${queryString}`
  }

  const fetchBoardTasks = async (url: string) => {
    try {
      const response: { rows: Task[] } = { rows: [] }
      const parsedParams = qs.parse(url)

      const allStatusesIds = ['0', '1', '2', '3']
      const operator =
        typeof parsedParams.status === 'string' ? parsedParams.status.split(':')[0] : ''
      const statusesIds =
        typeof parsedParams.status === 'string' ? parsedParams.status.split(':')[1].split(',') : []

      const statusesIdsArr = statusesIds.length
        ? allStatusesIds.filter(id =>
            operator === 'is' ? statusesIds.includes(id) : !statusesIds.includes(id)
          )
        : allStatusesIds

      const requests = statusesIdsArr.map(async statusId => {
        const finalParams = {
          ...parsedParams,
          page_number: 1,
          page_size: 10,
          status: `is:${statusId}`
        }

        const updatedUrl = qs.stringify(finalParams, { encode: false })
        return makeGetRequest(await getFilterTasksUrl(updatedUrl.split('?')[1]))
      })

      const responses = await withLoadingLocks(Promise.allSettled(requests))

      setBoardColumnTotalEntries({ 0: 0, 1: 0, 2: 0, 3: 0 })

      responses.forEach((r, index) => {
        const statusId = statusesIdsArr[index]

        if (r.status === 'fulfilled') {
          response.rows.push(...r.value.rows)
          setBoardHasPagination(prevState => ({ ...prevState, [statusId]: r.value.more }))
          setBoardColumnTotalEntries(prevState => ({
            ...prevState,
            [statusId]: r.value.totalEntries
          }))
        } else {
          dispatch({ type: APP_ACT.API_ERROR, error: r.reason })
        }
      })

      return response
    } catch (error) {
      dispatch({ type: APP_ACT.API_ERROR, error })
    }
  }

  const fetchTasks = async ({
    filters,
    tableParams,
    cb,
    hash,
    tab,
    concatTasks = false
  }: {
    filters?: TaskFilters
    tableParams?: Params
    cb?: (url: string, hasMoreResults: boolean) => void
    hash?: string
    tab?: string
    concatTasks?: boolean
  } = {}) => {
    const taskFiltersMap = {
      taskType: 'type',
      status: 'status',
      priority: 'priority',
      name: 'name',
      dueDate: 'due_date',
      taskId: 'task_id',
      relatedMatter: 'matter_id'
    }
    try {
      const base = `columnKey=${
        taskFiltersMap[(tableParams || params).ordering.columnKey as keyof typeof taskFiltersMap]
      }&isDesc=${Number((tableParams || params).ordering.isDesc)}&search=${encodeURIComponent(
        (tableParams || params).search
      )}&page_number=${(tableParams || params).page}&page_size=${(tableParams || params).pageSize}`

      const queryString = hash
        ? removeLabelsFromURL(hash.split('#')[1])
        : `${base}&${createQueryParamsUrl(filters || taskFilters)}`

      let response
      if (view === 'board' && !concatTasks) {
        response = await fetchBoardTasks(`${baseUrl}/tasks/?${queryString}`)
      } else {
        response = await withLoadingLocksGetTasks(
          makeGetRequest(await getFilterTasksUrl(queryString))
        )
      }

      const { rows, totalEntries, more, currentPageNumber: responsePage } = response

      // handling delete related operations when there is no tasks on the last page
      if (responsePage < params.page) {
        setLocalState(currentState => ({
          ...currentState,
          params: { ...currentState.params, page: responsePage }
        }))
        const urlParams = window.location.hash.split('#')[1]
        updateUrl(`${urlParams?.replace(/(page_number=)\d+/, `page_number=${responsePage}`)}`)
      }

      const serializedRows = toTasks(rows, (tasks: Task[]) => {
        if (context === 'workbench') {
          return tasks.map(t =>
            t?.relatedMatter?.status === 'closed' || !t?.relatedMatter?.canEdit
              ? { ...t, canEdit: false }
              : t
          )
        }
        return tasks
      })

      if (concatTasks) {
        setTasks([...tasks, ...serializedRows])
      } else {
        setTasks(serializedRows)
      }

      setFilteredTotal(totalEntries)

      cb?.(
        hash
          ? hash.split('#')[1]
          : `${base}&category=${tab ?? selectedTab}&${createQueryParamsUrl(
              filters || taskFilters,
              true
            )}`,
        more
      )
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const getInitFiltersForSelectedTab = (selectedTab: string) => {
    const loggedInUser = window.credentials.user
    const loggedInUserFilterObj = {
      operator: {
        label: 'Is',
        value: 'IS'
      },
      values: [
        {
          value: loggedInUser.id,
          label: loggedInUser.firstName + ' ' + loggedInUser.lastName
        }
      ]
    }

    switch (selectedTab) {
      case 'mine':
        return {
          ...initialTaskFilters,
          assignees: loggedInUserFilterObj
        }
      case 'requests':
        return {
          ...initialTaskFilters,
          createdBy: loggedInUserFilterObj
        }
      case 'watching':
        return {
          ...initialTaskFilters,
          followers: loggedInUserFilterObj
        }
      default:
        return initialTaskFilters
    }
  }

  const clearFilters = () => {
    const filters = getInitFiltersForSelectedTab(selectedTab)
    setTaskFilters(filters)
    fetchTasks({ filters, cb: updateUrl })
  }

  const onChangeTabCb = (selectedTab: string) => {
    setOldSelectedTab(selectedTab)

    if (selectedTab === oldSelectedTab) return

    const newFilters = getInitFiltersForSelectedTab(selectedTab)

    fetchTasks({ filters: newFilters, cb: updateUrl, tab: selectedTab })
    setTaskFilters(newFilters)
  }

  const changeTab = (tab: string) => {
    setSelectedTab(tab)
    onChangeTabCb(tab)
  }

  useEffect(() => {
    const hash = window.location.hash
    const search = window.location.search
    const parsedHash = qs.parse(hash)
    const parsedQuery = qs.parse(search)

    if (parsedQuery.subtab && parsedQuery.subtab !== 'list') return

    const taskFiltersMap = {
      type: 'taskType',
      status: 'status',
      priority: 'priority',
      name: 'name',
      due_date: 'dueDate',
      task_id: 'taskId',
      matter_id: 'relatedMatter'
    }

    fetchTasks({ cb: updateUrl, hash })

    setLocalState(state => ({
      ...state,
      params: {
        ...state.params,
        page: parsedHash.page_number ? +parsedHash.page_number : 1,
        pageSize: parsedHash.page_size ? +parsedHash.page_size : context === 'workbench' ? 50 : 10,
        search: parsedHash.search && typeof parsedHash.search === 'string' ? parsedHash.search : '',
        ordering: {
          columnKey: parsedHash.columnKey
            ? taskFiltersMap[parsedHash.columnKey as keyof typeof taskFiltersMap]
            : 'dueDate',
          isDesc: Boolean(parsedHash.isDesc ? +parsedHash.isDesc : false)
        },
        category: getCategoryFromUrl() || 'all'
      }
    }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (oldView !== view && view !== 'calendar') {
      fetchTasks({ cb: updateUrl })
    }
    setOldView(view)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])

  const fetchTasksCounts = async () => {
    try {
      const {
        totalEntries,
        myTasksCount,
        myRequestsCount,
        myFollowingCount
      } = await withLoadingLocks(makeGetRequest(`${baseUrl}/tasks/counts`))
      setTotalEntries(totalEntries)
      setMyTasksCount(myTasksCount)
      setMyRequestsCount(myRequestsCount)
      setMyFollowingCount(myFollowingCount)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    if (view === 'calendar') return
    fetchTasksCounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (view !== 'list') return
    const parsedQuery = qs.parse(window.location.search)

    if (parsedQuery.tab !== 'tasks' && context !== 'workbench') return

    if (parsedQuery.id) {
      editTask({ id: +parsedQuery.id } as Task)
    }
    if (parsedQuery.category) {
      changeTab(parsedQuery.category as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])

  useEffect(() => {
    const parsedQuery = qs.parse(window.location.search)
    if (parsedQuery.subtab) {
      setView(parsedQuery.subtab as string)
    }
  }, [])

  useEffect(() => {
    if (view !== 'list') return
    // toggle subtasks visibility if parent is collapsed/expanded
    for (const task of tasks) {
      if (!task.parent && task.subtasks.length) {
        const subtasksArr = task.subtasks.map(s => s.id)
        setTasks(draft => {
          for (const i of subtasksArr) {
            const index = draft.findIndex(t => t.id === +i)
            if (draft[index]) {
              draft[index].hidden = task.expanded ? false : true
            }
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, view])

  const selectRow = ({ id }: { id: number }) => {
    setAllRowsSelected(false)
    const subtaskIds = getSubtaskIds(tasks, id)

    if (selectedRows.includes(id)) {
      const parentId = tasks.find(task => task.id === id)?.parent?.id
      setSelectedRows(prevSelectedRows =>
        prevSelectedRows.filter(
          rowId => rowId !== id && (parentId ? rowId !== parentId : !subtaskIds.includes(rowId))
        )
      )
    } else {
      setSelectedRows(prevSelectedRows => [...new Set([...prevSelectedRows, id, ...subtaskIds])])
    }
  }

  const selectAllRows = () => {
    setAllRowsSelected(allRowsSelected => !allRowsSelected)
    setSelectedRows(
      allRowsSelected ? [] : filteredTasks.filter(r => r.canEdit !== false).map(t => t.id)
    )
  }

  const updateTable = (params: Params) => {
    setLocalState({
      ...localState,
      params
    })

    fetchTasks({ filters: taskFilters, tableParams: params, cb: updateUrl })
  }

  const downloadXlsx = async () => {
    const { columnKey, isDesc } = params.ordering
    const mappedTab: Record<string, string> = {
      requests: 'myRequests',
      mine: 'myTasks',
      watching: 'following'
    }

    clearAllSelectedRows()

    const url = `${baseUrl}/tasks/export/?columnKey=${columnKey}&isDesc=${+isDesc}${
      selectedTab !== 'all' ? `&${mappedTab[selectedTab]}` : ''
    }&${createQueryParamsUrl(taskFilters)}&search=${encodeURIComponent(params.search)}${
      context === 'workbench' ? '&template_id=is_not_set' : ''
    }`

    const { is_async } = await makeGetRequest(`${url}&check=true`)
    if (!is_async) {
      openLink(url)
    } else {
      setIsDownloadModalOpen(true)
    }
  }

  const showDeleteModal = (task: Task) => {
    setShowDeleteConfirmation(true)
    setEditedTask(task)
  }

  const deleteSubTask = (id: number) => {
    setSubTaskToDelete(tasks.find(task => task.id === id))
    setShowDeleteConfirmation(true)
  }

  const showCopyMethodsModal = (task: Task) => {
    setShowCopyMethods(true)
    setEditedTask(task)
  }

  const updateTableRowsWithNewValues = (rows: Task[], subtaskId?: number) => {
    if (editedTask?.parent || subtaskId) {
      const subtask = subtaskId ? tasks.find(t => t.id === subtaskId) : editedTask
      rows = rows.map(row =>
        row.id === subtask?.parent?.id
          ? {
              ...row,
              subtasks: row.subtasks.filter(({ id }) => +id !== subtask.id),
              children: row.children - 1
            }
          : row
      )
    }
    if (editedTask?.children && !subtaskId) {
      const children = rows.filter(t => t.parent?.id === editedTask?.id)
      children.forEach(c => {
        const index = rows.findIndex(t => t.id === c.id)
        rows.splice(index, 1)
      })
    }

    return rows
  }

  const fetchCalendarTasks = async (date: Date, currentView: string) => {
    const DATE_FORMAT = 'yyyy-MM-dd'
    const startDate = startOfMonth(date)
    const endDate = endOfMonth(date)
    try {
      let endpoint = ''

      if (currentView === 'month') {
        endpoint = `${baseUrl}/tasks/?columnKey=due_date&isDesc=0&due_date=is_between:${format(
          startDate,
          DATE_FORMAT
        )},${format(endDate, DATE_FORMAT)}`
      } else if (currentView === 'week') {
        const firstDayOfWeek = startOfWeek(date)
        const lastDayOfWeek = endOfWeek(date)
        endpoint = `${baseUrl}/tasks/?columnKey=due_date&isDesc=0&due_date=is_between:${format(
          firstDayOfWeek,
          DATE_FORMAT
        )},${format(lastDayOfWeek, DATE_FORMAT)}`
      } else if (currentView === 'day') {
        endpoint = `${baseUrl}/tasks/?columnKey=due_date&isDesc=0&due_date=is:${format(
          date,
          DATE_FORMAT
        )}`
      }

      const { rows } = await withLoadingLocks(makeGetRequest(endpoint))

      const serializedTasks = toCalendarTasks(rows)
      setCalendarTasks(sortAlphabeticallyByProperty(serializedTasks, 'name'))
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const toggleAddTaskSidebar = () => {
    setIsAddTaskVisible(!isAddTaskVisible)
    setEditedTask(null)
    setDetailsSubtab('')
  }

  const refreshTable = (toggleSidebar: boolean = true) => {
    if (view === 'calendar') {
      fetchCalendarTasks(calendarDate, currentCalendarView)
      if (toggleSidebar) {
        toggleAddTaskSidebar()
      }
    } else {
      fetchTasks()
      fetchTasksCounts()
    }
  }

  const onDeleteTask = async (subtaskId?: number) => {
    const taskIdToDelete = subtaskId ?? editedTask?.id
    try {
      await makeDeleteRequest(`${baseUrl}/tasks/${taskIdToDelete}/`)

      let rows = cloneDeep(tasks)

      rows = updateTableRowsWithNewValues(rows, subtaskId)

      subtaskId && setEditedTask(rows.filter(e => e.id === editedTask?.id)[0])
      clearAllSelectedRows()

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: 'Task successfully deleted',
          level: 'success'
        }
      })
      refreshTable()
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
    setShowDeleteConfirmation(false)
    subTaskToDelete && setSubTaskToDelete(undefined)
  }

  const duplicateTask = async (
    task: Task | Subtask,
    parentId?: number | string | null,
    includeSubtasks?: boolean
  ) => {
    try {
      await withLoadingLocks(
        makePostRequest(
          `/task-management/matters/${_scopeId ||
            String(task?.relatedMatter?.value ?? '')}/tasks/clone/`,
          {
            object_id: task.id,
            ...(includeSubtasks ? { include_subtasks: true } : {})
          }
        )
      )

      if (parentId) {
        editTask({ ...task, id: +parentId })
      }

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Task ${task.name} successfully copied.`,
          level: 'success'
        }
      })

      refreshTable()
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
    clearAllSelectedRows()
  }

  const renderCustomAction = (row: Task) => {
    if (readOnly || !canEditTask(row, context)) {
      return <span className={s.actionsPlaceholder}>...</span>
    }
    return (
      <ActionsPopover
        task={row}
        editTask={() => editTask(row)}
        deleteTask={showDeleteModal}
        duplicateTask={row.children > 0 ? showCopyMethodsModal : duplicateTask}
      />
    )
  }

  const toggleAddTaskFromTemplateModal = () => {
    setIsAddTaskFromTemplateModalVisible(!isAddTaskFromTemplateModalVisible)
  }

  const saveTask = async (
    task: Task,
    isEdit: boolean | undefined,
    callback?: (task?: Task) => void,
    shouldRefreshTable?: boolean
  ) => {
    try {
      if (task.id) {
        const index = tasks.findIndex(p => p.id === task.id)
        const scopeIdFallback = _scopeId || String(editedTask?.relatedMatter?.value ?? '')

        const response = await withLoadingLocks(
          makePatchRequest(
            `/task-management/matters/${scopeIdFallback}/tasks/${task.id}/`,
            fromTask(task, scopeIdFallback, isEdit)
          )
        )
        const formattedResponse = toTasks([response])[0]
        setTasks(draft => {
          draft[index] = formattedResponse
        })
        callback && callback(formattedResponse)
        setOldTask(formattedResponse)
        setEditedTask(formattedResponse)

        if (shouldRefreshTable) {
          refreshTable()
        }
      } else {
        const newTask = await withLoadingLocks(
          makePostRequest(`/task-management/matters/${_scopeId}/tasks/`, fromTask(task, _scopeId))
        )
        // fetch newly created task
        editTask(toTasks([newTask])[0])
        refreshTable()
      }

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Task ${task?.name ?? editedTask?.name} successfully ${
            isEdit ? 'updated' : 'created'
          }.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
    clearAllSelectedRows()
  }

  const updateBulkTasks = async () => {
    try {
      await withLoadingLocks(
        makePatchRequest(
          `${baseUrl}/tasks/bulk-operations/`,
          serializeBulkEditOptions(bulkEdit.options, selectedRows)
        )
      )
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: `${pluralize('task', selectedRows.length, true)} successfully updated.`,
          level: 'success'
        }
      })
      refreshTable()
    } catch (err) {
      const error = err as AxiosError
      const customMessage = `Failed to bulk edit the ${pluralize(
        'task',
        selectedRows.length
      )}. Please try again later. If the issue persists, please contact support for further assistance.`
      dispatch({
        type: APP_ACT.API_ERROR,
        error: { ...error, response: { ...error.response, data: customMessage } }
      })
    }
    setBulkEdit(bulkEditInitialState)
    clearAllSelectedRows()
  }

  const deleteBulkTasks = async () => {
    setShowBulkDeleteConfirmation(false)
    try {
      await withLoadingLocks(
        makeDeleteRequest(`${baseUrl}/tasks/bulk-operations/`, {
          data: { task_ids: selectedRows }
        })
      )
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: `${pluralize('task', selectedRows.length, true)} successfully deleted.`,
          level: 'success'
        }
      })
      refreshTable()
    } catch (err) {
      const error = err as AxiosError
      const customMessage =
        'Failed to bulk delete the tasks. Please try again later. If the issue persists, please contact support for further assistance.'
      dispatch({
        type: APP_ACT.API_ERROR,
        error: { ...error, response: { ...error.response, data: customMessage } }
      })
    }
    clearAllSelectedRows()
  }

  const handleBulkDeleteAction = () => {
    const isSelectedTasksWithHiddenSubs = filteredTasks.some(
      task => selectedRows.includes(task.id) && task.hasHiddenSubtasks
    )
    isSelectedTasksWithHiddenSubs
      ? setShowBulkDeleteNotAllowed(true)
      : setShowBulkDeleteConfirmation(true)
  }

  const triggerBulkAction = (option: { value: string; label: string }) => {
    switch (option.value) {
      case 'edit':
        setBulkEdit(state => ({ ...state, isBulkEditDialog: true }))
        break
      case 'delete':
        handleBulkDeleteAction()
        break
      default:
        break
    }
  }

  const bulkActionsButton = (
    <ButtonDropdown
      displayText={`Bulk Actions (${selectedRows.length})`}
      options={BULK_OPTIONS}
      style={{ padding: '10px', borderRadius: '4px' }}
      listStyles={{ width: '100%', top: '42px' }}
      listItemStyles={{ fontSize: '14px' }}
      onSelect={triggerBulkAction}
    />
  )

  const cantBulkDeleteConfirmationContent = () => {
    const cantDeleteTasksList = filteredTasks
      .filter(task => selectedRows.includes(task.id) && task.hasHiddenSubtasks)
      .map(task => task.taskId)
    const cantDeleteTasks = cantDeleteTasksList.join(', ')
    const tasks = pluralize('task', cantDeleteTasksList.length)
    const these = pluralize('this', cantDeleteTasksList.length)
    return (
      <>
        <div className={s.cantDeleteReason}>
          {`The following ${tasks} have associated private task(s) and cannot be deleted: ${cantDeleteTasks}.`}
        </div>
        <div>{`Remove ${these} ${tasks} from your bulk selection to proceed.`}</div>
      </>
    )
  }

  const changeTaskStatus = async (
    targetLaneId: number,
    id: number | string,
    sourceLaneId: number,
    cb?: (sourceLaneId: number, targetLaneId: number) => void
  ) => {
    const task = tasks.find(t => +t.id === +id)
    if (!task) return

    try {
      await withLoadingLocks(
        makePatchRequest(
          `/task-management/matters/${task.relatedMatter?.value ?? scopeId}/tasks/${task.id}/`,
          fromTask(
            { status: { id: targetLaneId } as Status } as Task,
            String(task.relatedMatter?.value ?? scopeId),
            true
          )
        )
      )
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: 'Task status successfully updated.',
          level: 'success'
        }
      })
      fetchTasksCounts()

      cb?.(sourceLaneId, targetLaneId)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  return (
    <section>
      {isAddTaskVisible && (
        <AddTask
          toggleAddTaskSidebar={toggleAddTaskSidebar}
          tasks={tasks.map(t => ({ id: t.id, name: t.name }))}
          fetchTasks={fetchTasks}
          scopeName={scopeName}
          task={editedTask}
          saveTask={saveTask}
          onDeleteTask={deleteSubTask}
          setTasks={setTasks}
          scopeId={scopeId}
          oldTask={oldTask}
          onEditSubtask={editTask}
          duplicateTask={duplicateTask}
          readOnly={readOnly}
          context={context}
          setScopeId={setScopeId}
          openedTab={detailsSubtab}
          refreshTable={refreshTable}
        />
      )}
      <div
        style={{ marginBottom: 24, boxShadow: '0 0 4px 0 #d4d4d4' }}
        className={cn({ [s.noActions]: isAddTaskVisible || restrictAllActions })}
      >
        <div style={{ minHeight: '70vh', backgroundColor: '#fff', padding: '12px 24px 24px 24px' }}>
          <div className={s.header}>
            <h2 className={s.title} data-testid="title">
              Tasks
            </h2>
            <div className={s.tabs}>
              <span
                className={cn({ [s.selected]: view === 'list' })}
                onClick={() => {
                  setView('list')
                  const newUrl = deleteQueryParam('subtab')
                  window.history.replaceState(null, '', newUrl)
                }}
              >
                <FaList /> List
              </span>
              {context !== 'workbench' && (
                <span
                  className={cn({ [s.selected]: view === 'calendar' })}
                  onClick={() => {
                    setView('calendar')
                    const newUrl = getUrlWithSubtab('calendar', true)
                    window.history.replaceState(null, '', newUrl)
                  }}
                >
                  <FaCalendarAlt /> Calendar
                </span>
              )}
              <span
                className={cn({ [s.selected]: view === 'board' })}
                onClick={() => {
                  setView('board')
                  const newUrl = getUrlWithSubtab('board')
                  window.history.replaceState(null, '', newUrl)
                }}
              >
                <MdDashboard /> Board
              </span>
            </div>
            <span className={s.rightActions}>
              {!readOnly && isTaskCalendarSyncEnabled() && (
                <>
                  <div data-for="buttonTooltip" data-tip className={s.toolTipWrapper}>
                    <Button
                      style={{
                        padding: '8px 15px',
                        whiteSpace: 'nowrap',
                        position: 'relative',
                        bottom: 2
                      }}
                      key="sync"
                      onClick={() => setIsCalendarSyncModalVisible(true)}
                      isPrimary
                      isOutline
                      hasNewDesign
                      isDisabled={isBasicPlan}
                    >
                      <FaCalendarAlt className={s.calendarIcon} /> Calendar Sync{' '}
                      {isBasicPlan && <UpdateToPremiumLabel />}
                    </Button>
                  </div>
                  <ReactTooltip
                    id="buttonTooltip"
                    type="light"
                    effect="solid"
                    place="top"
                    border
                    disable={!isBasicPlan}
                    className={s.tooltipPopup}
                  >
                    Contact customer success to upgrade.
                  </ReactTooltip>
                </>
              )}
              {view === 'list' && (
                <Button
                  style={{ padding: '10px 15px' }}
                  key="download"
                  onClick={downloadXlsx}
                  isPrimary
                  isOutline
                  hasNewDesign
                >
                  Download
                </Button>
              )}
              {!readOnly && (
                <ButtonDropdown
                  displayText="Add"
                  onSelect={option => {
                    if (option.value === 'simpleTask') {
                      toggleAddTaskSidebar()
                    } else {
                      toggleAddTaskFromTemplateModal()
                    }
                    clearAllSelectedRows()
                  }}
                  options={[
                    { label: 'Create a task', value: 'simpleTask' },
                    {
                      label: 'Create task from template',
                      value: 'fromTemplateTask',
                      isPremium: !isBasicPlan,
                      hasTooltip: isBasicPlan
                    }
                  ]}
                  isPrimary
                  alignRight
                  listStyles={{ marginTop: 12, fontSize: 14 }}
                  style={{
                    padding: '10px 15px',
                    borderRadius: '4px',
                    marginLeft: 10,
                    position: 'relative',
                    bottom: 2
                  }}
                  listItemStyles={{ fontSize: 14, margin: '10px 0' }}
                  hasNewDesign
                />
              )}
            </span>
          </div>
          {view !== 'calendar' && (
            <>
              <Tabs
                selectedTab={selectedTab}
                setSelectedTab={changeTab}
                myTasksCount={myTasksCount}
                myRequestsCount={myRequestsCount}
                myFollowingCount={myFollowingCount}
                totalTasksCount={totalEntries}
              />
              {view === 'board' && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between'
                  }}
                >
                  <Filters
                    taskFilters={taskFilters}
                    setTaskFilters={filters => {
                      setTaskFilters(filters)
                      fetchTasks({ filters, cb: updateUrl })
                    }}
                    clearFilters={clearFilters}
                    baseUrl={baseUrl}
                    scopeId={scopeId}
                    context={context}
                  />
                  <div className={s.searchContainer}>
                    Search:{' '}
                    <TextInput
                      value={params.search}
                      placeholder="Start typing to search"
                      onChange={v => {
                        updateTable({ ...params, search: v })
                      }}
                      style={{ width: 220 }}
                      debounceDelay={500}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {view === 'list' && (
            <DataTableWrapper
              isLoading={isLoading}
              remotePagination
              alwaysShowLoadingSkeleton
              params={params}
              categories={[]}
              rows={filteredTasks}
              totalEntries={totalEntries}
              filteredTotal={filteredTotal}
              columns={columns}
              updateTable={updateTable}
              panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
              className={s.itemsTable}
              customAction={renderCustomAction}
              hasActions={!readOnly}
              alwaysShowActions
              categoryKey="task"
              hasTooltip
              selectAllRows={!readOnly ? selectAllRows : undefined}
              selectRow={!readOnly ? selectRow : undefined}
              selectedRows={new Set(selectedRows)}
              allRowsSelected={allRowsSelected}
              checkboxSize="md"
              borderPosition="left"
              tooltipClassName={s.tooltip}
              filters={
                <Filters
                  taskFilters={taskFilters}
                  setTaskFilters={filters => {
                    setTaskFilters(filters)
                    fetchTasks({ filters, cb: updateUrl })
                  }}
                  clearFilters={clearFilters}
                  baseUrl={baseUrl}
                  scopeId={scopeId}
                  context={context}
                />
              }
              bulkActions={bulkActionsButton}
              customStatusText={
                !isFiltered && filteredTasks.length === 0
                  ? 'There are currently none, click Add to get started.'
                  : undefined
              }
            />
          )}
          {view === 'calendar' && context !== 'workbench' && (
            <CalendarView
              baseUrl={baseUrl}
              fetchCalendarTasks={fetchCalendarTasks}
              changeTab={setView}
              tasks={calendarTasks}
              setTasks={setCalendarTasks}
              date={calendarDate}
              setDate={setCalendarDate}
              currentView={currentCalendarView}
              setCurrentView={setCurrentCalendarView}
            />
          )}
          {view === 'board' && (
            <Board
              tasks={filteredTasks}
              readOnly={readOnly}
              editTask={editTask}
              onDragEnd={changeTaskStatus}
              fetchTasks={fetchTasks}
              filters={taskFilters}
              boardHasPagination={boardHasPagination}
              boardColumnTotalEntries={boardColumnTotalEntries}
            />
          )}
        </div>
      </div>
      {showDeleteConfirmation && (
        <ConfirmationDialog
          title={`Delete this ${editedTask?.parent || subTaskToDelete ? 'subtask' : 'task'}?`}
          confirmText="Delete"
          content={`This will also delete all of it's ${
            editedTask?.subtasks.length && !subTaskToDelete ? 'subtasks, ' : ''
          }files and comments.`}
          onConfirm={() => (subTaskToDelete ? onDeleteTask(subTaskToDelete.id) : onDeleteTask())}
          onCancel={() => {
            setShowDeleteConfirmation(false)
            subTaskToDelete && setSubTaskToDelete(undefined)
          }}
        />
      )}
      {showBulkDeleteConfirmation && (
        <ConfirmationDialog
          title={`Delete ${pluralize('task', selectedRows.length, true)}?`}
          confirmText="Delete"
          content="This will also delete all associated subtasks, files, and comments? This action cannot be undone."
          onConfirm={deleteBulkTasks}
          onCancel={() => setShowBulkDeleteConfirmation(false)}
        />
      )}
      {showBulkDeleteNotAllowed && (
        <ConfirmationDialog
          title={`Can’t delete ${selectedRows.length} selected ${pluralize(
            'task',
            selectedRows.length
          )}`}
          content={cantBulkDeleteConfirmationContent()}
          hideButtons
          onCancel={() => setShowBulkDeleteNotAllowed(false)}
        />
      )}
      {showCopyMethods && (
        <CopyMethods
          onConfirm={(includeSubtasks: boolean) => {
            editedTask && duplicateTask(editedTask, null, includeSubtasks)
            setShowCopyMethods(false)
          }}
          onCancel={() => setShowCopyMethods(false)}
        />
      )}
      {bulkEdit.isBulkEditDialog && (
        <BulkEditModal
          tasksNumber={selectedRows.length}
          matterId={+scopeId}
          onUpdate={(options: BulkEditValues) =>
            setBulkEdit({ isBulkEditDialog: false, options, isBulkEditConfirmationDialog: true })
          }
          onCancel={() => setBulkEdit(bulkEditInitialState)}
          context={context}
        />
      )}
      {bulkEdit.isBulkEditConfirmationDialog && (
        <ConfirmationDialog
          title={`Update ${pluralize('task', selectedRows.length, true)}`}
          confirmText="Update"
          content={`Are you sure you want to update the ${pluralize(
            'task',
            selectedRows.length,
            true
          )} you selected?`}
          onConfirm={updateBulkTasks}
          onCancel={() => setBulkEdit(bulkEditInitialState)}
        />
      )}
      {isAddTaskFromTemplateModalVisible && (
        <AddTaskFromTemplateModal
          toggleAddTaskFromTemplateModal={toggleAddTaskFromTemplateModal}
          scopeId={scopeId}
          refreshTable={refreshTable}
          context={context}
        />
      )}
      {isCalendarSyncModalVisible && (
        <CalendarSyncModal
          toggleModal={() => setIsCalendarSyncModalVisible(!isCalendarSyncModalVisible)}
        />
      )}
      {isDownloadModalOpen && (
        <ModalContainer
          title="We are working on your download"
          content="You’ll receive an email once your export is ready."
          confirmText="OK"
          confirmCb={() => setIsDownloadModalOpen(false)}
          cancelCb={() => setIsDownloadModalOpen(false)}
          size="sm"
          hideCancelBtn
        />
      )}
    </section>
  )
}

export default Tasks
