import { useState, CSSProperties, useEffect, useMemo, Dispatch, SetStateAction } from 'react'
import s from './CalendarView.scss'
import { Calendar, dateFnsLocalizer, DateLocalizer } from 'react-big-calendar'
import { format, parse, setHours, startOfWeek, getDay, setMinutes } from 'date-fns'
import enUS from 'date-fns/locale/en-US'
import { CustomHeader } from './CustomHeader'
import cn from 'classnames'
import { CheckboxContainer, Popover } from 'simple-core-ui'
import { Event } from './types'
import { DayPicker } from 'react-day-picker8'
import { BsCalendar2Fill } from 'react-icons/bs'
import { EventDetails } from 'calendar_workbench/CalendarView/EventDetails'

const locales = {
  'en-US': enUS
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
  dayFormat: (date: Date, culture: string, localizer: DateLocalizer) =>
    localizer.format(date, 'd', culture)
})

const eventStyleGetter = (event: Event) => {
  return {
    className: event.isExternal ? 'externalEvent' : 'internalEvent'
  }
}

const addIcon = (event: Event) => {
  return (
    <div style={{ display: 'flex' }}>
      <div className={event.isExternal ? s.ruleIconWrapper : s.eventIconWrapper}>
        <BsCalendar2Fill className={s.eventIcon} />
      </div>
      <p
        style={{
          marginLeft: 5,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {event.title}
      </p>
    </div>
  )
}

const CustomWeekHeader = ({
  date,
  weekdayStyles,
  dayStyles,
  styles = {}
}: {
  date: Date
  weekdayStyles?: CSSProperties
  dayStyles?: CSSProperties
  styles?: CSSProperties
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', ...styles }}>
      <span className={cn('weekday', s.weekday)} style={weekdayStyles}>
        {format(date, 'E').toUpperCase()}
      </span>
      <span className={cn('day', s.day)} style={dayStyles}>
        {format(date, 'dd')}
      </span>
    </div>
  )
}

interface Props {
  changeTab?: (tab: string) => void
  fetchCalendarEvents: (date: Date, currentView: string) => void
  events: Event[]
  setEvents: Dispatch<SetStateAction<Event[]>>
  date: Date
  setDate: Dispatch<SetStateAction<Date>>
  currentView: string
  setCurrentView: Dispatch<SetStateAction<string>>
}

const CalendarView = ({
  fetchCalendarEvents,
  changeTab,
  events,
  date,
  setDate,
  currentView,
  setCurrentView
}: Props) => {
  const [eventsSelected, setEventsSelected] = useState(true)
  const [rulesSelected, setRulesSelected] = useState(true)

  useEffect(() => {
    fetchCalendarEvents(date, currentView)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, currentView])

  const handleDaySelect = (date: Date) => {
    setDate(date)
  }

  const filteredEvents = useMemo(() => {
    if (!eventsSelected && !rulesSelected) {
      return []
    }

    return events.filter(
      event => (eventsSelected && !event.isExternal) || (rulesSelected && event.isExternal)
    )
  }, [events, eventsSelected, rulesSelected])

  return (
    <div style={{ display: 'flex' }}>
      <div
        className={cn(s.calendarWrapper, {
          [s.weekView]: currentView === 'week',
          [s.dayView]: currentView === 'day',
          [s.monthView]: currentView === 'month'
        })}
      >
        <Calendar
          localizer={localizer}
          views={['month', 'week', 'day']}
          components={{
            toolbar: props => (
              <CustomHeader
                {...props}
                date={date}
                onNavigate={setDate}
                currentView={currentView}
                setCurrentView={setCurrentView}
              />
            ),
            eventWrapper: (props: any) => {
              return (
                <Popover
                  placement="left"
                  contentClassName={s.popoverContent}
                  hasArrow={false}
                  stayOpenOnClick
                  trigger={props.children}
                  content={<EventDetails event={props.event} changeTab={changeTab} />}
                />
              )
            },
            week: {
              header: CustomWeekHeader
            },
            timeGutterHeader: () => {
              if (currentView !== 'day') return null
              return (
                <CustomWeekHeader
                  date={date}
                  styles={{ alignItems: 'center' }}
                  weekdayStyles={{ color: '#0957AE', marginBottom: -1, marginTop: 3 }}
                  dayStyles={{ color: '#0957AE' }}
                />
              )
            }
          }}
          date={date}
          onNavigate={setDate}
          startAccessor="start"
          endAccessor="end"
          events={filteredEvents}
          popup
          // @ts-expect-error
          titleAccessor={event => addIcon(event)}
          allDayMaxRows={3}
          eventPropGetter={eventStyleGetter}
          formats={{
            dateFormat: 'd',
            timeGutterFormat: (date, culture, localizer) =>
              localizer ? localizer.format(date, 'h a', culture) : ''
          }}
          scrollToTime={setMinutes(setHours(new Date(), 7), 0)}
        />
      </div>
      <div className={s.dayPicker}>
        <DayPicker
          mode="single"
          defaultMonth={date}
          selected={date}
          onSelect={date => (date ? handleDaySelect(date) : null)}
          modifiersStyles={{
            selected: { background: '#E2F0FF', border: '2px solid #3C98FD', color: '#000000' }
          }}
        />
        <div className={s.calendars}>
          <p className={s.label}>Calendars</p>
          <div style={{ display: 'flex', marginBottom: 20 }}>
            <CheckboxContainer
              className={cn(s.eventsCheckbox, eventsSelected && s.selected)}
              size="md"
              isChecked={eventsSelected}
              cb={() => setEventsSelected(!eventsSelected)}
            />
            <div
              style={{ position: 'relative', top: 3, marginRight: 5 }}
              className={s.eventIconWrapper}
            >
              <BsCalendar2Fill className={s.eventIcon} />
            </div>
            <span
              style={{
                fontSize: 16,
                fontWeight: 700,
                color: '#0369A1',
                position: 'relative',
                top: 3
              }}
            >
              Events
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <CheckboxContainer
              className={cn(s.rulesCheckbox, rulesSelected && s.selected)}
              size="md"
              isChecked={rulesSelected}
              cb={() => setRulesSelected(!rulesSelected)}
            />
            <div
              style={{ position: 'relative', top: 3, marginRight: 5 }}
              className={s.ruleIconWrapper}
            >
              <BsCalendar2Fill className={s.eventIcon} />
            </div>
            <span
              style={{
                fontSize: 16,
                fontWeight: 700,
                color: '#F59E0B',
                position: 'relative',
                top: 3
              }}
            >
              Court Rules
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalendarView
