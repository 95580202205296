import orderBy from 'lodash/orderBy'
import { format } from 'date-fns'
import { DATE_FORMATS } from 'utils/constants'
import {
  formatName,
  sortAlphabeticallyByProperty,
  utcDate,
  timezoneDate,
  timezoneUtils
} from 'utils/helpers'
import {
  APIAttachment,
  APISimpleMatter,
  APIEvent,
  APIEventType,
  Attachment,
  BulkEditValues,
  CanActivate,
  CustomizedOption,
  Option,
  Event,
  APICourtRule,
  CourtRule
} from './types'

const { DEFAULT_DATE_FNS } = DATE_FORMATS

export const toEvents = (events: APIEvent[], callback?: (events: Event[]) => Event[]): Event[] => {
  const eventsArray = events.map((event: APIEvent) => {
    const {
      id,
      name,
      event_id,
      attendees,
      start_date,
      end_date,
      event_type,
      comments,
      attachments,
      comments_count,
      attachments_count,
      description,
      is_private,
      created_by,
      created_date,
      related_matter,
      reminder,
      calendar,
      location,
      is_external
    } = event

    return {
      id,
      name,
      eventId: event_id,
      attendees: attendees.map(attendee => ({
        value: attendee.id,
        label: formatName(attendee) as string
      })),
      startDate: start_date ? timezoneDate(start_date) : undefined,
      endDate: end_date ? timezoneDate(end_date) : undefined,
      timezone: timezoneUtils.getUserTimezoneLabel(),
      eventType: event_type
        ? {
            id: event_type.id,
            name: event_type.name
          }
        : null,
      hidden: false,
      comments: comments || [],
      commentsCount: comments_count || (comments?.length ?? 0),
      fileAttachments: attachments || [],
      fileAttachmentsCount: attachments_count || (attachments?.length ?? 0),
      description,
      createdBy: created_by
        ? {
            value: created_by.id,
            label: formatName(created_by) as string
          }
        : null,
      createdDate: format(new Date(created_date), DEFAULT_DATE_FNS),
      isPrivate: is_private,
      ...(related_matter
        ? {
            relatedMatter: {
              value: related_matter.id,
              label: related_matter.name,
              status: related_matter.status,
              canEdit: related_matter.can_edit
            }
          }
        : {}),
      ...(reminder
        ? {
            reminder: {
              type: reminder.time_unit,
              value: reminder.amount
            }
          }
        : {}),
      calendar: {
        id: calendar.id,
        name: calendar.name,
        color: calendar.color
      },
      location: location
        ? {
            address: location.address,
            latitude: location.latitude,
            longitude: location.longitude,
            name: location.name,
            google_place_id: location.google_place_id
          }
        : null,
      isExternal: is_external
    }
  })

  return callback ? callback(eventsArray) : eventsArray
}

export const toCourtRule = (courtRule: APICourtRule): CourtRule => {
  const {
    id,
    name,
    trigger_date,
    trigger_event,
    events_count,
    jurisdiction,
    needs_review,
    prefix
  } = courtRule

  return {
    id,
    name,
    triggerDate: trigger_date
      ? timezoneDate(trigger_date as string, jurisdiction.time_zone)
      : undefined,
    triggerEvent: {
      value: trigger_event.id,
      label: trigger_event.name
    },
    eventsCount: events_count,
    jurisdiction: {
      value: jurisdiction.id,
      label: jurisdiction.name,
      timezone: jurisdiction.time_zone
    },
    needsReview: needs_review,
    prefix
  }
}

export const toCourtRules = (courtRules: APICourtRule[]): CourtRule[] => {
  return courtRules.map(toCourtRule)
}

export const fromAttachments = (attachments: APIAttachment[]) => {
  return attachments.reduce((acc, attachment): Attachment => {
    const { file_name, file_type, file_url, file_size, will_overwrite_file = false } = attachment
    acc[file_url] = {
      name: file_name,
      type: file_type,
      size: file_size,
      will_overwrite_file: will_overwrite_file
    }
    return acc
  }, {} as Attachment)
}

export const fromEvent = (event: Event, scopeId: string, isEdit?: boolean) => {
  const {
    name,
    attendees,
    startDate,
    endDate,
    eventType,
    description,
    isPrivate,
    fileAttachments,
    reminder,
    location
  } = event

  if (isEdit) {
    return {
      ...(name !== undefined ? { name } : {}),
      ...(attendees !== undefined
        ? { attendee_ids: attendees.length ? attendees.map(attendee => attendee.value) : null }
        : {}),
      ...(startDate !== undefined
        ? { start_date: startDate ? utcDate(startDate as Date) : null }
        : {}),
      ...(endDate !== undefined ? { end_date: endDate ? utcDate(endDate as Date) : null } : {}),
      ...(eventType !== undefined
        ? { event_type_id: eventType === null ? null : eventType?.id }
        : {}),
      ...(description !== undefined ? { description } : {}),
      ...(isPrivate !== undefined ? { is_private: isPrivate } : {}),
      ...(reminder !== undefined
        ? {
            reminder: reminder
              ? {
                  amount: reminder?.value,
                  time_unit: reminder?.type
                }
              : null
          }
        : {}),
      ...(location !== undefined ? { location } : {})
    }
  }

  return {
    name,
    attendee_ids: attendees?.map(attendee => attendee.value) ?? [],
    start_date: startDate ? utcDate(startDate as Date) : null,
    end_date: endDate ? utcDate(endDate as Date) : null,
    event_type_id: eventType?.id ?? null,
    description,
    is_private: isPrivate,
    reminder: reminder
      ? {
          amount: reminder?.value,
          time_unit: reminder?.type
        }
      : null,
    location,
    comments: [],
    ...(isEdit
      ? {}
      : {
          files: fileAttachments.length ? fromAttachments(fileAttachments) : null
        })
  }
}

/**
 *
 * @param options A list of select options that may have been deactivated by
 * the client.
 * @returns A filtered list, containing only options that have not been
 * deactivated.
 */
export const onlyActiveOptions = <T extends CanActivate>(options: T[]): T[] => {
  return options.filter(o => o.isActive)
}

export const toNamesOptions = (
  events: APIEvent[]
): { value: number; label: string; eventId: string }[] => {
  const options = events

  const map = options.map(option => {
    const { id, name, event_id } = option

    return {
      value: id,
      label: name,
      eventId: event_id
    }
  })

  return orderBy(map, ['label'], ['asc'])
}

const extractNumericParts = (eventId: string): number[] => {
  const numericParts: string[] = eventId
    .replace(/[^0-9-]/g, '')
    .split('-')
    .filter(Boolean)
  return numericParts.map(part => parseInt(part))
}

export const toEventIdsOptions = (events: APIEvent[]): { value: string; label: string }[] => {
  const sortedOptions = events
    .map(option => {
      const { event_id } = option

      return {
        value: event_id,
        label: event_id
      }
    })
    .sort((a, b) => {
      const numericPartsA = extractNumericParts(a.label)
      const numericPartsB = extractNumericParts(b.label)

      for (let i = 0; i < numericPartsA.length && i < numericPartsB.length; i++) {
        const numberA = numericPartsA[i]
        const numberB = numericPartsB[i]

        if (numberA !== numberB) {
          return numberA - numberB
        }
      }

      return numericPartsA.length - numericPartsB.length
    })

  return sortedOptions
}

/**
 *
 * @param eventTypes A list of event type DTOs.
 * @returns A list of event type view models to be rendered in a select list.
 */
export const toEventTypesOptions = (eventTypes: APIEventType[]): CustomizedOption[] => {
  return eventTypes.map((type: APIEventType) => {
    const { id, name, is_active } = type

    return {
      value: id,
      label: name,
      isActive: is_active
    }
  })
}

export const serializeBulkEditOptions = (options: BulkEditValues, eventIds: Array<number>) => {
  const mapUsers = (users: Array<Option>) => users.map(option => option.value)
  const { attendeesToAdd, attendeesToRemove, date, time, location, reminder, eventType } = options
  return {
    event_ids: eventIds,
    ...(!!attendeesToAdd.length && { attendee_ids: mapUsers(attendeesToAdd) }),
    ...(!!attendeesToRemove.length && {
      remove_attendee_ids: mapUsers(attendeesToRemove)
    }),
    ...(!!date && { date: utcDate(date) }),
    ...(!!time.startTime &&
      !!time.endTime && {
        start_datetime: utcDate(time.startTime),
        end_datetime: utcDate(time.endTime)
      }),
    ...(location !== null && { location }),
    ...(reminder !== null && { reminder: { amount: reminder.value, time_unit: reminder.type } }),
    ...(eventType !== null && { event_type_id: eventType.value })
  }
}

export const toMattersOptions = (response: APISimpleMatter[], excludeClosed = false) => {
  const statusesToExclude = excludeClosed ? ['inactive', 'closed'] : ['inactive']
  const arr = response
    .filter(m => !statusesToExclude.includes(m.status))
    .map(m => ({
      value: m.id,
      label: m.name,
      clientMatterId: m.client_matter_id,
      status: m.status
    }))

  return sortAlphabeticallyByProperty(arr, 'label')
}
