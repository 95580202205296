import { ChangeEventHandler, useState } from 'react'
import { ModalContainer, Alert, CharLimitInput } from 'simple-core-ui'
import s from './CategoryModal.scss'

interface Category {
  id?: number
  name: string
}

interface Props {
  category: Category
  onChangeCategoryName(newName: string): void
  onSubmit(): Promise<void>
  onCloseModal(): void
  showDuplicateError: boolean
}

const CategoryModal = ({ category, showDuplicateError, onSubmit, ...props }: Props) => {
  const [isPristine, setIsPristine] = useState(true)

  const onChangeCategoryName: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
    setIsPristine(false)
    let value = e.target.value
    // remove non alpha-numeric characters
    value = value.replace(/([^\s\w\d])*/g, '')
    // remove extra spaces
    value = value.replace(/\s+/g, ' ')
    props.onChangeCategoryName(value)
  }

  const onCloseModal = () => {
    setIsPristine(true)
    props.onCloseModal()
  }

  const isEdit = typeof category.id === 'number'

  return (
    <ModalContainer
      title={isEdit ? 'Edit Invoice Rule Category' : 'Add Invoice Rule Category'}
      subtitle={isEdit ? 'Category Name' : 'New Category'}
      size="sm"
      confirmText="Save"
      cancelText="Cancel"
      confirmCb={onSubmit}
      cancelCb={onCloseModal}
      isDisabled={showDuplicateError || !category.name.trim() || (isEdit && isPristine)}
      content={
        <div>
          {showDuplicateError && (
            <Alert
              className={s.alert}
              message="The category already exists. Please specify a different category."
              status="error"
            />
          )}
          <div className={s.title}>Category</div>
          <CharLimitInput
            value={category.name}
            onChangeCb={onChangeCategoryName}
            placeholder="Enter Rule Category Name"
            dynamicCharCalculation
            maxLength={50}
          />
        </div>
      }
    />
  )
}

export default CategoryModal
