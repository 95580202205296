import get from 'lodash/get'
import { fromContact } from 'contacts/serializer'

const fromLogType = ({ id, created, category, verb, description, contact }) => {
  const logContact = fromContact(contact)
  const { firstName, lastName, email } = logContact
  const normalizedName = firstName || lastName ? `${firstName} ${lastName}`.trim() : email

  return {
    id,
    created,
    category,
    description: `${normalizedName} ${verb.toLowerCase()} ${description}.`,
    user: normalizedName,
    contact: logContact
  }
}

export const fromActivity = ({ activity }) => activity.map(fromLogType)

export const toActivityLogParams = ({ model, secondaryModel, pageSize, lastLogId }) => ({
  last_log: lastLogId,
  model_name: get(model, 'name'),
  model_id: get(model, 'id'),
  secondary_model_name: get(secondaryModel, 'name'),
  secondary_model_id: get(secondaryModel, 'id'),
  or_enabled: get(secondaryModel, 'or'),
  step: pageSize
})
