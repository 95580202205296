import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'

import { useLoading } from 'simple-core-ui'
import { makeGetRequest } from 'utils/api'
import { toAvailableFields, toOperatorsByType } from './serializers'
import { AvailableAction, CustomAttributeOption, Namespace } from './@types/api'
import { ContextState } from './@types/context'
import s from './SimpleReview.scss'
import { Filters } from './@types/list'

const initialFilters: Filters = {
  pageSize: 50,
  ordering: { columnKey: 'name', isDesc: false },
  page: 1,
  search: '',
  reset: 0,
  category: 'Active'
}

const SimpleReview = () => {
  const dispatch = useDispatch()

  const [state, setState] = useState<ContextState>({
    availableActions: [],
    availableFields: [],
    categories: [],
    operatorsByType: {},
    operators: [],
    customAttributes: [],
    filterSearch: initialFilters
  })

  const [, withLoadingLocks] = useLoading()

  useEffect(() => {
    const fetchAvailableFields = async () => {
      try {
        const namespace: Namespace = await withLoadingLocks(
          makeGetRequest(`/client/rules/namespace/invoice_validation/`)
        )

        const { funcs, models } = namespace
        const operators = namespace.operators.map(el => {
          if (el.symbol !== 'is not') return el
          return { ...el, label: 'Is not equal to' }
        })

        const availableFields = toAvailableFields(funcs, models)

        const operatorsByType = toOperatorsByType(operators)

        setState(prev => ({
          ...prev,
          availableFields,
          operatorsByType,
          operators
        }))
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: {
            ...(error as object),
            errorTitle: 'Error'
          }
        })
      }
    }

    const fetchActions = async () => {
      try {
        let { actions } = await withLoadingLocks(
          makeGetRequest('/client/rules/actions/invoice_validation/')
        )

        if (window.credentials.rejectionReasonEnabled) {
          actions = actions.map((action: AvailableAction) => {
            if (action.name !== 'reject_invoice') return action

            return {
              ...action,
              params: action.params.map(p => {
                if (p.name === 'rejection_reason') {
                  return { ...p, is_required: true }
                }
                return p
              })
            }
          })
        }

        setState(prev => ({
          ...prev,
          availableActions: actions
        }))
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: {
            ...(error as object),
            errorTitle: 'Error'
          }
        })
      }
    }

    const fetchCategories = async () => {
      try {
        const { rows } = await withLoadingLocks(makeGetRequest(`/category/rules/`))
        setState(prev => ({
          ...prev,
          categories: rows
        }))
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...(error as Record<string, unknown>), errorTitle: 'Error' }
        })
      }
    }

    const fetchCustomAttrs = async () => {
      try {
        const response: Array<CustomAttributeOption> = await withLoadingLocks(
          makeGetRequest(`/client/rules/attributes/`)
        )
        setState(prev => ({
          ...prev,
          customAttributes: response.map(item => ({ ...item, value: String(item.value) }))
        }))
      } catch (e) {
        dispatch({
          type: 'API_ERROR',
          error: e
        })
      }
    }

    fetchAvailableFields()
    fetchActions()
    fetchCategories()
    fetchCustomAttrs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateParams = (filterSearch: Filters) => {
    setState(prev => ({
      ...prev,
      filterSearch
    }))
  }

  return (
    <section className={s.container}>
      <Outlet context={{ state, setState, updateParams }} />
    </section>
  )
}

export default SimpleReview
