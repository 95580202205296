import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Panel, If, Button } from 'simple-core-ui'

import { Allocations } from 'matters/templates/common/TemplateAllocations/Allocations'
import { IManageTemplates } from 'matters/templates/common/IManageTemplates'
import { BaseEvaluatorsContainer } from 'matters/templates/common/BaseEvaluators'
import { PreviewPanelContent } from './PreviewPanelContent'
import { DynamicAttribute } from 'matters/templates/common/DynamicAttribute'
import { DefaultFolderStructureContainer } from 'matters/templates/common/DefaultFolderStructure'

import s from './TemplatePreview.scss'
import cn from 'classnames'
import { TEMPLATE_STATUSES, SELECT_TEMPLATE_FLOW } from 'matters/templates/constants'
import {
  categoryHasAttributes,
  getHasAllocations,
  getHasAdditionalAttributes,
  getAttributeLabel
} from 'matters/templates/utils'
import { makeGetRequest } from 'utils/api'
import update from 'immutability-helper'
import { hasModule } from 'utils/helpers'
import { MODULE } from 'utils/constants'
import * as ACT from 'matters/templates/actions'

@connect(
  ({
    matterTemplatesSelection,
    templateAllocations,
    legalRequest,
    surveyConfig,
    templateFolders
  }) => {
    const { userFlowState, additionalAttributes } = matterTemplatesSelection
    const { attributes, allocations } = templateAllocations
    return {
      additionalAttributes,
      attributes,
      allocations,
      legalRequest,
      userFlowState,
      templateFolders,
      sharedEvaluators: surveyConfig.sharedEvaluators
    }
  }
)
class TemplatePreview extends Component {
  state = {
    iManageTemplate: {
      details: {
        value: '',
        label: ''
      },
      readOnly: false
    }
  }
  componentDidMount() {
    this._isMounted = true
    if (this.props.selectedTemplate.id) {
      this.fetchSelectedImanageTemplate()
      this.getDefaultFolders(this.props.selectedTemplate.id)
    }
  }
  componentDidUpdate(prevProps) {
    this._isMounted = true
    if (this.props.selectedTemplate.id !== prevProps.selectedTemplate.id) {
      this.fetchSelectedImanageTemplate()
      this.getDefaultFolders(this.props.selectedTemplate.id)
    }
  }

  templateNameHeight = React.createRef()

  calculateTemplatePreviewSectionHeight = () => {
    const { templateHeight } = this.props
    const containerMarginsToRemove = parseInt(s.marginTop20)
    const buttonHeightAndMargin = parseInt(s.buttonHeightAndMargin)

    return (
      templateHeight +
      containerMarginsToRemove +
      buttonHeightAndMargin +
      (this.templateNameHeight.current ? this.templateNameHeight.current.clientHeight : 0)
    )
  }

  getDefaultFolders = templateId => {
    if (hasModule(MODULE.NATIVE_DMS)) {
      this.props.dispatch({
        type: ACT.DEFAULT_FOLDERS_FETCH_REQUESTED,
        payload: { templateId }
      })
    }
  }

  fetchSelectedImanageTemplate = async () => {
    const { selectedTemplate } = this.props
    const url = `/doc_management/template_mapping/?template_id=${selectedTemplate.id}`
    const response = await makeGetRequest(url)
    const { imanage_template_id, read_only } = response

    const selectedImanageTemplate = {
      details: { value: imanage_template_id, label: '' },
      readOnly: read_only
    }
    const newState = update(this.state, {
      iManageTemplate: {
        $set: selectedImanageTemplate
      }
    })
    if (this._isMounted) {
      this.setState(newState)
    }
  }

  render() {
    const {
      archived,
      template,
      selectedTemplate,
      additionalAttributes,
      allocations,
      attributes,
      unarchiveTemplate,
      userFlowState,
      templateFolders
    } = this.props

    const hasAdditionalAttributes = getHasAdditionalAttributes(additionalAttributes)
    const hasAllocations = getHasAllocations(allocations)
    const canUnarchive = template.unarchive_allowed
    const isChangeMatterTemplateOrMatterRequest = [
      SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE,
      SELECT_TEMPLATE_FLOW.MATTER_REQUEST
    ].includes(userFlowState.userFlow)
    const hasEvaluators = !!(
      this.props.sharedEvaluators.users.length || this.props.sharedEvaluators.roles.length
    )
    const showDefaultFolders = () => {
      if (hasModule(MODULE.NATIVE_DMS)) {
        if (userFlowState.userFlow === SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE) return false
        else {
          if (archived && !templateFolders.rootNode?.children) return false
          return true
        }
      }
      return false
    }

    return (
      <Fragment>
        <div>
          <div
            style={{
              display: categoryHasAttributes(template.left) ? 'flex' : 'none',
              justifyContent: 'flex-end'
            }}
          >
            <If
              condition={
                userFlowState.userFlow !== SELECT_TEMPLATE_FLOW.MATTER_REQUEST &&
                canUnarchive &&
                archived
              }
            >
              <Button onClick={unarchiveTemplate}>Unarchive</Button>
            </If>
          </div>
          <h4 ref={this.templateNameHeight} className={s.notification}>
            <span className={s.templateName}>
              {template.name}{' '}
              {template.status === TEMPLATE_STATUSES.PUBLISHED &&
                selectedTemplate.is_default_for_user &&
                '(default)'}
            </span>
            {template.description && (
              <small className={s.templateDescription}>{template.description}</small>
            )}
          </h4>
          <If condition={hasAdditionalAttributes && isChangeMatterTemplateOrMatterRequest}>
            <div className={s.additionalAttributesPanelWrapper}>
              <p className={s.warning}>
                These attributes will be removed from your matter. The data is not recoverable.
              </p>
              {additionalAttributes.map(attribute => {
                if (attribute.type === 'relationship') {
                  return (
                    <DynamicAttribute
                      key={attribute.id}
                      dynamicAttributeClass={s.dynamicAttribute}
                      threeLevelsClass={s.threeLevels}
                      twoLevelsClass={s.twoLevels}
                    >
                      {attribute.attributes.map((attr, relIndex) => (
                        <div key={attr.id} className={s.attributeWrapper}>
                          <div className={s.attributeLabel}>{attr.label}</div>
                          <div className={s.attributeValue}>
                            <span>
                              {attr.value && +attr.value !== -1 ? attr.value.label : 'N/A'}
                            </span>
                          </div>
                        </div>
                      ))}
                    </DynamicAttribute>
                  )
                } else {
                  if (!attribute.not_in_template) {
                    // for attributes of type select the value is an object {value: 1, label: 'A'}
                    // that's why we use value.value or value.label
                    // else value is just a string or a number
                    const value = getAttributeLabel(attribute)
                    return (
                      <p className={s.additionalAttribute} key={attribute.id}>
                        {`${attribute.label}: ${value}`}
                      </p>
                    )
                  } else {
                    return ''
                  }
                }
              })}
            </div>
          </If>
          <div className={s.panelWrapper}>
            {categoryHasAttributes(template.left) && (
              <Panel
                className={cn(s.panel, {
                  [s.single]: !categoryHasAttributes(template.right)
                })}
                title="Matter Attributes"
              >
                <PreviewPanelContent
                  archived
                  hasAttributes={categoryHasAttributes(template.left)}
                  sections={template.left}
                />
              </Panel>
            )}
            {categoryHasAttributes(template.right) && (
              <Panel className={s.panel} title="Other Attributes">
                <PreviewPanelContent archived sections={template.right} />
              </Panel>
            )}
            {template.status === TEMPLATE_STATUSES.PUBLISHED &&
              !categoryHasAttributes(template.left) &&
              !categoryHasAttributes(template.right) && (
                <div className={s.noAttributes}>This template has no attributes</div>
              )}
          </div>

          {hasAllocations && (
            <Panel
              title="Allocations"
              styles={{ marginTop: 20, backgroundColor: '#f5f5f5' }}
              className={cn(s.noFlex, s.panel)}
            >
              <Allocations allocations={allocations} attributes={attributes} readOnly />
            </Panel>
          )}

          {hasEvaluators && <BaseEvaluatorsContainer readOnly />}

          {showDefaultFolders() && (
            <DefaultFolderStructureContainer readOnly templateId={this.props.selectedTemplate.id} />
          )}

          <IManageTemplates
            readOnly
            template={this.state.iManageTemplate}
            matterTemplateId={this.props.selectedTemplate.id}
          />
        </div>
      </Fragment>
    )
  }
}

export default TemplatePreview
