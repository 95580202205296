import { APIEvent } from 'common/Events/types'
import { Event } from './types'
import { timezoneDate, formatName } from 'utils/helpers'

export const toEvents = (events: APIEvent[]): Event[] => {
  const eventsArray = events.map((event: APIEvent) => {
    const {
      id,
      name,
      event_type,
      related_matter,
      location,
      start_date,
      attendees,
      is_external,
      end_date
    } = event

    return {
      id,
      name,
      location,
      attendees: attendees.map(attendee => ({
        value: attendee.id,
        label: formatName(attendee) as string
      })),
      isExternal: is_external,
      startDate: start_date ? timezoneDate(start_date) : undefined,
      endDate: end_date ? timezoneDate(end_date) : undefined,
      eventType: event_type
        ? {
            id: event_type.id,
            name: event_type.name
          }
        : null,
      ...(related_matter
        ? {
            relatedMatter: {
              value: related_matter.id,
              label: related_matter.name,
              status: related_matter.status
            }
          }
        : {}),
      title: name,
      start: start_date ? timezoneDate(start_date) : undefined,
      end: start_date ? timezoneDate(end_date || start_date) : undefined,
      allDay: end_date ? false : true
    }
  })

  return eventsArray
}
