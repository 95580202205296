import moment from 'moment'
import get from 'lodash/get'
import { isFolder } from './helpers'
import { openLink } from 'utils/helpers'
import orderBy from 'lodash/orderBy'

export const getNormalizedCellContent = (columnKey, row) => {
  const { cells } = row
  const cellContent = get(
    cells.find(cell => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }
  switch (columnKey) {
    case 'category':
      return cellContent.toLowerCase()
    case 'name':
      return cellContent.toLowerCase()
    case 'user':
      return cellContent.toLowerCase()
    case 'size':
      return row.size
    case 'type':
      return isFolder(cellContent) ? 'folder' : 'file'
    case 'status':
      return cellContent.toLowerCase()
    case 'created':
      return moment(cellContent).valueOf()
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}

export const viewAttachment = attachment => {
  const fileUrl = attachment.fileURL || attachment.fileUrl
  openLink(`/attachment/${fileUrl}?type=${attachment.type || 'matter'}`)
}

export const getFileNameExtension = fileName => {
  const [, name, extension] = /^(.*?)(\.[^.]*)?$/.exec(fileName)
  return {
    fileName: name,
    extension: extension
  }
}

export const sortLabels = list => {
  return orderBy(list, [option => option.label.toLowerCase()], ['asc'])
}

export const resolveShareFileValue = value => {
  if (typeof value === 'boolean') {
    if (value) {
      return 'IS_SHARED'
    } else {
      return 'IS_NOT_SHARED'
    }
  } else return '--'
}
