import { useMemo } from 'react'
import { FaPlus } from 'react-icons/fa'
import cloneDeep from 'lodash/cloneDeep'
import cn from 'classnames'

import { AiIcon, Button, ConditionalIcon, Grid, CharLimitInput } from 'simple-core-ui'
import { EditableField } from 'components'
import { sortAlphabeticallyByProperty } from 'utils/helpers'
import { getTotalLevels } from 'simple_review/utils/helpers'
import { toCategories } from 'simple_review/serializers'
import { validateCondition } from 'simple_review/editor/validators'
import { useSimpleReviewContext } from 'simple_review/hooks'
import { getSearchableSelectStyles, textArea, textInput } from 'simple_review/styles/styles'
import { Constant } from 'simple_review/@types/common'
import { Condition, Condition as ConditionType, Operand, Rule } from 'simple_review/@types/editor'
import { btnStyling } from 'simple_review/editor/styles'
import { Conditions } from './conditions'
import { Actions } from './actions'
import { getBaseCondition } from './constants'
import s from './EditorContent.scss'

const { Column, Row } = Grid

const CELL_STYLE = {
  padding: '12px 20px',
  minWidth: '300px',
  flexGrow: 1
}

interface Props {
  rule: Rule
  isReadOnly: boolean
  isAI: boolean
  onChangeRule(newRule: Rule): void
  errors: Array<Condition | Operand>
}

const categorySelectStyle = getSearchableSelectStyles(true)

export const EditorContent = ({ rule, onChangeRule, isReadOnly, isAI, errors }: Props) => {
  const { state } = useSimpleReviewContext()

  const numLevels = getTotalLevels(rule.condition)

  const onUpdateProperty = (key: keyof Rule, newValue: unknown) => {
    const newRule = {
      ...cloneDeep(rule),
      [key]: newValue
    }
    onChangeRule(newRule)
  }

  const handleChangeConditions = (newCondition: ConditionType) => {
    onUpdateProperty('condition', newCondition)
  }

  const handleAddCondition = (index?: number) => {
    const op = rule.condition.op
    const newCondition = getBaseCondition(op)
    const newRule = cloneDeep(rule)
    if (index !== undefined) {
      newRule.condition.operands.splice(index + 1, 0, newCondition)
    } else {
      newRule.condition.operands.push(newCondition)
    }
    onChangeRule(newRule)
  }

  const isConditionValid = validateCondition(rule.condition)

  const categoryOptions = useMemo(() => toCategories(state.categories.filter(c => c.active)), [
    state.categories
  ])

  return (
    <div className={s.contentContainer}>
      <Row>
        <Column span={7} className={s.topCell} style={CELL_STYLE}>
          <div className={s.leftContent}>
            <div>
              <div className={s.label}>
                <span className={s.required}>* </span>NAME:
              </div>
              <div className={s.nameContainer}>
                <div className={cn(s.nameIcon, !isAI && s.ai)}>
                  {isAI ? <AiIcon size={20} /> : <ConditionalIcon />}
                </div>
                <CharLimitInput
                  value={rule.name}
                  onChangeCb={e => {
                    onUpdateProperty('name', e.target.value)
                  }}
                  placeholder="Enter rule name"
                  style={{ ...textInput, paddingLeft: 32 }}
                  maxLength={150}
                  focused={false}
                  isDisabled={isReadOnly}
                />
              </div>
            </div>
            <div>
              <div className={s.label}>
                <span className={s.required}>* </span>CATEGORY:
              </div>
              {/* @ts-expect-error */}
              <EditableField
                type="select"
                placeholder="Select a category"
                value={categoryOptions.find(option => option.value === rule.categoryId)}
                options={sortAlphabeticallyByProperty(categoryOptions, 'label')}
                clearable={false}
                style={categorySelectStyle}
                onChange={(selected: Constant) => {
                  onUpdateProperty('categoryId', selected.value)
                }}
                disabled={isAI}
              />
            </div>
          </div>
        </Column>
        <Column span={5} className={s.topCell} style={CELL_STYLE}>
          <div>
            <div className={s.label}>DESCRIPTION:</div>
            <CharLimitInput
              type="textarea"
              value={rule.description}
              onChangeCb={e => {
                onUpdateProperty('description', e.target.value)
              }}
              style={{ minHeight: '135px', ...textArea }}
              maxLength={1000}
              focused={false}
              placeholder="Enter rule description"
              isDisabled={isReadOnly}
            />
          </div>
        </Column>
      </Row>
      <Row>
        <Column span={7} style={CELL_STYLE}>
          <div
            className={cn('box', s.conditionsBox, s.cellContainer, numLevels >= 3 && s.whiteBox)}
          >
            <div className={s.ruleType}>
              {isAI ? (
                <>
                  AI Rule
                  <AiIcon />
                </>
              ) : (
                <>
                  Conditional Rule
                  <ConditionalIcon />
                </>
              )}
            </div>
            <div className={cn(s.label, s.bigMargin)}>
              <span className={s.required}>*</span> CONDITIONS:
            </div>
            <div className={s.box}>
              <Conditions
                condition={rule.condition}
                errors={errors}
                ruleName={rule.name}
                isReadOnly={isReadOnly}
                onChangeConditions={handleChangeConditions}
                onAddCondition={handleAddCondition}
              />
              {!isReadOnly && (
                <Button
                  className={s.addConditionButton}
                  style={btnStyling}
                  isDisabled={!isConditionValid}
                  isSecondary
                  onClick={() => handleAddCondition()}
                >
                  <div className={s.addConditionButtonContent}>
                    <FaPlus size={12} />
                    Add additional condition
                  </div>
                </Button>
              )}
            </div>
          </div>
        </Column>
        <Column span={5} style={CELL_STYLE}>
          <Actions
            rule={rule}
            isReadOnly={isReadOnly}
            onChangeActions={newActions => onUpdateProperty('actions', newActions)}
          />
        </Column>
      </Row>
    </div>
  )
}
