import { ModalContainer } from 'simple-core-ui'

interface Props {
  onConfirm: () => void
  onCancel: () => void
  userName: string
  view: string
}

const DeleteConfirmation = ({ onConfirm, onCancel, userName, view }: Props) => {
  return (
    <ModalContainer
      title={`${view === 'rules' ? 'Remove' : 'Delete'} User`}
      hasNewButtons
      cancelText="Cancel"
      size="sm"
      cancelCb={onCancel}
      confirmText={view === 'rules' ? 'Delete' : 'Confirm'}
      confirmCb={onConfirm}
      content={
        <>
          {`Are you sure you want to remove ${userName} from receiving notifications ${
            view === 'rules' ? 'when Events with Court Rules are updated' : 'for shared files'
          }?`}
        </>
      }
      contentStyle={{ padding: '10px 24px 30px', minHeight: 0 }}
    />
  )
}

export default DeleteConfirmation
