import axios, { AxiosError, AxiosRequestConfig, ResponseType } from 'axios'
import get from 'lodash/get'
import has from 'lodash/has'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { setupCache } from 'axios-cache-interceptor'

import { Alert } from 'simple-core-ui'

import APP_ACT from 'app/actions'

const ReactSwal = withReactContent(Swal)

type Params = Record<any, any>

import qs from 'query-string'

const cache = axios.create()

// Default TTL is 5 Minutes (1000 * 60 * 5)
const cacheAxios = setupCache(cache)
//  --> API request will fail on empty strings
export const sanitizeApiParams = (params: Params) => {
  for (const key in params) {
    if (params[key] === '') {
      params[key] = null
    }
  }
  return params
}

// These configurations are necessary to make any non-GET request to Django.
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.headers.common['X-CSRFToken'] = window.credentials.csrfToken

// This will allow for the Django view check request.is_ajax() to pass.
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

export const makeFormDataPost = (url: string, params: Params) => {
  const bodyFormData = new FormData()
  for (const key in params) {
    if (params[key] !== null && params[key] !== undefined) {
      bodyFormData.append(key, params[key])
    }
  }

  const config: AxiosRequestConfig = {
    method: 'POST',
    url: url,
    data: bodyFormData,
    headers: { 'X-CSRFToken': window.credentials.csrfToken, 'Content-Type': 'multipart/form-data' }
  }

  return axios(config).then(response => response.data)
}

axios.interceptors.request.use(
  config => {
    if (!config.headers['browser-timezone']) {
      config.headers['browser-timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    return config
  },
  error => Promise.reject(error)
)

export const getRequest = (url: string) => {
  return axios.get(url).then(response => response.data)
}

export const makeGetRequestCache = (url: string, params?: Params) => {
  return cacheAxios.get(url, params).then(response => response.data)
}

export const makeGetRepeatedParams = (url: string, params: Params, responseType: ResponseType) => {
  return axios
    .get(url, {
      params: { ...params },
      responseType,
      paramsSerializer: params => qs.stringify(params)
    })
    .then(response => response.data)
}

export const makeGetRequest = (url: string, params?: Params) => {
  return axios.get(url, params).then(response => response.data)
}

interface BlobRequest {
  url: string
  method: string
  data?: Record<string, unknown>
  params?: Params
}

interface Request {
  url: string
  method: string
  data?: Record<string, unknown>
  responseType: ResponseType
  params?: Params
}

export const makeRequest = ({ url, method, data, responseType, params }: Request) =>
  axios({ url, method, responseType, data, params }).then(response => response)

export const makeBlobRequest = ({ url, data, method, params }: BlobRequest) =>
  axios({ url, method, responseType: 'blob', data, params }).then(response => response.data)

export const makePostRequest = (url: string, params: Params, config = {}) =>
  axios.post(url, params, config).then(response => response.data)

export const makePutRequest = (url: string, params: Params) =>
  axios.put(url, params).then(response => response.data)

export const makeDeleteRequest = (url: string, params?: Params) =>
  axios.delete(url, params).then(response => response.data)

export const makePatchRequest = (url: string, params: Params) =>
  axios.patch(url, params).then(response => response.data)

const handleStandardError = (error: AxiosError | unknown, fallback: string) => {
  let message = ''
  message = get(error, 'response.data.error', '')
  if (message === '') message = get(error, 'response.data.errors[0].description', '')
  if (message === '') message = get(error, 'response.data.errors[0]', fallback)

  if (typeof message !== 'string') {
    message = JSON.stringify(message)
  }

  window.store.dispatch({
    type: APP_ACT.PUSH_NOTIFICATION,
    loadingLock: 'off',
    payload: {
      title: 'Error',
      message: message,
      level: 'error'
    }
  })
}

const wrapperStyle = {
  display: 'inline-flex',
  flexFlow: 'column nowrap',
  alignItems: 'stretch',
  margin: 'auto',
  fontSize: '16px',
  fontWeight: 'normal'
}

interface IError {
  description: string
  instances: Array<string>
}

interface ContentProps {
  errors: Array<IError>
}

interface BadRequest {
  description: string
  errors: Array<IError>
}

const Content = ({ errors }: ContentProps) => {
  return (
    <section style={{ display: 'flex' }} className="p-t-sp300">
      <section style={wrapperStyle}>
        {errors.map(({ description, instances }, idx) => (
          <Alert message={description} items={instances} status="error" key={idx} />
        ))}
      </section>
    </section>
  )
}

const handleBadRequest = ({ description, errors }: BadRequest) => {
  ReactSwal.fire({
    title: description,
    confirmButtonText: 'Close',
    icon: 'error',
    html: <Content errors={errors} />
  })
}

export const handleError = (error: AxiosError | unknown, fallback: string) => {
  fallback =
    fallback ||
    'We seem to have run into an issue. Please contact support and we will get this issue resolved as soon as possible.'

  const data = get(error, 'response.data', {})
  const hasBadRequestShape =
    has(data, 'description') && has(data, 'errors') && has(data, 'errors[0].description')

  if (hasBadRequestShape) {
    handleBadRequest(data as BadRequest)
  } else {
    handleStandardError(error, fallback)
  }
}

export const HTTP_NOT_FOUND = 404
