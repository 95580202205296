import { memo } from 'react'
import s from './Filters.scss'
import cn from 'classnames'
import moment from 'moment'
import { Filter } from './Filter'
import { CheckboxList, Users } from 'common/Selects'
import { FilterOption, Filters as FiltersType, TaskFilters, Option, DateFormat } from '../types'
import {
  toStatusesOptions,
  toPrioritiesOptions,
  toNamesOptions,
  toTaskIdsOptions,
  toMattersOptions
} from '../serializers'
import Select, { StylesConfig } from 'react-select'
import { DateRangePopper } from 'common/DateRangePopper'
import { DueDatePicker } from 'common/DueDatePicker'
import { DATE_FORMATS } from 'utils/constants'
import { OPERATORS } from '../constants'
import isEqual from 'lodash/isEqual'
import { DropdownPopper } from 'common/DropdownPopper'

const {
  IS,
  IS_NOT,
  IS_SET,
  IS_NOT_SET,
  IS_BEFORE,
  IS_AFTER,
  IS_BETWEEN,
  WITHOUT_ATTACHMENTS,
  WITH_ATTACHMENTS,
  WITHOUT_COMMENTS,
  WITH_COMMENTS
} = OPERATORS

const { DEFAULT_DATE } = DATE_FORMATS

const customStyle: StylesConfig<FilterOption, false> = {
  control: (provided, state) => {
    if (state.hasValue) {
      return {
        ...provided,
        border: '1px solid #3c99fd',
        '&:hover': {
          borderColor: '#3c99fd'
        },
        '&:focus': {
          borderColor: '#3c99fd'
        },
        boxShadow: 'none'
      }
    }
    return {
      ...provided,
      boxShadow: 'none'
    }
  },
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  singleValue: (provided, state) => {
    if (!state.data) return provided
    return {
      ...provided,
      color: '#3c99fd'
    }
  }
}

interface Props {
  className?: string
  taskFilters: TaskFilters
  setTaskFilters: (filters: TaskFilters) => void
  clearFilters: () => void
  scopeId: string
  context?: 'workbench' | 'matter'
  baseUrl: string
}
interface InjectedProps {
  setFilters: (values: FilterOption[]) => void
  filters: FiltersType | null
}

const getPickerValue = (filters: InjectedProps['filters'], index: number) => {
  if (filters?.values && filters.values[index]) {
    return moment(filters.values[index].value).format(DEFAULT_DATE) as DateFormat | undefined | Date
  }
  return undefined
}

const Filters = memo(
  ({ className, taskFilters, setTaskFilters, clearFilters, scopeId, context, baseUrl }: Props) => {
    return (
      <div className={cn(s.filters, className)}>
        <Filter
          label="Task Name"
          value={taskFilters.id}
          onConfirm={(value, cb) => {
            setTaskFilters({ ...taskFilters, id: value })
            cb?.()
          }}
          operatorsList={[
            {
              label: 'Is',
              value: IS
            },
            {
              label: 'Is not',
              value: IS_NOT
            }
          ]}
        >
          {({ filters, setFilters }: InjectedProps) => (
            <DropdownPopper value={filters?.values ?? []} placeholder="Select task(s)" label="task">
              <CheckboxList
                url={`${baseUrl}/tasks/`}
                value={filters?.values ?? []}
                onConfirm={setFilters}
                isPaginated
                firstPageIndex={1}
                secondaryColumn="taskId"
                serialiser={toNamesOptions}
              />
            </DropdownPopper>
          )}
        </Filter>
        {context === 'workbench' && (
          <Filter
            label="Matter Name"
            value={taskFilters.relatedMatter ?? null}
            onConfirm={(value, cb) => {
              setTaskFilters({ ...taskFilters, relatedMatter: value })
              cb?.()
            }}
            operatorsList={[
              {
                label: 'Is',
                value: IS
              },
              {
                label: 'Is not',
                value: IS_NOT
              }
            ]}
          >
            {({ filters, setFilters }: InjectedProps) => (
              <DropdownPopper
                value={filters?.values ?? []}
                placeholder="Select matter(s)"
                label="matter"
              >
                <CheckboxList
                  url="/manage/matters/v2/simple_matter_list/"
                  value={filters?.values ?? []}
                  onConfirm={setFilters}
                  isPaginated
                  serialiser={toMattersOptions}
                  secondaryColumn="clientMatterId"
                  secondaryColumnPosition="below"
                />
              </DropdownPopper>
            )}
          </Filter>
        )}
        <Filter
          label="Task Id"
          value={taskFilters.taskId}
          onConfirm={(value, cb) => {
            setTaskFilters({ ...taskFilters, taskId: value })
            cb?.()
          }}
          operatorsList={[
            {
              label: 'Is',
              value: IS
            },
            {
              label: 'Is not',
              value: IS_NOT
            }
          ]}
        >
          {({ filters, setFilters }: InjectedProps) => (
            <DropdownPopper
              value={filters?.values ?? []}
              placeholder="Select task id(s)"
              label="id"
            >
              <CheckboxList
                url={`${baseUrl}/tasks/`}
                value={filters?.values ?? []}
                isPaginated
                firstPageIndex={1}
                onConfirm={setFilters}
                serialiser={toTaskIdsOptions}
              />
            </DropdownPopper>
          )}
        </Filter>
        <Filter
          label="Assignee"
          value={taskFilters.assignees}
          onConfirm={(value, cb) => {
            setTaskFilters({ ...taskFilters, assignees: value })
            cb?.()
          }}
        >
          {({ filters, setFilters }: InjectedProps) => (
            <DropdownPopper
              value={filters?.values ?? []}
              placeholder="Select assignee(s)"
              label="assignee"
            >
              <Users
                value={(filters?.values as Option[]) ?? []}
                style={{ width: '400px' }}
                requestParams={{ matterId: +scopeId, canEdit: true, active: true }}
                onConfirm={setFilters}
              />
            </DropdownPopper>
          )}
        </Filter>
        <Filter
          label="Status"
          value={taskFilters.status}
          onConfirm={(value, cb) => {
            setTaskFilters({ ...taskFilters, status: value })
            cb?.()
          }}
          operatorsList={[
            {
              label: 'Is',
              value: IS
            },
            {
              label: 'Is not',
              value: IS_NOT
            }
          ]}
        >
          {({ filters, setFilters }: InjectedProps) => (
            <DropdownPopper
              value={filters?.values ?? []}
              placeholder="Select status(s)"
              label="status"
            >
              <CheckboxList
                url="/task-management/task-status-types/?includeClientSettings"
                value={filters?.values ?? []}
                onConfirm={setFilters}
                serialiser={toStatusesOptions}
              />
            </DropdownPopper>
          )}
        </Filter>
        <Filter
          label="Due Date"
          value={taskFilters.dueDate}
          onConfirm={(value, cb) => {
            setTaskFilters({ ...taskFilters, dueDate: value })
            cb?.()
          }}
          validation={(value: FiltersType | null) => {
            // return true and let the filter default validation take care of it
            if (!value?.operator || !value.values) return true
            if (value.operator.value === IS_BETWEEN && value.values?.length < 2) {
              return false
            }
            return true
          }}
          operatorsList={[
            {
              label: 'Is',
              value: IS
            },
            {
              label: 'Is between',
              value: IS_BETWEEN
            },
            {
              label: 'Is before',
              value: IS_BEFORE
            },
            {
              label: 'Is after',
              value: IS_AFTER
            },
            {
              label: 'Is set',
              value: IS_SET
            },
            {
              label: 'Is not set',
              value: IS_NOT_SET
            }
          ]}
          customLabel={() => {
            const filters = taskFilters.dueDate
            if (!filters) return 'Due Date'
            switch (filters.operator?.value) {
              case IS:
                return `Due ${moment(filters.values?.[0].value).format(DEFAULT_DATE)}`
              case IS_BETWEEN:
                return `Due ${moment(filters.values?.[0].value).format(DEFAULT_DATE)} - ${moment(
                  filters.values?.[1].value
                ).format(DEFAULT_DATE)}`
              case IS_AFTER:
                return `Due after ${moment(filters.values?.[0].value).format(DEFAULT_DATE)}`
              case IS_BEFORE:
                return `Due before ${moment(filters.values?.[0].value).format(DEFAULT_DATE)}`
              case IS_SET:
                return 'Due Date is set'
              case IS_NOT_SET:
                return 'Due Date is not set'
              default:
                return 'Due Date'
            }
          }}
        >
          {({ filters, setFilters }: InjectedProps) => {
            return (
              <DateRangePopper value={filters?.values ?? []}>
                <DueDatePicker
                  value={getPickerValue(filters, 0)}
                  onConfirm={(value: DateFormat | undefined | Date) => {
                    setFilters([
                      ...(value
                        ? [
                            {
                              value: value?.toString() ?? '',
                              label: 'Start Date'
                            }
                          ]
                        : []),
                      ...(filters?.values?.[1] ? [filters?.values?.[1]] : [])
                    ])
                  }}
                />
                {filters?.operator?.value === IS_BETWEEN ? (
                  <DueDatePicker
                    value={getPickerValue(filters, 1)}
                    onConfirm={(value: DateFormat | undefined | Date) => {
                      setFilters([
                        ...(filters?.values?.[0] ? [filters?.values?.[0]] : []),
                        ...(value
                          ? [
                              {
                                value: value?.toString() ?? '',
                                label: 'End Date'
                              }
                            ]
                          : [])
                      ])
                    }}
                  />
                ) : null}
              </DateRangePopper>
            )
          }}
        </Filter>
        <Filter
          label="Priority"
          value={taskFilters.priority}
          onConfirm={(value, cb) => {
            setTaskFilters({ ...taskFilters, priority: value })
            cb?.()
          }}
        >
          {({ filters, setFilters }: InjectedProps) => (
            <DropdownPopper
              value={filters?.values ?? []}
              placeholder="Select priority(s)"
              label="priority"
            >
              <CheckboxList
                url="/task-management/task-priorities/?includeClientSettings"
                value={filters?.values ?? []}
                onConfirm={setFilters}
                serialiser={toPrioritiesOptions}
              />
            </DropdownPopper>
          )}
        </Filter>
        <Filter
          label="Type"
          value={taskFilters.taskType}
          onConfirm={(value, cb) => {
            setTaskFilters({ ...taskFilters, taskType: value })
            cb?.()
          }}
        >
          {({ filters, setFilters }: InjectedProps) => (
            <DropdownPopper value={filters?.values ?? []} placeholder="Select type(s)" label="type">
              <CheckboxList
                url="/task-management/task-types/"
                value={filters?.values ?? []}
                onConfirm={setFilters}
                isPaginated
                firstPageIndex={1}
              />
            </DropdownPopper>
          )}
        </Filter>
        <Filter
          label="Comments"
          value={taskFilters.comments}
          hasOperators={false}
          onConfirm={(value, cb) => {
            setTaskFilters({ ...taskFilters, comments: value })
            cb?.()
          }}
          customLabel={() => {
            const value = taskFilters.comments?.values?.[0]
            return value ? `Tasks ${value.label.toLowerCase()}` : 'Comments'
          }}
          placeholder="Tasks"
        >
          {({ filters, setFilters }: InjectedProps) => (
            <Select
              value={filters?.values ?? []}
              onChange={value => {
                setFilters(value ? [value] : [])
              }}
              className={s.select}
              styles={customStyle}
              options={[
                {
                  label: 'With comments',
                  value: WITH_COMMENTS
                },
                {
                  label: 'Without comments',
                  value: WITHOUT_COMMENTS
                }
              ]}
            />
          )}
        </Filter>
        <Filter
          label="Files"
          value={taskFilters.fileAttachments}
          hasOperators={false}
          onConfirm={(value, cb) => {
            setTaskFilters({ ...taskFilters, fileAttachments: value })
            cb?.()
          }}
          customLabel={() => {
            const value = taskFilters.fileAttachments?.values?.[0]
            return value ? `Tasks ${value.label.toLowerCase()}` : 'Files'
          }}
          placeholder="Tasks"
        >
          {({ filters, setFilters }: InjectedProps) => (
            <Select
              value={filters?.values ?? []}
              onChange={value => {
                setFilters(value ? [value] : [])
              }}
              className={s.select}
              styles={customStyle}
              options={[
                {
                  label: 'With attached files',
                  value: WITH_ATTACHMENTS
                },
                {
                  label: 'Without attached files',
                  value: WITHOUT_ATTACHMENTS
                }
              ]}
            />
          )}
        </Filter>
        <Filter
          placeholder="Created By"
          label="Creator"
          labelPosition="right"
          value={taskFilters.createdBy}
          onConfirm={(value, cb) => {
            setTaskFilters({ ...taskFilters, createdBy: value })
            cb?.()
          }}
          operatorsList={[
            {
              label: 'Is',
              value: IS
            },
            {
              label: 'Is not',
              value: IS_NOT
            }
          ]}
          customLabel={
            taskFilters.createdBy?.values && taskFilters.createdBy?.values?.length > 1
              ? undefined
              : () => {
                  const filters = taskFilters.createdBy
                  if (!filters) return 'Created By'
                  switch (filters.operator?.value) {
                    case IS:
                      return `Created by ${filters.values?.[0].label}`
                    case IS_NOT:
                      return `Is not Created by ${filters.values?.[0].label}`
                    default:
                      return 'Created By'
                  }
                }
          }
        >
          {({ filters, setFilters }: InjectedProps) => (
            <DropdownPopper
              value={filters?.values ?? []}
              placeholder="Select creator"
              label="creator"
            >
              <Users
                value={(filters?.values as Option[]) ?? []}
                style={{ width: '400px' }}
                requestParams={{ active: true }}
                onConfirm={setFilters}
              />
            </DropdownPopper>
          )}
        </Filter>
        <Filter
          label="Created Date"
          value={taskFilters.createdDate}
          onConfirm={(value, cb) => {
            setTaskFilters({ ...taskFilters, createdDate: value })
            cb?.()
          }}
          validation={(value: FiltersType | null) => {
            // return true and let the filter default validation take care of it
            if (!value?.operator || !value.values) return true
            if (value.operator.value === IS_BETWEEN && value.values?.length < 2) {
              return false
            }
            return true
          }}
          operatorsList={[
            {
              label: 'Is',
              value: IS
            },
            {
              label: 'Is between',
              value: IS_BETWEEN
            },
            {
              label: 'Is before',
              value: IS_BEFORE
            },
            {
              label: 'Is after',
              value: IS_AFTER
            }
          ]}
          customLabel={() => {
            const filters = taskFilters.createdDate
            if (!filters) return 'Created Date'
            switch (filters.operator?.value) {
              case IS:
                return `Created ${moment(filters.values?.[0].value).format(DEFAULT_DATE)}`
              case IS_BETWEEN:
                return `Created ${moment(filters.values?.[0].value).format(
                  DEFAULT_DATE
                )} - ${moment(filters.values?.[1].value).format(DEFAULT_DATE)}`
              case IS_AFTER:
                return `Created after ${moment(filters.values?.[0].value).format(DEFAULT_DATE)}`
              case IS_BEFORE:
                return `Created before ${moment(filters.values?.[0].value).format(DEFAULT_DATE)}`
              case IS_SET:
                return 'Created Date is set'
              case IS_NOT_SET:
                return 'Created Date is not set'
              default:
                return 'Created Date'
            }
          }}
        >
          {({ filters, setFilters }: InjectedProps) => {
            return (
              <DateRangePopper value={filters?.values ?? []}>
                <DueDatePicker
                  value={getPickerValue(filters, 0)}
                  onConfirm={(value: DateFormat | undefined | Date) => {
                    setFilters([
                      ...(value
                        ? [
                            {
                              value: value?.toString() ?? '',
                              label: 'Start Date'
                            }
                          ]
                        : []),
                      ...(filters?.values?.[1] ? [filters?.values?.[1]] : [])
                    ])
                  }}
                />
                {filters?.operator?.value === IS_BETWEEN ? (
                  <DueDatePicker
                    value={getPickerValue(filters, 1)}
                    onConfirm={(value: DateFormat | undefined | Date) => {
                      setFilters([
                        ...(filters?.values?.[0] ? [filters?.values?.[0]] : []),
                        ...(value
                          ? [
                              {
                                value: value?.toString() ?? '',
                                label: 'End Date'
                              }
                            ]
                          : [])
                      ])
                    }}
                  />
                ) : null}
              </DateRangePopper>
            )
          }}
        </Filter>
        <div className={s.reset} onClick={clearFilters}>
          Reset
        </div>
      </div>
    )
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps.taskFilters, nextProps.taskFilters)
  }
)

export default Filters
