import { Event } from '../types'
import format from 'date-fns/format'
import s from './EventDetails.scss'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { Location } from 'common/Events/types'
import { timezoneUtils } from 'utils/helpers'

interface Props {
  event: Event | null
  changeTab?: (tab: string) => void
}

const EventDetails = ({ event, changeTab }: Props) => {
  const location = useLocation()

  const handleEditClick = (event: Event) => {
    const queryParams = queryString.parse(location.search)
    const newSearch = queryString.stringify({ ...queryParams, id: event.id })

    const url = changeTab
      ? `/v2/matters/${event?.relatedMatter?.value}/?tab=events&id=${event.id}#category=all`
      : `/v2/matters/${event?.relatedMatter?.value}/?tab=events&${newSearch}`

    window.location.href = url
  }

  const handleRelatedClick = () => {
    if (event?.relatedMatter) {
      window.location.href = `/v2/matters/${event.relatedMatter.value}`
    }
  }

  const getLocation = (location: Location | null) => {
    let element: JSX.Element | string = ''

    if (!location) return '-'

    if (typeof location === 'object' && 'address' in location) {
      element = location.address

      if ('name' in location && location.name.trim() !== location.address.trim()) {
        element = (
          <>
            <b>{location.name}</b> {location.address}
          </>
        )
      }
    }

    return element
  }

  const getAttendeesLabel = () => {
    if (!event?.attendees) return '-'

    if (event.attendees.length > 3) {
      const attendees = event.attendees
        .slice(0, 2)
        .map(attendee => attendee.label)
        .join(', ')

      return `${attendees} +${event.attendees.length - 2} more`
    }

    return event.attendees.map(attendee => attendee.label).join(', ')
  }

  return (
    <div
      style={{ borderTop: `4px solid ${event?.isExternal ? '#F59E0B' : '#0EA5E9'}`, width: 350 }}
    >
      <div style={{ padding: '24px 24px 12px' }}>
        <h2 style={{ fontSize: 16, fontWeight: 700, color: '#0957AE', wordWrap: 'break-word' }}>
          <a style={{ cursor: 'pointer' }} onClick={() => (event ? handleEditClick(event) : null)}>
            {event?.title}
          </a>
        </h2>
        <h3 className={s.subtitle}>
          <span style={{ color: '#767676', fontSize: 14, fontWeight: 400 }}>
            {event && event.start
              ? format(new Date(event.startDate as string), 'EEEE, MMMM d')
              : ''}
            {event && event.endDate
              ? ' @ ' +
                format(new Date(event.startDate as string), 'h:mm a') +
                ' - ' +
                format(new Date(event.endDate as string), 'h:mm a') +
                ' ' +
                timezoneUtils.getUserTimezone()
              : ''}
          </span>
        </h3>
      </div>
      <div className={s.content}>
        <p className={s.row}>
          <span className={s.label}>Location:</span>
          <span className={s.value}>{getLocation(event?.location ?? null)}</span>
        </p>
        <p className={s.row}>
          <span className={s.label}>Related to:</span>
          <span
            onClick={handleRelatedClick}
            className={s.value}
            style={{ color: '#0957AE', cursor: 'pointer' }}
          >
            {event?.relatedMatter?.label ?? '-'}
          </span>
        </p>
        <p className={s.row}>
          <span className={s.label}>Attendees:</span>
          <span className={s.value}>{getAttendeesLabel()}</span>
        </p>
        <p className={s.row}>
          <span className={s.label}>Type:</span>
          <span className={s.value}>{event?.eventType?.name ?? '-'}</span>
        </p>
        <div className={s.footer}>
          <div onClick={() => (event ? handleEditClick(event) : null)} className={s.button}>
            Edit
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventDetails
