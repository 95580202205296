import { useState, CSSProperties, useEffect, Dispatch, SetStateAction } from 'react'
import s from './CalendarView.scss'
import { Calendar, dateFnsLocalizer, DateLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import { CustomHeader } from './CustomHeader'
import cn from 'classnames'
import { CheckboxContainer, Popover } from 'simple-core-ui'
import { Task } from './types'
import { AiFillCheckCircle } from 'react-icons/ai'
import { DayPicker } from 'react-day-picker8'
import { TaskDetails } from 'calendar_workbench/CalendarView/TaskDetails'

const locales = {
  'en-US': enUS
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
  dayFormat: (date: Date, culture: string, localizer: DateLocalizer) =>
    localizer.format(date, 'd', culture)
})

const addIcon = (task: Task) => {
  return (
    <>
      <AiFillCheckCircle className={s.eventIcon} /> {task.title}
    </>
  )
}

const CustomWeekHeader = ({
  date,
  weekdayStyles,
  dayStyles,
  styles = {}
}: {
  date: Date
  weekdayStyles?: CSSProperties
  dayStyles?: CSSProperties
  styles?: CSSProperties
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', ...styles }}>
      <span className={cn('weekday', s.weekday)} style={weekdayStyles}>
        {format(date, 'E').toUpperCase()}
      </span>
      <span className={cn('day', s.day)} style={dayStyles}>
        {format(date, 'dd')}
      </span>
    </div>
  )
}

interface Props {
  baseUrl: string
  changeTab?: (tab: string) => void
  fetchCalendarTasks: (date: Date, currentView: string) => void
  tasks: Task[]
  setTasks: Dispatch<SetStateAction<Task[]>>
  date: Date
  setDate: Dispatch<SetStateAction<Date>>
  currentView: string
  setCurrentView: Dispatch<SetStateAction<string>>
}

const CalendarView = ({
  changeTab,
  fetchCalendarTasks,
  tasks,
  setTasks,
  date,
  setDate,
  currentView,
  setCurrentView
}: Props) => {
  const [tasksEventsSelected, setTasksEventsSelected] = useState(true)

  useEffect(() => {
    fetchCalendarTasks(date, currentView)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, currentView])

  const handleDaySelect = (date: Date) => {
    setDate(date)
  }

  return (
    <div style={{ display: 'flex' }}>
      <div
        className={cn(s.calendarWrapper, {
          [s.weekView]: currentView === 'week',
          [s.dayView]: currentView === 'day',
          [s.monthView]: currentView === 'month'
        })}
      >
        <Calendar
          localizer={localizer}
          views={['month', 'week', 'day']}
          components={{
            toolbar: props => (
              <CustomHeader
                {...props}
                date={date}
                onNavigate={setDate}
                currentView={currentView}
                setCurrentView={setCurrentView}
              />
            ),
            eventWrapper: (props: any) => {
              return (
                <Popover
                  placement="left"
                  contentClassName={s.popoverContent}
                  hasArrow={false}
                  stayOpenOnClick
                  trigger={props.children}
                  content={
                    <TaskDetails task={props.event} changeTab={changeTab} setTasks={setTasks} />
                  }
                />
              )
            },
            week: {
              header: CustomWeekHeader
            },
            timeGutterHeader: () => {
              if (currentView !== 'day') return null
              return (
                <CustomWeekHeader
                  date={date}
                  styles={{ alignItems: 'center' }}
                  weekdayStyles={{ color: '#0957AE', marginBottom: -1, marginTop: 3 }}
                  dayStyles={{ color: '#0957AE' }}
                />
              )
            }
          }}
          date={date}
          onNavigate={setDate}
          startAccessor="start"
          endAccessor="end"
          events={tasksEventsSelected ? tasks : []}
          popup
          // @ts-expect-error
          titleAccessor={task => addIcon(task)}
          allDayMaxRows={3}
          formats={{
            dateFormat: 'd',
            timeGutterFormat: (date, culture, localizer) =>
              localizer ? localizer.format(date, 'h a', culture) : ''
          }}
        />
      </div>
      <div className={s.dayPicker}>
        <DayPicker
          mode="single"
          defaultMonth={date}
          selected={date}
          onSelect={date => (date ? handleDaySelect(date) : null)}
          modifiersStyles={{
            selected: { background: '#E2F0FF', border: '2px solid #3C98FD', color: '#000000' }
          }}
        />
        <div className={s.calendars}>
          <p className={s.label}>Calendars</p>
          <CheckboxContainer
            className={cn(s.tasksCheckbox, tasksEventsSelected && s.selected)}
            size="md"
            isChecked={tasksEventsSelected}
            cb={() => setTasksEventsSelected(!tasksEventsSelected)}
          />
          <span style={{ fontSize: 16, fontWeight: 700, color: '#047857' }}>
            <AiFillCheckCircle className={s.eventIcon} /> Tasks
          </span>
        </div>
      </div>
    </div>
  )
}

export default CalendarView
