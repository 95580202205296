import { useEffect, useState } from 'react'
import { KanbanBoard } from 'common/KanbanBoard'
import { Task, CustomizedOption, TaskFilters, Params } from '../types'
import { toBoardData, toCard } from './serializers'
import { useLoading } from 'simple-core-ui'
import { useDispatch } from 'react-redux'
import { makeGetRequest } from 'utils/api'
import { toStatusesOptions } from '../serializers'
import { BoardData, Lane, Card as CardType } from './types'
import { LaneHeader } from './LaneHeader'
import { Card } from './Card'
import s from './Board.scss'
import debounce from 'debounce-promise'
import cloneDeep from 'lodash/cloneDeep'

interface Props {
  tasks: Task[]
  readOnly: boolean
  editTask: (task: Task) => void
  onDragEnd?: (
    targetLaneId: number,
    id: number | string,
    sourceLaneId: number,
    cb?: (sourceLaneId: number, targetLaneId: number) => void
  ) => void
  fetchTasks?: ({
    filters,
    tableParams,
    cb,
    hash,
    tab,
    concatTasks
  }: {
    filters?: TaskFilters
    tableParams?: Params
    cb?: (url: string, hasMore: boolean) => void
    hash?: string
    tab?: string
    concatTasks?: boolean
  }) => void
  filters?: TaskFilters
  boardHasPagination: Record<string, boolean>
  boardColumnTotalEntries: Record<string, number>
}

const Board = ({
  tasks,
  readOnly,
  editTask,
  onDragEnd,
  fetchTasks,
  filters,
  boardHasPagination,
  boardColumnTotalEntries
}: Props) => {
  const [statuses, setStatuses] = useState<(CustomizedOption & { phase?: string })[]>([])
  const dispatch = useDispatch()
  const [, withLoadingLocks] = useLoading()
  const [data, setData] = useState<BoardData>({ lanes: [] })
  const [pagination, setPagination] = useState<{
    [key: string]: { page: number; isLast: boolean }
  }>({})

  const resetPagination = () => {
    setPagination({
      ...statuses.reduce((acc: { [key: string]: { page: number; isLast: boolean } }, status) => {
        const { value } = status
        acc[value] = { page: 1, isLast: !boardHasPagination[value] }
        return acc
      }, {})
    })
  }

  // this is needed because product wants to keep the cards position  after the users movesq them
  // until the page is refreshed. This is not the default behavior of the kanban board
  // because when the user moves or edit or add a card, the board is re-rendered and the cards are re-ordered as they initially were.
  // the performance of this is decent though. it can be improved a bit by using sets instead of find in arrays
  // but i expect it to be fine since we only have 4 columns. with 4 columns and 2-300 cards it should be unoticeable
  const getUpdatedBoard = (
    oldBoard: BoardData,
    newTasks: Task[],
    boardColumnTotalEntries: Record<string, number>
  ): BoardData => {
    const newBoard = cloneDeep(oldBoard)

    // Create a mapping of card IDs to their corresponding tasks in the new array
    const taskMap = newTasks.reduce((map: Record<string | number, Task>, task: Task) => {
      map[String(task.id)] = task
      return map
    }, {})

    // Update the board based on the task map
    newBoard.lanes.forEach((lane: Lane) => {
      lane.totalEntries = boardColumnTotalEntries[lane.id]
      lane.cards.forEach((card, index) => {
        const updatedCard = taskMap[String(card.id)]
        if (updatedCard) {
          // If the card exists in the new array, update its properties
          lane.cards[index] = { ...card, ...toCard(updatedCard, updatedCard.status?.color) }
        }
      })

      // Filter out cards that were present in the original board but are not in the new tasks
      lane.cards = lane.cards.filter((card: CardType) => {
        return newTasks.find(
          (task: Task) =>
            String(task.status?.id) === String(lane.id) && String(card.id) === String(task.id)
        )
      })

      // Filter out cards that were not present in the original board but are in the new tasks
      const newCards = newTasks
        .filter((task: Task) => {
          return (
            String(task.status?.id) === String(lane.id) &&
            !lane.cards.find((card: CardType) => String(card.id) === String(task.id))
          )
        })
        .map((task: Task) => toCard(task, task.status?.color))

      lane.cards.unshift(...newCards)
    })

    return newBoard
  }

  useEffect(() => {
    if (!statuses.length || !filters) return

    resetPagination()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardHasPagination, statuses, filters])

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const statuses = await withLoadingLocks(
          makeGetRequest('/task-management/task-status-types/?includeClientSettings')
        )
        setStatuses(toStatusesOptions(statuses))
      } catch (error) {
        dispatch({ type: 'API_ERROR', error })
      }
    }

    fetchStatuses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (statuses.length === 0) {
      setData({ lanes: [] })
      return
    }

    if (
      data.lanes.reduce((acc, lane) => acc + lane.cards.length, 0) > 0 &&
      Object.values(boardColumnTotalEntries).reduce((acc, total) => acc + total, 0) > 0
    ) {
      setData(getUpdatedBoard(data, tasks, boardColumnTotalEntries))
      return
    }
    setData(toBoardData(tasks, statuses, boardColumnTotalEntries))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, statuses, readOnly, boardColumnTotalEntries])

  const onLaneScroll = debounce(
    (_requestedPage: number, laneId: number) => {
      if (
        !fetchTasks ||
        pagination[laneId].isLast ||
        (filters?.status && !filters?.status.values?.map(v => +v.value).includes(+laneId))
      )
        return

      fetchTasks({
        filters: {
          ...filters,
          status: {
            values: [{ value: laneId, label: '' }],
            operator: { label: 'Is', value: 'IS' }
          }
        } as TaskFilters,
        tableParams: {
          page: pagination[laneId].page + 1,
          pageSize: 10,
          search: '',
          category: 'all',
          ordering: { columnKey: 'dueDate', isDesc: false }
        },
        cb: (_url: string, hasMore: boolean) => {
          const newPagination = { ...pagination }
          newPagination[laneId] = {
            page: pagination[laneId].page + 1,
            isLast: !hasMore
          }
          setPagination(newPagination)

          return new Promise(function(resolve) {
            resolve([])
          })
        },
        concatTasks: true
      })
    },
    4000,
    { leading: true }
  )

  return (
    <div className={s.wrapper}>
      <KanbanBoard
        draggable={!readOnly}
        data={data}
        components={{
          LaneHeader,
          Card: props => <Card {...props} tasks={tasks} editTask={editTask} />
        }}
        cardDragClass={s.cardDragClass}
        onDragEnd={(
          targetLaneId: number,
          id: string | number,
          sourceLaneId: number,
          position: number
        ) =>
          onDragEnd?.(targetLaneId, id, sourceLaneId, () => {
            const movedTask = data.lanes[sourceLaneId].cards.find(t => +t.id === +id)

            if (!movedTask) return

            const newColor = statuses.find(s => +s.value === +targetLaneId)?.color

            const { lanes } = cloneDeep(data)
            const sourceLane = lanes[sourceLaneId]
            const targetLane = lanes[targetLaneId]

            const updatedSourceCards = sourceLane.cards.filter(t => +t.id !== +id)
            const updatedTargetCards = [
              ...targetLane.cards.slice(0, position),
              { ...movedTask, color: newColor },
              ...targetLane.cards.slice(position)
            ]

            sourceLane.cards = updatedSourceCards
            sourceLane.totalEntries =
              sourceLane.totalEntries !== undefined ? sourceLane.totalEntries - 1 : 0
            targetLane.totalEntries =
              targetLane.totalEntries !== undefined ? targetLane.totalEntries + 1 : 0
            targetLane.cards = updatedTargetCards

            setData({ lanes })
          })
        }
        onLaneScroll={onLaneScroll}
      />
    </div>
  )
}

export default Board
