export const REPORT_LIST_KEY = 'report_list'
export const ADHOC_REPORT_KEY = 'reportbuilder_adhoc'
export const INVOICES_CATEGORIES = [
  'Invoice Adjustments',
  'Invoice Allocations',
  'Invoice List',
  'AI Powered Invoice Review Flagged Report'
]
export const MATTERS_CATEGORIES = [
  'Matter Activity',
  'Matter Inventory',
  'Matter Overview',
  'Matter Cost Comparison',
  'Task Productivity by Assignee'
]
export const VENDORS_CATEGORIES = [
  'Accruals vs. Actuals',
  'Activity by Lead Attorney',
  'Timekeeper Diversity',
  'Rate Comparison'
]
export const SPEND_CATEGORIES = ['Monthly Spend', 'Task Code Comparison', 'Timekeeper Activity']
export const CUSTOM_REPORTS_KEYS = [
  'export_csv_report_list',
  'custom_report_management',
  'custom_report_exports'
]

export const TABLEAU_REPORTS = 'tableau_reports'

export const STANDARD_TAB_HEADERS = {
  all: 'All',
  Invoices: 'Invoices',
  Matters: 'Matters',
  Vendors: 'Vendors',
  Spend: 'Spend'
}
export const ALL_SUPERSET_DASHBOARDS_PAGE_SIZE = -1
export const SUPERSET_CATEGORY_DRAFT = 'Draft'
export const REPORT_TYPES = {
  live: 'live',
  daily: 'daily'
}
export const LEGEND_ICON_DESCRIPTION = {
  live: ['Data updated ', 'in real time'],
  daily: ['Data updated ', 'once every 24 hours']
}
export const SIMPLESET_USER_ROLES = {
  view: 'View',
  create: 'Create',
  none: 'None'
}
