import { ModalContainer, CharLimitInput } from 'simple-core-ui'
import s from './FolderModal.scss'

interface Props {
  folderName: string
  saveFolder: () => void
  closeModal: () => void
  isDuplicate: boolean
  isEditMode: boolean
  updateFolderName: (name: string) => void
}

const CHAR_LIMIT = 50

const FolderModal = ({
  folderName,
  saveFolder,
  closeModal,
  isDuplicate,
  isEditMode,
  updateFolderName
}: Props) => {
  return (
    <ModalContainer
      title={`${isEditMode ? 'Rename' : 'Create'} Folder`}
      size="sm"
      confirmText="Save"
      confirmCb={saveFolder}
      cancelCb={closeModal}
      isDisabled={isDuplicate || !folderName?.trim()}
      content={
        <div>
          <div className={s.title}>Folder Name</div>
          <CharLimitInput
            value={folderName}
            onChangeCb={e => updateFolderName(e.target.value)}
            placeholder="Folder Name"
            dynamicCharCalculation
            maxLength={CHAR_LIMIT}
            customErrorMessage={
              isDuplicate ? 'The folder name already exists. Specify another name.' : ''
            }
            hasError={isDuplicate}
            maxCharMsgClassName={s.message}
            cssClass={(isDuplicate || folderName.length >= CHAR_LIMIT) && s.errorClass}
          />
        </div>
      }
    />
  )
}

export default FolderModal
