import { useState, CSSProperties, useRef, useEffect, KeyboardEvent } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import '!style-loader!css-loader!react-quill/dist/quill.snow.css' // eslint-disable-line
import cn from 'classnames'
import s from './RichTextEditor.scss'
import { FaExpandAlt, FaCompressAlt } from 'react-icons/fa'

const Link = Quill.import('formats/link')
class MyLink extends Link {
  static create(value: string | string[]) {
    const node = super.create(value)
    let href
    if (!value.includes('http')) {
      href = `http://${value}`
    } else {
      href = this.sanitize(value)
    }
    node.setAttribute('href', href)
    node.setAttribute('rel', 'noopener noreferrer')
    node.setAttribute('target', '_blank')
    return node
  }

  static formats(domNode: HTMLElement) {
    return domNode.getAttribute('href') || ''
  }

  format(name: string, value: string) {
    let href = value
    if (name === 'link' && typeof value === 'string') {
      if (!value.startsWith('http://') && !value.startsWith('https://')) {
        href = `http://${value}`
      }
      this.domNode.setAttribute('href', href)
    } else {
      super.format(name, value)
    }
  }
}
Quill.register('formats/link', MyLink, true)

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ color: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
    [{ align: [] }]
  ]
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'color',
  'list',
  'bullet',
  'indent',
  'link',
  'align'
]

interface Props {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  readOnly?: boolean
  style?: CSSProperties
  className?: string
  isExpanded?: boolean
  maxLength?: number
  dynamicCharCalculation?: boolean
  validation?: (value: boolean) => void
  onBlur?: () => void
  customErrorMessage?: string
  focused?: boolean
}

const RichTextEditor = ({
  value,
  onChange,
  placeholder = '',
  readOnly = false,
  style,
  className,
  isExpanded,
  maxLength,
  dynamicCharCalculation = false,
  validation,
  onBlur,
  customErrorMessage,
  focused
}: Props) => {
  const [expanded, setExpanded] = useState(isExpanded ?? false)
  const [hasLengthError, setHasLengthError] = useState(false)
  const reactQuillRef = useRef<ReactQuill>(null)
  const [maxCharMsg, setMaxCharMsg] = useState('')

  const focusInput = () => {
    const unprivilegedEditor = reactQuillRef.current?.getEditor()
    if (unprivilegedEditor) {
      unprivilegedEditor.focus()
    }
  }

  useEffect(() => {
    const handleMouseDown = (e: Event) => {
      e.stopPropagation()
      e.preventDefault()
    }
    // this is to prevent the toolbar from triggering onBlur when clicking on it
    // it's a bug in quill.js that has been reported in 2018 but not fixed yet
    document?.querySelector('.ql-toolbar')?.addEventListener('mousedown', handleMouseDown)

    return () => {
      document?.querySelector('.ql-toolbar')?.removeEventListener('mousedown', handleMouseDown)
    }
  }, [])

  const checkCharacterCount = (event?: KeyboardEvent): void => {
    if (!maxLength) return
    const unprivilegedEditor = reactQuillRef.current?.unprivilegedEditor
    setMaxCharMsg(
      dynamicCharCalculation && unprivilegedEditor?.getLength() && maxLength
        ? `${unprivilegedEditor.getLength() - 1}/${maxLength + 1 - unprivilegedEditor.getLength()}`
        : `Max character limit: ${maxLength}`
    )

    if (reactQuillRef.current) {
      if (event?.key === 'Backspace') setHasLengthError(false)
      if (
        unprivilegedEditor &&
        unprivilegedEditor.getLength() > maxLength &&
        event?.key !== 'ArrowLeft' &&
        event?.key !== 'Backspace' &&
        event?.key !== 'ArrowRight' &&
        event?.key !== 'ArrowUp' &&
        event?.key !== 'ArrowDown' &&
        !((event?.ctrlKey || event?.metaKey) && event?.key.toLowerCase() === 'a') &&
        !((event?.ctrlKey || event?.metaKey) && event?.key.toLowerCase() === 'c')
      ) {
        setHasLengthError(true)
        event?.preventDefault()
      }
    }
  }

  useEffect(() => {
    checkCharacterCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    focused && focusInput()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused])

  useEffect(() => {
    if (validation) {
      const unprivilegedEditor = reactQuillRef.current?.unprivilegedEditor
      validation(
        unprivilegedEditor && maxLength ? unprivilegedEditor.getLength() > maxLength + 1 : false
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLengthError, value])

  return (
    <div
      className={s.wrapper}
      onBlur={event => {
        // This is to prevent the onBlur from triggering when clicking on the toolbar or some tooltips (like the link tooltip)
        // because of a bug in quill.js that has been reported in 2018 but not fixed yet
        if (onBlur) {
          if (
            (event.relatedTarget &&
              (event.relatedTarget.classList.contains('ql-clipboard') ||
                (event.relatedTarget as HTMLElement).tagName === 'INPUT')) ||
            (event.target && (event.target as HTMLElement).tagName === 'INPUT') ||
            (event.relatedTarget && event.relatedTarget.classList.contains('js-changeSize')) ||
            !event.target.nextElementSibling?.nextElementSibling?.classList.contains('ql-hidden')
          ) {
            return
          }
          onBlur()
        }
      }}
    >
      {!expanded ? (
        <FaExpandAlt
          className={cn(s.changeSize, 'js-changeSize')}
          onClick={() => {
            setExpanded(true)
            focusInput()
          }}
          tabIndex={0}
        />
      ) : (
        <FaCompressAlt
          className={cn(s.changeSize, 'js-changeSize')}
          onClick={() => {
            setExpanded(false)
            focusInput()
          }}
          tabIndex={1}
        />
      )}
      <ReactQuill
        className={cn(s.container, {
          [className as string]: className,
          [s.expanded]: expanded,
          [s.error]: hasLengthError
        })}
        onKeyDown={checkCharacterCount}
        style={style}
        ref={reactQuillRef}
        placeholder={placeholder}
        readOnly={readOnly}
        modules={modules}
        formats={formats}
        theme="snow"
        value={value}
        onChange={value => {
          if (maxLength) {
            checkCharacterCount()
          }
          let processedHtml = value
          if (value.match(/^([ ]*<p><br><\/p>)*$/gi)) {
            processedHtml = value.replace(/(^([ ]*<p><br><\/p>)*)/gi, '').trim()
          }
          onChange(processedHtml)
        }}
      />
      {customErrorMessage && hasLengthError && (
        <div className={cn(s.leftInfo, s.error)}>{customErrorMessage}</div>
      )}
      {maxLength && (
        <div className={cn(s.rightInfo, { [s.error]: hasLengthError })}>{maxCharMsg}</div>
      )}
    </div>
  )
}

export default RichTextEditor
