import {
  ModalContainer,
  Panel,
  ShareFillIcon,
  ShareLineIcon,
  Tab,
  Tabs,
  useLoading,
  UpdateToPremiumLabel,
  Tooltip
} from 'simple-core-ui'
import FilesList from './FilesList/FilesList'
import { resultsSerializer } from './serializer'
import { ExtensionImage } from 'common/FileUpload/ExtensionImage'
import { filesize } from 'filesize'
import { FileStatusIcon } from 'matters/detail/files/MatterAttachmentsTab/FileStatusIcon'
import { SerializedResultItem } from './types'
import { FileUpload } from 'common/FileUpload'
import { ActionsPopover } from 'files/ActionsPopover'
import { useEffect, useMemo, useState } from 'react'
import { makeGetRequest, makePatchRequest, makePostRequest } from 'utils/api'
import { ROW_ACTIONS, vendorRestrictedColumns } from './constants'
import { useDispatch } from 'react-redux'
import { DeleteConfirmation } from 'files/DeleteConfirmation'
import { RenameFile } from 'files/RenameFile'
import { viewAttachment } from 'matters/detail/files/MatterAttachmentsTab/utils'
import { CopyConfirmation } from 'files/CopyConfirmation'
import { getFileNameExtension } from 'files/utils'
import FileShareModal from './FileShare/FileShareModal'
import { serializeSharedWithUsers } from 'files/FileShare/serializer'
import { SharedUser } from './FileShare/types'
import s from './FileListsContainer.scss'
import {
  FILE_STATUS_SCANNING,
  FILE_STATUS_INFECTED,
  FILE_STATUS_CLEAN,
  FILE_STATUS_UPLOADED
} from 'common/FileUpload/constants'
import qs from 'query-string'
import ReactTooltip from 'react-tooltip'
import { isBasicTaskManagementPlan, isBasicSpendPlan } from 'utils/helpers'

const {
  object_id: vendorId,
  virus_scan_enabled: virusScanEnabled = false
} = window.serverContext.all()

const BASE_URL = `/upload/vendor/${vendorId}`

interface ModalAction {
  actionType: string
  itemId: string | null
  itemName: string
  itemType: string
}

const parsedQuery = qs.parse(window.location.search)

const tabIndices: Record<string, number> = {
  our_files: 0,
  vendor_files: 1
}

const isBasicMatterPlan = isBasicTaskManagementPlan()
const isStarterSpendPlan = isBasicSpendPlan()
const FILE_SHARING_LIMIT = 5

const FileListsContainer = () => {
  const [files, setFiles] = useState<SerializedResultItem[]>([])
  const [isShareModalVisible, setIsShareModalVisible] = useState(false)
  const [duplicateCopyModal, setDuplicateCopyModal] = useState(false)
  const [sharedWithUsers, setSharedWithUsers] = useState<SharedUser[]>([])
  const [fileName, setFileName] = useState('')
  const [fileId, setFileId] = useState('')

  const allowedFileStatusForSharing = [
    FILE_STATUS_CLEAN,
    ...(virusScanEnabled ? [] : [FILE_STATUS_UPLOADED])
  ]

  const handleShareClick = (shared_with_users: SharedUser[], file: SerializedResultItem) => {
    setSharedWithUsers(shared_with_users)
    setFileId(file?.id)
    setFileName(file?.fileName)
    setIsShareModalVisible(true)
  }

  const canShare = useMemo(
    () =>
      !isBasicMatterPlan || !isStarterSpendPlan
        ? true
        : files.filter(file => file.share.length > 0).length < FILE_SHARING_LIMIT,
    [files]
  )

  const columns = [
    {
      columnKey: 'fileType',
      content: 'Type',
      isSortable: true,
      isFilterable: true,
      render: (cell: { content: string }) => (
        <div className={s.iconContainer}>
          <ExtensionImage file={{ path: cell.content }} />
        </div>
      )
    },
    {
      columnKey: 'fileStatus',
      content: 'Scan Status',
      isSortable: true,
      isFilterable: true,
      render: (cell: { content: string }, { fileStatus }: { fileStatus: string }) => {
        return (
          <div className={s.fileStatusIcon}>
            <FileStatusIcon fileStatus={fileStatus} />
          </div>
        )
      }
    },
    {
      columnKey: 'fileName',
      content: 'File Name',
      isSortable: true,
      isFilterable: true
    },
    {
      columnKey: 'updated',
      content: 'Last Updated',
      isSortable: true,
      isFilterable: true
    },
    {
      columnKey: 'userFullName',
      content: 'Updated By',
      isSortable: true,
      isFilterable: true
    },
    {
      columnKey: 'fileSize',
      content: 'Size',
      isSortable: true,
      render: (cell: { content: string }) => filesize(cell.content)
    },
    {
      columnKey: 'share',
      content: 'Share',
      isSortable: false,
      isFilterable: false,
      render: (cell: { content: SharedUser[] }, rowData: SerializedResultItem) =>
        allowedFileStatusForSharing.includes(rowData?.fileStatus) ? (
          cell.content.length > 0 ? (
            <div className={s.iconContainer}>
              <ShareFillIcon
                style={{ color: '#3D99FD' }}
                onClick={() => {
                  handleShareClick(cell.content, rowData)
                }}
                className={s.shareIcon}
              />
            </div>
          ) : (
            <div>
              {canShare ? (
                <ShareLineIcon
                  onClick={() => {
                    handleShareClick(cell.content, rowData)
                  }}
                  className={s.shareIcon}
                />
              ) : (
                <div>
                  <Tooltip
                    trigger={
                      <ShareLineIcon
                        opacity={0.2}
                        className={s.shareIconDisable}
                        data-tip
                        data-for="share-tooltip"
                      />
                    }
                    content={
                      <div className={s.popper}>
                        <div className={s.textWrap}>
                          With your current plan, you can only share {FILE_SHARING_LIMIT} Files.
                          Contact customer success to upgrade
                          <br />
                        </div>
                        <UpdateToPremiumLabel />
                      </div>
                    }
                    placement="left"
                    hasArrow={false}
                    contentClassName={s.tooltip}
                  />
                </div>
              )}
            </div>
          )
        ) : (
          '--'
        )
    }
  ]

  const [modalAction, setModalAction] = useState<ModalAction>({
    actionType: '',
    itemId: null,
    itemName: '',
    itemType: ''
  })
  const [selectedTab, setSelectedTab] = useState(
    parsedQuery.section === 'vendor_files' ? 'vendor_files' : 'our_files'
  )

  const [isLoading, withLoadingLocks] = useLoading()
  const [, withLoadingLocksDelete] = useLoading()

  const dispatch = useDispatch()

  const getTabIndex = () => {
    switch (selectedTab) {
      case 'our_files':
        return 0
      case 'vendor_files':
        return 1
      default:
        return null
    }
  }

  const getFilesList = async () => {
    setFiles([])
    const category = selectedTab === 'our_files' ? 'attachment' : 'vendor_files'

    try {
      const response = await withLoadingLocks(makeGetRequest(`${BASE_URL}/${category}/`))
      const attachments = response?.attachments || response?.data

      setFiles(resultsSerializer(attachments))
      setModalAction({ actionType: '', itemId: null, itemName: '', itemType: '' })
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  useEffect(() => {
    getFilesList()
    ReactTooltip.rebuild()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab])

  const resetModalAction = () => {
    setModalAction({ actionType: '', itemId: null, itemName: '', itemType: '' })
  }

  const globalActions =
    selectedTab === 'our_files'
      ? [
          // @ts-expect-error
          <FileUpload
            key="file_upload_action"
            scopeId={vendorId}
            isEditable
            scope="vendor"
            onChangeAttachments={getFilesList}
            view="noList"
            files={files}
            hasNewButtons
            showReshareOption
          />
        ]
      : []

  const deleteFile = async () => {
    try {
      await withLoadingLocksDelete(
        makePostRequest(`/invoices/delete/vendor/${vendorId}/attachment/${modalAction.itemId}/`, {})
      )

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: 'File deleted successfully',
          message: 'The file was successfully deleted.',
          level: 'success'
        }
      })

      const updatedFiles = files.filter(fileItem => fileItem.id !== modalAction.itemId)
      setFiles(updatedFiles)
      resetModalAction()
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const allFileNames = useMemo(
    () => files.map(({ fileName, fileType, id }) => ({ fileName, fileType, id })),
    [files]
  )

  const renameFile = async (name: string) => {
    const url = `/doc_management/native_docs/rename_document/${modalAction.itemId}/`

    try {
      await withLoadingLocks(makePatchRequest(url, { name }))

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: 'Success',
          message: 'File successfully renamed',
          level: 'success'
        }
      })

      const { fileName } = getFileNameExtension(name)
      const updatedFiles = files.map(fileItem => ({
        ...fileItem,
        ...(modalAction.itemId === fileItem.id ? { fileName } : {})
      }))
      setFiles(updatedFiles)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
    resetModalAction()
  }

  const copyFile = async () => {
    const url = `/upload/vendor/${vendorId}/copy_vendor_file/${modalAction.itemId}/`

    try {
      const response = await withLoadingLocks(makePostRequest(url, {}))
      if (response['attachments']) {
        dispatch({
          type: 'PUSH_NOTIFICATION',
          payload: {
            title: 'Success',
            message: 'File successfully copied',
            level: 'success'
          }
        })
        setSelectedTab('our_files')
      } else {
        setDuplicateCopyModal(true)
      }
      resetModalAction()
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const actionCb = (actionType: string, rowData: SerializedResultItem) => {
    switch (actionType) {
      case ROW_ACTIONS.DELETE:
      case ROW_ACTIONS.RENAME:
      case ROW_ACTIONS.COPY:
        setModalAction({
          actionType,
          itemId: rowData.id,
          itemName: rowData.fileName,
          itemType: rowData.fileType
        })
        break
      case ROW_ACTIONS.DOWNLOAD:
        viewAttachment({ fileUrl: rowData.fileUrl, type: 'vendor' })
        break
      default:
        break
    }
  }

  const rowActions = (row: SerializedResultItem) => {
    const isInfected = row.fileStatus === FILE_STATUS_INFECTED
    if (row.fileStatus === FILE_STATUS_SCANNING) {
      return <span className={s.actionsPlaceholder}>...</span>
    }
    return (
      <ActionsPopover
        selectedTab={selectedTab}
        rowData={row}
        actionCb={actionCb}
        isInfected={isInfected}
      />
    )
  }

  return (
    <div>
      <Panel title="Files">
        <section>
          <Tabs selectedIndex={tabIndices[selectedTab]}>
            {/* @ts-expect-error */}
            <Tab
              selected={selectedTab === 'our_files'}
              onClick={() => {
                setSelectedTab('our_files')
              }}
              header="Our Files"
            >
              <div>
                <FilesList
                  files={files}
                  columns={columns}
                  globalActions={globalActions}
                  rowActions={rowActions}
                  isLoading={isLoading}
                  statusText="No Files Available."
                />
              </div>
            </Tab>
            {/* @ts-expect-error */}
            <Tab
              selected={selectedTab === 'vendor_files'}
              onClick={() => {
                setSelectedTab('vendor_files')
              }}
              header="Vendor Files"
            >
              <div>
                <FilesList
                  files={files}
                  columns={columns.filter(
                    ({ columnKey }) => !vendorRestrictedColumns.includes(columnKey)
                  )}
                  globalActions={[]}
                  rowActions={rowActions}
                  isLoading={isLoading}
                  statusText="No Files Available."
                />
              </div>
            </Tab>
          </Tabs>
          {isShareModalVisible && (
            <FileShareModal
              fileId={fileId}
              fileName={fileName}
              sharedWithUsers={serializeSharedWithUsers(sharedWithUsers)}
              closeShareModal={() => setIsShareModalVisible(false)}
              customQueryParams={{ vrn: '_general_file_sharing', v: vendorId }}
              updateFileData={getFilesList}
            />
          )}
          {duplicateCopyModal && (
            <ModalContainer
              title="File Cannot be Copied to Our Files"
              size="sm"
              hideButtons
              cancelCb={() => {
                setDuplicateCopyModal(false)
              }}
              content={
                <div>
                  This file is currently under Our Files (Each file can be copied only once).
                </div>
              }
              contentStyle={{ padding: '10px 24px 30px', minHeight: 0, color: '#555555' }}
            />
          )}
        </section>
      </Panel>
      {modalAction.actionType === ROW_ACTIONS.DELETE ? (
        <DeleteConfirmation onConfirm={deleteFile} onCancel={resetModalAction} />
      ) : modalAction.actionType === ROW_ACTIONS.RENAME ? (
        <RenameFile
          onConfirm={renameFile}
          onCancel={resetModalAction}
          fileName={modalAction.itemName}
          type={modalAction.itemType}
          fileId={modalAction.itemId}
          allFileNames={allFileNames}
        />
      ) : modalAction.actionType === ROW_ACTIONS.COPY ? (
        <CopyConfirmation onConfirm={copyFile} onCancel={resetModalAction} />
      ) : null}
    </div>
  )
}

export default FileListsContainer
