import { UserAPI, User, AdminUserAPI, FileSharingUserAPI } from './types'
import { timezoneDate } from 'utils/helpers'
import { parseISO, format } from 'date-fns'
import capitalize from 'lodash/capitalize'

export const toUsers = (users: UserAPI[]): User[] => {
  return users.map(user => ({
    id: user.user.id,
    name: user.user.name,
    email: user.user.email,
    role: capitalize(user.user.role),
    addedOn: format(timezoneDate(user.added), 'MM/dd/yyyy')
  }))
}

export const toAdminUsers = (users: AdminUserAPI[]) => {
  return users.map(u => ({
    value: u.user_id || u.id,
    label: u.full_name,
    email: u.primary_email,
    firstName: u.given_name,
    lastName: u.family_name
  }))
}

export const toFileSharingUsers = (results: FileSharingUserAPI[]): User[] => {
  return results.map(r => ({
    id: r.id,
    name: r.name,
    email: r.email,
    role: capitalize(r.orgrole),
    addedOn: format(parseISO(r.added_ts), 'MM/dd/yyyy')
  }))
}
