import classNames from 'classnames'
import { Anchor, Avatar, BuildingIcon, ConditionalRender, If } from 'simple-core-ui'

import { IoMdPhonePortrait, IoIosMail } from 'react-icons/io'

import { formatFullName } from 'utils/formatting'

import { StackText } from 'components'

import s from './Contact.scss'

const Contact = ({
  firstName,
  lastName,
  title,
  company,
  phone,
  email,
  readOnly,
  editCb,
  isEditable,
  active,
  url,
  hasNewForm
}) => (
  <section className={s.container}>
    <StackText>
      <If condition={firstName || lastName}>
        <span className={hasNewForm ? s.initials : ''}>
          {hasNewForm && (
            <Avatar
              initials={formatFullName(firstName, lastName)}
              className={s.avatar}
              styles={{
                height: '20px',
                width: '20px',
                border: '1px solid #3C98FD',
                marginLeft: '-2px'
              }}
              textClassName={s.avatarText}
            />
          )}
          <span className={hasNewForm ? s.newText : s.name}>
            <ConditionalRender
              conditions={[
                {
                  condition: url,
                  content: (
                    <Anchor url={url} style={{ border: 'none' }}>
                      <span
                        className={s.nameLink}
                        data-testid="contact_name"
                        title={`Navigate to ${formatFullName(firstName, lastName)}'s Profile`}
                      >
                        {formatFullName(firstName, lastName)}
                      </span>
                    </Anchor>
                  )
                }
              ]}
              fallback={formatFullName(firstName, lastName)}
            />
            {isEditable && editCb && active && (
              <span
                title="Edit Contact"
                data-testid={firstName + '_edit_icon'}
                className={classNames(s.icon, 'simple-line-icon-pencil')}
                onClick={editCb}
              />
            )}
          </span>
        </span>
      </If>
      <span className={hasNewForm ? s.initials : ''}>
        {hasNewForm && (
          <span style={{ paddingRight: '2px' }}>
            <BuildingIcon />
          </span>
        )}
        <span className={hasNewForm ? s.newText : ''}>
          <If condition={title}>{title}</If>
          <If condition={title && company}>{' - '}</If>
          <If condition={company}>{company}</If>
        </span>
      </span>
      {!hasNewForm && (
        <If condition={phone}>
          <span>
            <IoMdPhonePortrait /> {phone}
          </span>
        </If>
      )}

      <If condition={email}>
        {readOnly ? (
          <span className={hasNewForm ? s.initials : ''}>
            <IoIosMail className={hasNewForm ? s.mailIcon : ''} />{' '}
            <span className={hasNewForm ? s.newText : ''}>{email}</span>
          </span>
        ) : (
          <Anchor url={`mailto:${email}`}>
            <IoIosMail className={hasNewForm ? s.mailIcon : ''} /> {email}
          </Anchor>
        )}
      </If>
    </StackText>
  </section>
)

Contact.defaultProps = {
  readOnly: false
}

export default Contact
