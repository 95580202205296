import React from 'react'
import { put, takeLatest, all, call } from 'redux-saga/effects'
import get from 'lodash/get'
import omit from 'lodash/omit'

import APP_ACT from 'app/actions'
import ACT from './actions'

import { makeGetRequest, makePostRequest, makeDeleteRequest, handleError } from 'utils/api'

import { SCOPE } from 'utils/constants'

import {
  toContact,
  fromContact,
  fromRoles,
  fromContacts,
  toPartialContact,
  toAdditionalPhone,
  toAdditionalEmail,
  fromAdditionalPhones,
  fromAdditionalEmails
} from './serializer'

import { fromUserSettings, toUserSetting } from 'user_settings/serializer'

import {
  scopedRequestConfig,
  sortContacts,
  sortRoles,
  onlyAssignedContacts,
  isClientContact,
  CONTACTS_VENDOR_ROLES_URL,
  CONTACTS_MATTER_ROLES_URL,
  INITIAL_AFFILIATION_PARAMS,
  INITIAL_ADDRESS_BOOK_PARAMS,
  AFFILIATION_URLS,
  AFFILIATION_NOT_IMPLEMENTED,
  OPEN_AFFILIATION_CATEGORIES
} from './utils'

import { isArrayWithLength } from 'utils/helpers'

import ContactsSyncWarning from './ContactsSyncWarning/ContactsSyncWarning'

function* contactRolesFetch(action) {
  let apiMatterRoles = []
  let apiVendorRoles = []

  try {
    const matterResponse = yield makeGetRequest(CONTACTS_MATTER_ROLES_URL)
    const vendorResponse = yield makeGetRequest(CONTACTS_VENDOR_ROLES_URL)
    apiMatterRoles = matterResponse.roles
    apiVendorRoles = vendorResponse.roles
  } catch (e) {
    handleError(e, 'There was an issue fetching contact roles.')
  }

  const matterRoleSet = sortRoles(fromRoles(apiMatterRoles))
  const vendorRoleSet = sortRoles(fromRoles(apiVendorRoles))

  yield put({
    type: ACT.CONTACT_ROLES_FETCH_SUCCESS,
    payload: {
      [SCOPE.MATTER]: matterRoleSet,
      [SCOPE.VENDOR]: vendorRoleSet
    }
  })
}

function* individualContact(action) {
  try {
    yield put({ type: ACT.CONTACT_FETCH_LOADING })

    const { contactId } = action.payload
    const response = yield makeGetRequest(`/manage/contacts/${contactId}/`)
    const contact = fromContact(response)

    yield put({
      type: ACT.CONTACT_FETCH_SUCCESS,
      payload: contact
    })

    return contact
  } catch (e) {
    handleError(e, 'There was an issue fetching information for this contact.')
  }
}

function* assignedContactsFetch(action) {
  try {
    const { scope, scopeId, isSync } = action.payload
    const contactsRequestConfig = scopedRequestConfig(
      ACT.ASSIGNED_CONTACTS_FETCH_REQUESTED,
      scope,
      scopeId
    )

    const rolesRequestConfig = scopedRequestConfig(
      ACT.CONTACT_ROLES_FETCH_REQUESTED,
      scope,
      scopeId
    )

    const {
      results,
      currentCGAdminsNo,
      maxAllowedCGAdmins,
      userCanEditContactCG
    } = yield makeGetRequest(contactsRequestConfig.url, {
      params: contactsRequestConfig.params
    })
    const { roles } = yield makeGetRequest(rolesRequestConfig.url)

    const roleSet = sortRoles(fromRoles(roles))
    const assignedContacts = sortContacts(onlyAssignedContacts(fromContacts(results, roleSet)))

    yield put({
      type: ACT.ASSIGNED_CONTACTS_FETCH_SUCCESS,
      payload: {
        assignedContacts,
        roles: roleSet,
        currentCGAdminsNo,
        maxAllowedCGAdmins,
        userCanEditContactCG,
        ...action.payload
      }
    })

    if (isSync) {
      yield put({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Contacts Sync Successful',
          message: 'The contact information is now up to date.',
          level: 'success'
        }
      })
    }
  } catch (e) {
    handleError(e, 'There was an issue fetching contacts.')
  }
}

function* submitContactAssignment(action) {
  const { contact, roles, scope, scopeId, overwrite } = action.payload

  try {
    const { url, params } = scopedRequestConfig(ACT.ASSIGNED_CONTACTS_SUBMISSION_REQUESTED, scope, {
      scopeId,
      contactId: contact.id,
      roles,
      roleTimestamp: contact.roleTimestamp
    })

    const { role_timestamp } = yield makePostRequest(url, params) // eslint-disable-line camelcase

    yield put({
      type: ACT.ASSIGNED_CONTACTS_UPDATE_SUCCESS,
      payload: {
        contact,
        roles,
        overwrite
      }
    })

    yield put({
      type: ACT.ROLE_TIMESTAMP_UPDATE,
      payload: {
        contactId: contact.id,
        roleTimestamp: role_timestamp
      }
    })
  } catch (e) {
    if (e.response.status === 409) {
      yield put({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Data Sync Issue',
          message: <ContactsSyncWarning scope={scope} scopeId={scopeId} />,
          level: 'warning',
          dismissible: 'none',
          autoDismiss: 0,
          uid: ACT.ASSIGNED_CONTACTS_SYNC
        }
      })
    } else if (e.response.status === 403) {
      handleError(e, e.response.data.errors)
    } else {
      handleError(e, 'There was an issue updating the assigned contacts.')
    }
  }
}

function* removeAssignedContact(action) {
  const { scope, scopeId, contact, roles } = action.payload

  try {
    const { url, params } = scopedRequestConfig(ACT.ASSIGNED_CONTACT_REMOVAL_REQUESTED, scope, {
      scopeId,
      roles,
      contactId: contact.id,
      roleTimestamp: contact.roleTimestamp
    })

    yield makePostRequest(url, params)

    yield put({
      type: ACT.ASSIGNED_CONTACT_REMOVAL_SUCCESS,
      payload: action.payload
    })
  } catch (e) {
    if (e.response.status === 409) {
      yield put({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Data Sync Issue',
          message: <ContactsSyncWarning scope={scope} scopeId={scopeId} />,
          level: 'warning',
          dismissible: 'none',
          autoDismiss: 0,
          uid: ACT.ASSIGNED_CONTACTS_SYNC
        }
      })
    } else {
      handleError(e, 'There was an issue removing the assigned contact.')
    }
  }
}

function* removeAssignedContactRole(action) {
  const { contact, roleId, scope, scopeId } = action.payload

  try {
    const filteredRoles = contact.roles.filter(role => role.id !== roleId)

    const { url, params } = scopedRequestConfig(
      ACT.ASSIGNED_CONTACT_ROLE_REMOVAL_REQUESTED,
      scope,
      { scopeId, contactId: contact.id, roles: filteredRoles, roleTimestamp: contact.roleTimestamp }
    )

    const { role_timestamp } = yield makePostRequest(url, params) // eslint-disable-line camelcase

    yield put({
      type: ACT.ROLE_TIMESTAMP_UPDATE,
      payload: {
        contactId: contact.id,
        roleTimestamp: role_timestamp
      }
    })

    yield put({
      type: ACT.ASSIGNED_CONTACT_ROLE_REMOVAL_SUCCESS,
      payload: action.payload
    })
  } catch (e) {
    if (e.response.status === 409) {
      yield put({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Data Sync Issue',
          message: <ContactsSyncWarning scope={scope} scopeId={scopeId} />,
          level: 'warning',
          dismissible: 'none',
          autoDismiss: 0,
          uid: ACT.ASSIGNED_CONTACTS_SYNC
        }
      })
    } else {
      handleError(e, 'There was an issue removing a role from the assigned contact.')
    }
  }
}

function* assignedContactsSync(action) {
  try {
    yield put({
      type: ACT.ASSIGNED_CONTACTS_FETCH_REQUESTED,
      payload: { ...action.payload, isSync: true }
    })
  } catch (e) {
    handleError(e, 'There was an issue syncing contacts.')
  }
}

function* createNewContact(action) {
  try {
    const { roles, scope, scopeId } = action.payload

    const response = yield makePostRequest('/manage/contacts/new/', toContact(action.payload))
    const newContact = fromContact(response)

    yield put({
      type: ACT.CONTACT_CREATION_SUCCESS,
      payload: newContact
    })

    if (isArrayWithLength(roles)) {
      yield put({
        type: ACT.ASSIGNED_CONTACTS_SUBMISSION_REQUESTED,
        payload: {
          contact: newContact,
          roles,
          scope,
          scopeId
        }
      })
    }

    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Creation Successful',
        message: 'The contact has been successfully created!',
        level: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: 'API_ERROR',
      error
    })
  }
}

function* updateContact(action) {
  const { id, roles, scope, scopeId, roleTimestamp } = action.payload

  try {
    const response = yield makePostRequest(`/manage/contacts/${id}/`, toContact(action.payload))
    const updatedContact = { ...fromContact(response), roleTimestamp }
    delete updatedContact.roles //remove roles from response as roles assignment implemented using another API call
    yield put({
      type: ACT.CONTACT_UPDATE_SUCCESS,
      payload: updatedContact
    })

    if (roles.length) {
      yield put({
        type: ACT.ASSIGNED_CONTACTS_SUBMISSION_REQUESTED,
        payload: {
          contact: updatedContact,
          roles,
          scope,
          scopeId,
          overwrite: true
        }
      })
    } else {
      yield put({
        type: ACT.ASSIGNED_CONTACT_REMOVAL_REQUESTED,
        payload: {
          contact: updatedContact,
          scope: action.payload.scope,
          scopeId: action.payload.scopeId
        }
      })
    }
  } catch (e) {
    if (e.response.status === 409) {
      yield put({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Data Sync Issue',
          message: <ContactsSyncWarning scope={scope} scopeId={scopeId} />,
          level: 'warning',
          dismissible: 'none',
          autoDismiss: 0,
          uid: ACT.ASSIGNED_CONTACTS_SYNC
        }
      })
    } else {
      handleError(e, 'There was an issue updating the contact.')
    }
  }
}

function* deleteContact(action) {
  const { id, firstName, lastName, email } = action.payload

  try {
    yield makeDeleteRequest(`/manage/contacts/${id}/`)

    yield put({
      type: ACT.CONTACT_DELETE_SUCCESS,
      payload: id
    })

    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: `Deletion Successful`,
        message: (
          <React.Fragment>
            The contact{' '}
            <strong>
              {firstName} {lastName} ({email})
            </strong>{' '}
            has been deleted.
          </React.Fragment>
        ),
        level: 'success'
      }
    })
  } catch (e) {
    handleError(e, `There was an issue deleting the contact ${firstName} ${lastName} (${email}).`)
  }
}

function* contactProfileFetch(action) {
  yield put({ type: ACT.CONTACT_AFFILIATION_LOADING })
  yield put({ type: ACT.CONTACT_USER_SETTINGS_LOADING })

  yield put({
    type: ACT.CONTACT_AVATAR_FETCH_REQUESTED,
    payload: action.payload.contactId
  })

  yield put({
    type: ACT.CONTACT_ADDITIONAL_FIELDS_FETCH_REQUESTED,
    payload: action.payload.contactId
  })

  // Need to access contact for following affiliation tasks.
  // Ensure that all the roles are fetched for affiliation tasks.
  const [contact] = yield all([call(individualContact, action), call(contactRolesFetch)])

  const isClientType = isClientContact(contact)

  yield put({
    type: ACT.CONTACT_AFFILIATION_COUNTS_REQUESTED,
    payload: {
      ...action.payload,
      isClientType
    }
  })

  yield put({
    type: ACT.CONTACT_AFFILIATION_FETCH_REQUESTED,
    payload: {
      ...action.payload,
      ...INITIAL_AFFILIATION_PARAMS,
      isClientType
    }
  })

  if (isClientContact) {
    yield put({
      type: ACT.CONTACT_USER_SETTINGS_FETCH_REQUESTED,
      payload: contact.userId
    })
  }
}

function* contactActivityFetch(action) {
  try {
    yield put({ type: ACT.CONTACT_ACTIVITY_LOADING })

    const pageSize = 50
    const { contactId, offset } = action.payload

    const offsetParams = offset ? { t: offset } : {}

    // We want `n` set to `pageSize + 1` because that will be useful in
    // determining whether or not more logs are available for the user
    // to load.
    const { results } = yield makeGetRequest(`/manage/contacts/${contactId}/logbook/`, {
      params: { n: pageSize + 1, ...offsetParams }
    })

    // We only want to take a pageSize's worth of results being that an
    // extra log was queried for to check for hasMore.
    const logs = results.slice(0, pageSize)
    const hasMore = results.length > pageSize

    yield put({
      type: ACT.CONTACT_ACTIVITY_FETCH_SUCCESS,
      payload: {
        logs,
        hasMore,
        ...action.payload
      }
    })
  } catch (e) {
    handleError(e, 'There was an issue getting activity log information for this contact.')

    yield put({ type: ACT.CONTACT_ACTIVITY_ERROR })
  }
}

function* contactAffiliationFetch(action) {
  yield put({ type: ACT.CONTACT_AFFILIATION_LOADING })

  const { contactId, isClientType, pageSize, ordering, search, page, category } = action.payload

  const CATEGORY_URLS = AFFILIATION_URLS(contactId)

  let apiAffiliation = {}

  const requestParams = {
    params: {
      pageSize,
      isDesc: Number(ordering.isDesc),
      search,
      page: page - 1 // API is expecting 0 as first page.
    }
  }

  try {
    apiAffiliation =
      isClientType || OPEN_AFFILIATION_CATEGORIES.includes(category)
        ? yield call(makeGetRequest, CATEGORY_URLS[category], requestParams)
        : AFFILIATION_NOT_IMPLEMENTED

    yield put({
      type: ACT.CONTACT_AFFILIATION_FETCH_SUCCESS,
      payload: {
        apiAffiliation,
        category,
        ordering,
        page,
        pageSize
      }
    })
  } catch (e) {
    handleError(e, 'There was an issue getting affiliated information for this contact.')

    yield put({ type: ACT.CONTACT_AFFILIATION_ERROR })
  }
}

function* contactAffiliationCounts(action) {
  let affiliationCounts = {
    [SCOPE.MATTER]: 0,
    [SCOPE.VENDOR]: 0,
    [SCOPE.LEGAL_ENTITY]: 0,
    [SCOPE.PRACTICE_AREA]: 0
  }

  const { contactId, isClientType } = action.payload

  const CATEGORY_URLS = AFFILIATION_URLS(contactId)
  const clientOnlyCategories = [SCOPE.LEGAL_ENTITY, SCOPE.PRACTICE_AREA]
  const categories = isClientType
    ? [...OPEN_AFFILIATION_CATEGORIES, ...clientOnlyCategories]
    : OPEN_AFFILIATION_CATEGORIES
  const defaultParams = {
    params: { pageSize: 10, isDesc: 0, search: '', page: 0 }
  }

  try {
    const [matter, vendor, legalEntity, practiceArea] = yield all(
      categories.map(category => call(makeGetRequest, CATEGORY_URLS[category], defaultParams))
    )

    affiliationCounts = {
      [SCOPE.MATTER]: get(matter, 'totalEntries', 0),
      [SCOPE.VENDOR]: get(vendor, 'totalEntries', 0),
      [SCOPE.LEGAL_ENTITY]: get(legalEntity, 'totalEntries', 0),
      [SCOPE.PRACTICE_AREA]: get(practiceArea, 'totalEntries', 0)
    }

    yield put({
      type: ACT.CONTACT_AFFILIATION_COUNTS_SUCCESS,
      payload: affiliationCounts
    })
  } catch (e) {
    handleError(
      e,
      'There was an issue getting the counts for the affiliated information for this contact.'
    )

    yield put({ type: ACT.CONTACT_AFFILIATION_ERROR })
  }
}

function* updateContactAttributes(action) {
  try {
    yield put({
      type: ACT.CONTACT_ATTRIBUTES_UPDATE_SUCCESS,
      payload: action.payload
    })

    // TODO: Remove if block once backend consolidates Contact primary
    // email with User email.
    //
    // Conditional logic below is currently in place to separately
    // update primary email in cases where Contact is a login user.

    const { id, userId, email, ...restAttributes } = action.payload

    let partialContact = { email, ...restAttributes }

    if (userId && email) {
      partialContact = restAttributes
      yield makePostRequest(`/set_login_email_for_user/${userId}`, { email })
    }

    const serializedAttributes = toPartialContact(partialContact)
    yield makePostRequest(`/manage/contacts/${id}/`, serializedAttributes)
  } catch (e) {
    handleError(e, 'There was an issue updating a field for this contact.')
  }
}

function* fetchAvatar(action) {
  try {
    yield put({ type: ACT.CONTACT_AVATAR_LOADING })

    const url = `/upload/contacts/${action.payload}/`
    const response = yield call(makeGetRequest, url)

    yield put({
      type: ACT.CONTACT_AVATAR_FETCH_SUCCESS,
      payload: response ? url : null
    })
  } catch (e) {
    handleError(e, 'There was an issue fetching the profile picture for this contact.')
  }
}

function* uploadAvatar(action) {
  const { contactId, loggedInUser, avatar, uploadInfo } = action.payload
  try {
    yield put({ type: ACT.CONTACT_AVATAR_LOADING })

    const url = `/upload/contacts/${contactId}/`

    yield call(makePostRequest, url, uploadInfo)

    yield put({ type: ACT.CONTACT_AVATAR_UPLOAD_SUCCESS, payload: url })
    if (+contactId === +loggedInUser.contactId)
      yield put({ type: APP_ACT.ADD_AVATAR, payload: { url, version: avatar.version + 1 } })
  } catch (e) {
    handleError(e, 'There was an issue updating the profile picture for this contact.')
  }
}

function* deleteAvatar(action) {
  const { contactId, loggedInUser } = action.payload
  try {
    yield put({ type: ACT.CONTACT_AVATAR_LOADING })

    const url = `/upload/contacts/${contactId}/`
    yield call(makeDeleteRequest, url)

    yield put({ type: ACT.CONTACT_AVATAR_DELETE_SUCCESS })
    if (+contactId === +loggedInUser.contactId) yield put({ type: APP_ACT.REMOVE_AVATAR })
  } catch (e) {
    handleError(e, 'There was an issue removing the profile picture for this contact.')
  }
}

function* fetchAdditionalFields(action) {
  try {
    yield put({ type: ACT.CONTACT_ADDITIONAL_FIELDS_LOADING })

    const phoneUrl = `/manage/contacts/${action.payload}/phone/`
    const emailUrl = `/manage/contacts/${action.payload}/email/`

    const [{ phones }, { emails }] = yield all([
      call(makeGetRequest, phoneUrl),
      call(makeGetRequest, emailUrl)
    ])

    const notPrimary = ({ is_primary }) => !is_primary // eslint-disable-line camelcase

    yield put({
      type: ACT.CONTACT_ADDITIONAL_FIELDS_FETCH_SUCCESS,
      payload: {
        additionalPhones: fromAdditionalPhones(phones.filter(notPrimary)),
        additionalEmails: fromAdditionalEmails(emails.filter(notPrimary))
      }
    })
  } catch (e) {
    handleError(e, 'There was an issue fetching additional fields for this contact.')
  }
}

function* createAdditionalField(action) {
  try {
    const { contactId, field, value } = action.payload

    const fieldType = {
      additionalPhones: 'phone',
      additionalEmails: 'email'
    }[field]

    const serializer = {
      additionalPhones: toAdditionalPhone,
      additionalEmails: toAdditionalEmail
    }[field]

    const url = `/manage/contacts/${contactId}/${fieldType}/new/`
    const { id } = yield call(makePostRequest, url, serializer(value))

    yield put({
      type: ACT.CONTACT_ADDITIONAL_FIELD_CREATE_SUCCESS,
      payload: {
        field: action.payload.field,
        value: {
          ...action.payload.value,
          id
        }
      }
    })
  } catch (e) {
    handleError(e, 'There was an issue creating additional fields for this contact.')
  }
}

function* updateAdditionalField(action) {
  try {
    const { contactId, field, value } = action.payload

    const fieldType = {
      additionalPhones: 'phone',
      additionalEmails: 'email'
    }[field]

    const serializer = {
      additionalPhones: toAdditionalPhone,
      additionalEmails: toAdditionalEmail
    }[field]

    const url = `/manage/contacts/${contactId}/${fieldType}/${value.id}/`
    yield call(makePostRequest, url, serializer(value))

    yield put({
      type: ACT.CONTACT_ADDITIONAL_FIELD_UPDATE_SUCCESS,
      payload: omit(action.payload, 'contactId')
    })
  } catch (e) {
    handleError(e, 'There was an issue updating additional fields for this contact.')
  }
}

function* deleteAdditionalField(action) {
  try {
    const { contactId, field, value } = action.payload

    const fieldType = {
      additionalPhones: 'phone',
      additionalEmails: 'email'
    }[field]

    const url = `/manage/contacts/${contactId}/${fieldType}/${value.id}/`
    yield call(makeDeleteRequest, url)

    yield put({
      type: ACT.CONTACT_ADDITIONAL_FIELD_DELETE_SUCCESS,
      payload: omit(action.payload, 'contactId')
    })
  } catch (e) {
    handleError(e, 'There was an issue removing additional fields from this contact.')
  }
}

function* fetchAddressBook(action) {
  yield put({ type: ACT.CONTACT_ADDRESS_BOOK_LOADING })

  try {
    const filterParams = action.payload || INITIAL_ADDRESS_BOOK_PARAMS
    const { pageSize, page, search, ordering } = filterParams

    const column = {
      firstName: 'fn',
      lastName: 'ln',
      title: 't',
      email: 'e',
      org: 'c',
      address: 'a'
    }[ordering.columnKey]

    const order = ordering.isDesc ? '-' : ''

    const url = `/manage/contacts/search/`

    const baseParams = { v: 'any', l: 'any', f: 'any', c: 'any', i: 'true' }
    const queryParams = {
      params: {
        ...baseParams,
        n: pageSize,
        p: page - 1,
        q: search,
        o: `${order}${column}`
      }
    }

    const {
      results,
      filteredEntries,
      totalEntries,
      maxAllowedCGAdmins,
      currentCGAdminsNo,
      userCanEditContactCG
    } = yield call(makeGetRequest, url, queryParams)

    yield put({
      type: ACT.CONTACT_ADDRESS_BOOK_FETCH_SUCCESS,
      payload: {
        rows: fromContacts(results),
        filteredTotal: filteredEntries,
        totalEntries,
        maxAllowedCGAdmins,
        currentCGAdminsNo,
        userCanEditContactCG
      }
    })
  } catch (e) {
    handleError(e, 'There was an issue fetching the contacts for the address book.')
  }
}

function* fetchUserSettings(action) {
  if (action.payload) {
    yield put({ type: ACT.CONTACT_USER_SETTINGS_LOADING })

    try {
      const url = `/company/user/${action.payload}/`

      const response = yield call(makeGetRequest, url)
      const userSettings = fromUserSettings(response)

      yield put({
        type: ACT.CONTACT_USER_SETTINGS_FETCH_SUCCESS,
        payload: userSettings
      })
    } catch (e) {
      handleError(e, 'There was an issue fetching the settings for this contact.')
    }
  }
}

function* updateUserSetting(action) {
  const { url, params } = yield call(toUserSetting, action.payload)

  try {
    yield call(makePostRequest, url, params)

    yield put({
      type: ACT.CONTACT_USER_SETTING_UPDATE_SUCCESS,
      loadingLock: 'off',
      payload: omit(action.payload, 'userId')
    })
  } catch (e) {
    handleError(e, 'There was an issue updating the settings for this contact.')
  }
}

const contactsSagas = [
  takeLatest(ACT.CONTACT_ROLES_FETCH_REQUESTED, contactRolesFetch),
  takeLatest(ACT.CONTACT_FETCH_REQUESTED, individualContact),
  takeLatest(ACT.ASSIGNED_CONTACTS_FETCH_REQUESTED, assignedContactsFetch),
  takeLatest(ACT.ASSIGNED_CONTACT_REMOVAL_REQUESTED, removeAssignedContact),
  takeLatest(ACT.ASSIGNED_CONTACTS_SUBMISSION_REQUESTED, submitContactAssignment),
  takeLatest(ACT.ASSIGNED_CONTACT_ROLE_REMOVAL_REQUESTED, removeAssignedContactRole),
  takeLatest(ACT.ASSIGNED_CONTACTS_SYNC, assignedContactsSync),
  takeLatest(ACT.CONTACT_CREATION_REQUESTED, createNewContact),
  takeLatest(ACT.CONTACT_UPDATE_REQUESTED, updateContact),
  takeLatest(ACT.CONTACT_DELETE_REQUESTED, deleteContact),
  takeLatest(ACT.CONTACT_PROFILE_FETCH_REQUESTED, contactProfileFetch),
  takeLatest(ACT.CONTACT_ACTIVITY_FETCH_REQUESTED, contactActivityFetch),
  takeLatest(ACT.CONTACT_AFFILIATION_FETCH_REQUESTED, contactAffiliationFetch),
  takeLatest(ACT.CONTACT_AFFILIATION_COUNTS_REQUESTED, contactAffiliationCounts),
  takeLatest(ACT.CONTACT_ATTRIBUTES_UPDATE_REQUESTED, updateContactAttributes),
  takeLatest(ACT.CONTACT_AVATAR_FETCH_REQUESTED, fetchAvatar),
  takeLatest(ACT.CONTACT_AVATAR_UPLOAD_REQUESTED, uploadAvatar),
  takeLatest(ACT.CONTACT_AVATAR_DELETE_REQUESTED, deleteAvatar),
  takeLatest(ACT.CONTACT_ADDITIONAL_FIELDS_FETCH_REQUESTED, fetchAdditionalFields),
  takeLatest(ACT.CONTACT_ADDITIONAL_FIELD_CREATE_REQUESTED, createAdditionalField),
  takeLatest(ACT.CONTACT_ADDITIONAL_FIELD_UPDATE_REQUESTED, updateAdditionalField),
  takeLatest(ACT.CONTACT_ADDITIONAL_FIELD_DELETE_REQUESTED, deleteAdditionalField),
  takeLatest(ACT.CONTACT_ADDRESS_BOOK_FETCH_REQUESTED, fetchAddressBook),
  takeLatest(ACT.CONTACT_USER_SETTINGS_FETCH_REQUESTED, fetchUserSettings),
  takeLatest(ACT.CONTACT_USER_SETTING_UPDATE_REQUESTED, updateUserSetting)
]

export default contactsSagas
