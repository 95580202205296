import { useMemo } from 'react'
import { UserSelect } from 'common/Selects'
import { IoMdLock } from 'react-icons/io'
import { components, MultiValueGenericProps, OptionProps, StylesConfig } from 'react-select'
import { Avatar, Checkbox, CharLimitInput } from 'simple-core-ui'
import { formatFullName } from 'utils/formatting'
import { StackText } from 'components'
import s from './FileShareForm.scss'
import cn from 'classnames'
import { CgUser, CustomQueryParams, ShareFormType } from '../types'
import { toCgUsers } from '../serializer'

interface Props {
  onChangeHandler: (key: string, value: boolean | string | CgUser[] | null) => void
  removeCgUser: (value: CgUser[]) => void
  formValues: ShareFormType
  customQueryParams: CustomQueryParams
  fileName: string
}

const formatOptions = (props: OptionProps<CgUser> | MultiValueGenericProps) => (
  <section className={s.container}>
    <StackText>
      <span className={s.avatarInitials}>
        <Avatar
          initials={formatFullName(props.data.firstName, props.data.lastName)}
          className={s.avatar}
          styles={{
            border: '1px solid #ddd',
            marginLeft: '-2px',
            fontSize: '14px'
          }}
        />
        <span className={s.newText}>
          <span className={s.nameText}>
            {props.data.label}
            {props.data.isDisabled && <IoMdLock />}
          </span>
          <span className={s.companyText}>{props.data.company}</span>
        </span>
      </span>
    </StackText>
  </section>
)

const Option = (props: OptionProps<CgUser>) => {
  return <components.Option {...props}>{formatOptions(props)}</components.Option>
}

const MultiValueLabel = (props: MultiValueGenericProps) => {
  return <components.MultiValueLabel {...props}>{formatOptions(props)}</components.MultiValueLabel>
}

const multiSelectViewerStyle: StylesConfig = {
  input: () => ({ display: 'none' }),
  indicatorsContainer: () => ({ display: 'none' }),
  valueContainer: base => ({
    ...base,
    height: 200,
    paddingBottom: 38,
    backgroundColor: 'white',
    overflowY: 'scroll',
    alignContent: 'baseline'
  }),
  multiValue: styles => ({
    ...styles,
    backgroundColor: 'white',
    border: '0.5px solid #040404'
  })
}

const multiSelectDropdownStyle: StylesConfig = {
  control: base => ({
    ...base,
    backgroundColor: 'white',
    minBlockSize: 60
  }),
  multiValue: styles => ({
    ...styles,
    backgroundColor: 'white',
    border: '0.5px solid #040404'
  })
}

const FileShareForm = ({
  onChangeHandler,
  formValues,
  removeCgUser,
  customQueryParams,
  fileName
}: Props) => {
  const {
    selectedCgUser,
    sharedWithUsers,
    isFileShared,
    isUserEdited,
    notifyViaEmail,
    displayMsg
  } = formValues

  const filterCgUser = [...selectedCgUser, ...sharedWithUsers]

  const serializeOptions = useMemo(() => toCgUsers(sharedWithUsers), [sharedWithUsers])

  return (
    <section>
      {isFileShared ? (
        <>
          <div
            className={cn(s.multiSelectViewerContainer, {
              [s.disabled]: selectedCgUser.length
            })}
          >
            <div style={{ display: 'flex' }}>
              <div className={cn(s.labelText, s.labelTextWithName)}>
                CounselGO users with access to ‘{fileName}’
              </div>
              <div className={s.buttonClearAll} style={{ display: 'flex' }}>
                <button
                  className={s.buttonClearAll}
                  disabled={Boolean(selectedCgUser.length)}
                  onClick={() => removeCgUser([])}
                >
                  Clear All
                </button>
              </div>
            </div>
            <div className={s.multiSelectTop}>
              {/* @ts-expect-error */}
              <UserSelect
                value={sharedWithUsers}
                onChange={(item: CgUser[]) => {
                  removeCgUser(item)
                }}
                isClearable={false}
                styles={multiSelectViewerStyle}
                placeholder=""
                paginated
                isMulti
                components={{ MultiValueLabel }}
                menuIsOpen={false}
                isDisabled={selectedCgUser.length}
                serializer={toCgUsers}
              />
              {displayMsg ? (
                <p className={s.multiSelectViewerDescriber}>{displayMsg}</p>
              ) : (
                <p className={s.multiSelectViewerDescriber}>
                  Shared with {sharedWithUsers && sharedWithUsers.length} CounselGO users
                </p>
              )}
            </div>
          </div>
          <hr className={s.lineBreak} />
        </>
      ) : displayMsg ? (
        <p style={{ marginTop: '-12px', paddingBottom: '12px', color: 'rgba(4, 4, 4, 0.7)' }}>
          {displayMsg}
        </p>
      ) : null}
      <div
        className={cn({
          [s.multiSelectDropdownContainer]: isFileShared,
          [s.disabled]: isFileShared && isUserEdited
        })}
      >
        <div className={s.labelText}>
          Please select CounselGO users from the list or start typing
        </div>
        <div className={cn(s.multiSelectNoChip)}>
          {/* @ts-expect-error */}
          <UserSelect
            value={selectedCgUser}
            onChange={(item: CgUser[]) => {
              onChangeHandler('selectedCgUser', item)
            }}
            isClearable={false}
            placeholder="Select or Type here"
            styles={multiSelectDropdownStyle}
            paginated
            isMulti
            isPortal={false}
            components={{ Option, MultiValueLabel }}
            filterOption={(cgUser: CgUser) =>
              !filterCgUser.find((filterCgUser: CgUser) => filterCgUser.value === cgUser.value)
            }
            customQueryParams={customQueryParams}
            isDisabled={isUserEdited}
            closeMenuOnSelect={false}
            serializer={serializeOptions}
          />
        </div>

        {selectedCgUser.length ? (
          <>
            <div className={s.selectedValueCount}>
              {selectedCgUser.length} CounselGO user selected
            </div>
            <section className={s.inlineFieldGroup}>
              <Checkbox
                triggerCheckbox={() => onChangeHandler('notifyViaEmail', !notifyViaEmail)}
                isChecked={notifyViaEmail}
                size="sm"
              />
              <div className={s.checkBoxFieldLabel}>Notify Via Email</div>
            </section>
            {notifyViaEmail && (
              <>
                <div className={s.labelText}>Add Note (Optional)</div>
                <CharLimitInput
                  type="textarea"
                  value={formValues.note}
                  onChangeCb={e => onChangeHandler('note', e.target.value)}
                  cssClass={s.textarea}
                  dynamicCharCalculation
                />
              </>
            )}
          </>
        ) : null}
      </div>
    </section>
  )
}

export default FileShareForm
