import { useEffect } from 'react'
import { ModalContainer, CharLimitInput } from 'simple-core-ui'
import ReactTooltip from 'react-tooltip'
import s from './TemplateModal.scss'
import { MatterGroup, Template } from '../types'
import { PracticeAreaSelect } from 'common/Selects'

interface Props {
  toggleTemplateModal: () => void
  showDuplicateError: boolean
  template: Template
  updateTemplate: (value: string | MatterGroup, property: string) => void
  saveTemplate: () => void
  saveAndAddTasks: () => void
  matterGroupLabels: string[]
}

const MAX_NAME_LENGTH = 50

const TemplateModal = ({
  toggleTemplateModal,
  showDuplicateError,
  template,
  updateTemplate,
  saveTemplate,
  saveAndAddTasks,
  matterGroupLabels
}: Props) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const errorObject = (template: Template) => {
    let hasError = false
    let errorMessage = 'Maximum character limit reached'

    if (template.name && template.name[0] === ' ') {
      hasError = true
      errorMessage = 'Name cannot begin with a space.'
    }
    if (showDuplicateError) {
      hasError = true
      errorMessage = 'Name must be unique.'
    }

    return { hasError, errorMessage }
  }

  return (
    <ModalContainer
      title={template.id ? 'Edit a Task Template' : 'Add a Task Template'}
      subtitle="Use templates to quickly set up standardized task list for repeated legal tasks."
      hasNewButtons
      cancelText="Cancel"
      size="md"
      cancelCb={toggleTemplateModal}
      confirmText={template.id ? 'Save' : 'Save and Add Tasks'}
      secondaryConfirmText="Save"
      secondaryConfirmCb={!template.id ? saveTemplate : null}
      confirmCb={template.id ? saveTemplate : saveAndAddTasks}
      isDisabled={showDuplicateError || !template.name.trim() || template.name[0] === ' '}
      isDisabledSecondary={showDuplicateError || !template.name.trim() || template.name[0] === ' '}
      content={
        <div className={s.modalBody}>
          <div className={s.statusItem}>
            <div className={s.info}>Template Name*</div>
            <CharLimitInput
              maxLength={MAX_NAME_LENGTH}
              value={template.name}
              onChangeCb={e => updateTemplate(e.target.value, 'name')}
              placeholder="Template name"
              dynamicCharCalculation
              customErrorMessage={errorObject(template).errorMessage}
              hasError={errorObject(template).hasError}
            />
          </div>
          <div className={s.statusItem}>
            <div className={s.info}>Description</div>
            <textarea
              className={s.description}
              value={template.description ?? ''}
              onChange={e => {
                updateTemplate(e.target.value, 'description')
              }}
              rows={5}
            />
          </div>
          <div className={s.statusItem}>
            <div className={s.info}>{matterGroupLabels[0]}</div>
            {/* @ts-expect-error */}
            <PracticeAreaSelect
              value={template.matterGroup}
              onChange={(value: MatterGroup) => {
                updateTemplate(value, 'matterGroup')
              }}
              isClearable
              placeholder={`Select a ${matterGroupLabels[0]}`}
              paginated
            />
          </div>
        </div>
      }
    />
  )
}

export default TemplateModal
