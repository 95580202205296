import { useState, useEffect, useRef } from 'react'
import DayPicker from 'react-day-picker'
import { Input, Alert, Grid } from 'simple-core-ui'
import SearchSelectBody from './SearchSelectBody'
import moment from 'moment'
import { dateStr } from 'utils/dateUtils'
import s from '../SearchFilter.scss'
import get from 'lodash/get'
import cn from 'classnames'

const TODAY = {
  min: moment()
    .startOf('day')
    .toDate(),
  max: moment()
    .endOf('day')
    .toDate()
}

const LAST_7_DAYS = {
  min: moment()
    .subtract({ week: 1 })
    .startOf('day')
    .toDate(),
  max: moment()
    .endOf('day')
    .toDate()
}

const LAST_30_DAYS = {
  min: moment()
    .subtract({ month: 1 })
    .startOf('day')
    .toDate(),
  max: moment()
    .endOf('day')
    .toDate()
}

const LAST_90_DAYS = {
  min: moment()
    .subtract({ month: 3 })
    .startOf('day')
    .toDate(),
  max: moment()
    .endOf('day')
    .toDate()
}

export const LAST_6_MONTHS = {
  min: moment()
    .subtract({ month: 6 })
    .startOf('day')
    .toDate(),
  max: moment()
    .endOf('day')
    .toDate()
}

export const LAST_YEAR = {
  min: moment()
    .subtract({ year: 1 })
    .startOf('day')
    .toDate(),
  max: moment()
    .endOf('day')
    .toDate()
}

const isGreaterYear = (min, max) => {
  const daysCompare = moment(max).isLeapYear() ? 366 : 365
  return moment(max).diff(min, 'days') > daysCompare
}

export const defaultOptions = [
  { label: 'Today', value: TODAY },
  { label: 'Last 7 Days', value: LAST_7_DAYS },
  { label: 'Last 30 Days', value: LAST_30_DAYS },
  { label: 'Last 90 Days', value: LAST_90_DAYS },
  { label: 'Last 6 Months', value: LAST_6_MONTHS },
  { label: 'Last Year', value: LAST_YEAR }
]

const COMMON_INVALID_FORMATS = [
  'DD-MM-YYYY',
  'YYYY-MM-DD',
  'M/D/YYYY',
  'M/D/YYYY',
  'M/DD/YYYY',
  'M-D-YYYY',
  'M-DD-YYYY'
]

const VALID_FORMATS = ['MM-DD-YYYY', 'MM/DD/YYYY']

const DateRange = ({
  min,
  max,
  inputMin,
  inputMax,
  setMin,
  setMax,
  setInputMin,
  setInputMax,
  setIsDisabled,
  errorMessage,
  setErrorMessage,
  inputMinRef
}) => {
  const { Row, Column } = Grid
  const handleInputMin = newInputMin => {
    setInputMin(newInputMin)
    if (newInputMin === '') {
      setMin('')
      clearError()
    } else {
      const newMin = moment(newInputMin, VALID_FORMATS, true)
        .startOf('day')
        .toDate()

      if (moment(newMin).isValid()) {
        if (moment(newMin).isAfter(max)) {
          setIsDisabled(true)
          setErrorMessage('Start date is after the end date')
        } else if (isGreaterYear(newMin, max)) {
          setIsDisabled(true)
          setErrorMessage('Range is larger than 1 year')
          setMin(newMin)
          setInputMin(newInputMin)
        } else {
          setMin(newMin)
          clearError()
        }
      } else {
        setMin('')
        setIsDisabled(true)
      }
    }
  }

  const handleInputMax = newInputMax => {
    setInputMax(newInputMax)
    if (newInputMax === '') {
      setMax('')
      clearError()
    } else {
      const newMax = moment(newInputMax, VALID_FORMATS, true)
        .endOf('day')
        .toDate()

      if (moment(newMax).isValid()) {
        if (moment(newMax).isBefore(min)) {
          setIsDisabled(true)
          setErrorMessage('End date is before the start date')
        } else if (isGreaterYear(min, newMax)) {
          setIsDisabled(true)
          setErrorMessage('Range is larger than 1 year')
          setMax(newMax)
          setInputMax(newInputMax)
        } else {
          setMax(newMax)
          clearError()
        }
      } else {
        setMax('')
        setIsDisabled(true)
      }
    }
  }

  const handleMinClick = value => {
    const newMin = moment(value)
      .startOf('day')
      .toDate()
    const newInputMin = dateStr(newMin)

    if (newInputMin === inputMin) {
      setMin('')
      setInputMin('')
      clearError()
    } else if (moment(newMin).isAfter(max) || isGreaterYear(newMin, max)) {
      return
    } else {
      setMin(newMin)
      setInputMin(newInputMin)
      clearError()
    }
  }

  const handleMaxClick = value => {
    const newMax = moment(value)
      .endOf('day')
      .toDate()
    const newInputMax = dateStr(newMax)
    if (newInputMax === inputMax) {
      setMax('')
      setInputMax('')
      clearError()
    } else if (moment(newMax).isBefore(min) || isGreaterYear(min, newMax)) {
      return
    } else {
      setMax(newMax)
      setInputMax(newInputMax)
      clearError()
    }
  }

  const handleResetClick = () => {
    setMax('')
    setMin('')
    clearError()
  }

  const clearError = () => {
    setIsDisabled(false)
    setErrorMessage('')
  }

  const modifiers = { start: min, end: max }

  const beforeYear = moment(max)
    .subtract({ year: 1 })
    .toDate()
  const afterYear = moment(min)
    .add({ year: 1 })
    .toDate()

  return (
    <div style={{ margin: '0 auto', width: 500 }}>
      {errorMessage && (
        <Row>
          <Column span={12}>
            <Alert message={errorMessage} status="error" />
          </Column>
        </Row>
      )}
      <Row>
        <Column span={12}>You can only select a date range that is max 1 year at a time.</Column>
      </Row>
      <Row>
        <Column span={6}>
          <Input
            ref={inputMinRef}
            forwardedRef={inputMinRef}
            text={inputMin}
            onChangeCb={e => handleInputMin(e.target.value)}
            placeholder="MM/DD/YYYY"
            type="text"
          />
        </Column>
        <Column span={6}>
          <Input
            text={inputMax}
            onChangeCb={e => handleInputMax(e.target.value)}
            style={{ width: 'auto' }}
            placeholder="MM/DD/YYYY"
            type="text"
          />
        </Column>
      </Row>
      <Row>
        <Column span={6}>
          <DayPicker
            selectedDays={[min]}
            disabledDays={{ after: max, before: beforeYear }}
            modifiers={modifiers}
            month={min || new Date()}
            numberOfMonths={1}
            onDayClick={handleMinClick}
          />
        </Column>
        <Column span={6}>
          <DayPicker
            selectedDays={[max]}
            disabledDays={{ before: min, after: afterYear }}
            modifiers={modifiers}
            month={max || new Date()}
            numberOfMonths={1}
            onDayClick={handleMaxClick}
          />
        </Column>
      </Row>
    </div>
  )
}

const SelectDefaultRange = ({ onSelect, selected, onSpecificRange, isSpecificRange }) => {
  return (
    <div className={s.defaultDateContainer}>
      <ul>
        {defaultOptions.map(v => {
          const { label, value } = v
          return (
            <li
              data-testid={label}
              key={label}
              onClick={() => onSelect({ label, value })}
              className={cn(s.defaultDateValue, {
                [s.selectedDefault]: selected && selected.label === label
              })}
            >
              {label}
            </li>
          )
        })}
        <li
          key={'specific'}
          onClick={() => onSpecificRange(true)}
          className={cn(s.defaultDateValue, {
            [s.selectedDefault]: isSpecificRange && !selected
          })}
        >
          Specific Date Range
        </li>
      </ul>
    </div>
  )
}

const DateRangeSelect = ({ onConfirm, initialDateRange }) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isSpecificRange, setIsSpecificRange] = useState(false)

  const inputMinRef = useRef()

  const initialMin = get(initialDateRange, 'min', '')
  const initialMax = get(initialDateRange, 'max', '')
  const initialInputMin = dateStr(initialMin)
  const initialInputMax = dateStr(initialMax)

  const [defaultRange, setDefaultRange] = useState(null)
  const [inputMin, setInputMin] = useState(initialInputMin)
  const [inputMax, setInputMax] = useState(initialInputMax)
  const [min, setMin] = useState(initialMin)
  const [max, setMax] = useState(initialMax)

  const setDateRange = ({ label, value }) => {
    const { min, max } = value
    setDefaultRange({ value, label })
    setInputMin(dateStr(min))
    setInputMax(dateStr(max))
    setMin(min)
    setMax(max)
  }

  useEffect(() => {
    const defaultOption = defaultOptions.find(
      o =>
        moment(o.value.min).isSame(moment(min).startOf('day')) &&
        moment(o.value.max).isSame(moment(max).endOf('day'))
    )

    if (defaultOption) {
      setDefaultRange(defaultOption)
    } else if (!!min || !!max) {
      setIsSpecificRange(true)
      setDefaultRange(null)
    } else {
      setIsSpecificRange(false)
      setDefaultRange(null)
    }
  }, [min, max])

  useEffect(() => {
    if (
      !moment(inputMax, VALID_FORMATS, true).isValid() &&
      moment(inputMax, COMMON_INVALID_FORMATS, true).isValid()
    ) {
      setErrorMessage('Invalid date format for end date. Revise to MM/DD/YYYY.')
    } else if (
      !moment(inputMin, VALID_FORMATS, true).isValid() &&
      moment(inputMin, COMMON_INVALID_FORMATS, true).isValid()
    ) {
      setErrorMessage('Invalid date format for start date. Revise to MM/DD/YYYY.')
    }
  }, [inputMax, inputMin])

  const clearDateRange = () => {
    setDefaultRange(null)
    setInputMin('')
    setInputMax('')
    setMin('')
    setMax('')
    setErrorMessage('')
    setIsDisabled(false)
    setIsSpecificRange(false)
  }

  const onSpecificRange = value => {
    clearDateRange()
    setIsSpecificRange(value)
    inputMinRef.current.focus()
  }

  return (
    <SearchSelectBody
      onClear={clearDateRange}
      isDisabled={isDisabled}
      style={{ width: 'initial' }}
      onSubmit={() => {
        onConfirm({ min, max })
      }}
    >
      <div style={{ padding: 2, display: 'flex' }}>
        <SelectDefaultRange
          selected={defaultRange}
          onSelect={setDateRange}
          onSpecificRange={onSpecificRange}
          isSpecificRange={isSpecificRange}
        />
        <DateRange
          min={min}
          max={max}
          inputMax={inputMax}
          inputMin={inputMin}
          setMax={setMax}
          setMin={setMin}
          setInputMin={setInputMin}
          setInputMax={setInputMax}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          setIsSpecificRange={setIsSpecificRange}
          setIsDisabled={setIsDisabled}
          inputMinRef={inputMinRef}
        />
      </div>
    </SearchSelectBody>
  )
}

export default DateRangeSelect
