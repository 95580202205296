import { ModalContainer, KeyValuePairs, Input } from 'simple-core-ui'
import s from './AddValueModal.scss'
import { PracticeAreaSelect, LegalEntitySelect } from 'common/Selects'
import Select from 'react-select'
import swal from 'sweetalert'
import cn from 'classnames'

const statuses = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'NOT_ACTIVE', label: 'Not Active' }
]

const AddValueModal = ({
  value,
  setValue,
  setIsAddValueModalVisible,
  addValue,
  attributeName,
  resetModal,
  matterGroupManagement,
  legalEntityManagement,
  errors
}) => {
  return (
    <ModalContainer
      title={value.id ? 'Edit Value' : 'Add Value'}
      cancelText="Cancel"
      size="sm"
      shouldModalClose={false}
      cancelCb={() => {
        setIsAddValueModalVisible(false)
        resetModal()
      }}
      confirmText="Submit"
      confirmCb={addValue}
      isDisabled={!value.short_name}
      content={
        <div className={s.modalBody}>
          <KeyValuePairs
            pairs={[
              {
                key: `${attributeName} Name`,
                sectionClass: [s.sectionClass],
                value: (
                  <>
                    <Input
                      type="text"
                      cssClass={cn({ 'add-value-modal-error': errors.short_name })}
                      value={value.short_name}
                      text={value.short_name}
                      placeholder="Enter Name"
                      onChangeCb={({ target }) =>
                        setValue(draft => {
                          draft.short_name = target.value
                        })
                      }
                    />
                    {errors.short_name && <div className={s.error}>{errors.short_name}</div>}
                  </>
                )
              },
              {
                key: `${attributeName} Description`,
                sectionClass: [s.sectionClass],
                value: (
                  <>
                    <Input
                      type="text"
                      value={value.description}
                      text={value.description}
                      placeholder="Enter Description"
                      onChangeCb={({ target }) =>
                        setValue(draft => {
                          draft.description = target.value
                        })
                      }
                    />
                    {errors.description && <div className={s.error}>{errors.description}</div>}
                  </>
                )
              },
              {
                key: `${attributeName} Code`,
                sectionClass: [s.sectionClass],
                value: (
                  <>
                    <Input
                      type="text"
                      value={value.code}
                      text={value.code}
                      placeholder="Enter Code"
                      onChangeCb={({ target }) =>
                        setValue(draft => {
                          draft.code = target.value
                        })
                      }
                    />
                    {errors.code && <div className={s.error}>{errors.code}</div>}
                  </>
                )
              },
              ...(value.id
                ? [
                    {
                      key: `Applicable ${matterGroupManagement[1]}`,
                      sectionClass: [s.sectionClass],
                      value: (
                        <PracticeAreaSelect
                          value={value.practice_areas?.map(p => ({
                            value: p.id || p.value,
                            label: p.short_name || p.label
                          }))}
                          onChange={values => {
                            setValue(draft => {
                              draft.practice_areas = values
                            })
                          }}
                          isPortal={false}
                          isClearable={false}
                          paginated
                          isMulti
                        />
                      )
                    },
                    {
                      key: `Applicable ${legalEntityManagement[1]}`,
                      sectionClass: [s.sectionClass],
                      value: (
                        <LegalEntitySelect
                          value={value.legal_entities?.map(p => ({
                            value: p.id || p.value,
                            label: p.entity_short_name || p.label
                          }))}
                          onChange={values => {
                            setValue(draft => {
                              draft.legal_entities = values
                            })
                          }}
                          isPortal={false}
                          isClearable={false}
                          paginated
                          isMulti
                        />
                      )
                    },
                    {
                      key: 'Status',
                      sectionClass: [s.sectionClass],
                      value: (
                        <Select
                          value={statuses.find(s => s.label === value.status)}
                          options={statuses}
                          onChange={async value => {
                            if (value.value === 'NOT_ACTIVE') {
                              const confirm = await swal({
                                title: 'Mark as Not Active?',
                                text:
                                  'This value will no longer be assignable in SimpleLegal. References to this attribute value will no longer satisfy invoice requirements. Do you wish to continue?',
                                buttons: ['Cancel', 'Yes'],
                                icon: 'warning'
                              })

                              if (confirm) {
                                setValue(draft => {
                                  draft.status = value
                                })
                              }
                            } else {
                              setValue(draft => {
                                draft.status = value
                              })
                            }
                          }}
                          isPortal={false}
                          isClearable={false}
                        />
                      )
                    }
                  ]
                : [])
            ]}
          />
        </div>
      }
    />
  )
}

export default AddValueModal
