import { VerticalTabs, VerticalTab } from 'simple-core-ui'
import { Reviewers } from './Reviewers'
import { Permissions } from './Permissions'
import { AllocationsContainer } from './Allocations'
import { Notifications } from './Notifications'
import { hasModule } from 'utils/helpers'
import { Notes } from './Notes'
import { SCOPE } from 'utils/constants'

const SettingsTab = ({ reviewerConfigId, readOnly, scopeId }) => {
  return (
    <VerticalTabs activeTabKey="allocations">
      <VerticalTab key="allocations" label="Allocations">
        <AllocationsContainer scopeId={scopeId} readOnly={readOnly} />
      </VerticalTab>
      <VerticalTab key="invoice" label="Reviewers">
        <Reviewers reviewerConfigId={reviewerConfigId} readOnly={readOnly} />
      </VerticalTab>
      <VerticalTab key="invoiceNotes" label="Invoice Notes">
        <Notes scopeId={scopeId} readOnly={readOnly} />
      </VerticalTab>
      {hasModule('has_perms') ? (
        <VerticalTab key="permissions" label="Permissions">
          <Permissions scopeId={scopeId} readOnly={readOnly} />
        </VerticalTab>
      ) : null}
      <VerticalTab key="notifications" label="Notifications">
        <Notifications
          scope={SCOPE.MATTER}
          scopeId={scopeId}
          readOnly={readOnly}
          type="courtRules"
        />
      </VerticalTab>
    </VerticalTabs>
  )
}

export default SettingsTab
