import { useState, useEffect } from 'react'
import s from '../../styles/HomeDashboard.scss'
import { DashboardSkeleton } from 'common'
import { MODULE } from 'utils/constants'
import { useLoading, DataTableWrapper, Panel } from 'simple-core-ui'
import { toTask } from './serializers'
import { APITask, Params, TabsConfig } from './types'
import { Tabs } from './Tabs'
import { hasModule } from 'utils/helpers'
import Expand from '../../components/Expand'
import cn from 'classnames'
import { makeGetRequest } from 'utils/api'
import { useDispatch } from 'react-redux'
import { eventsColumns, tasksColumns } from './tableConfig'
import { toEvents } from 'common/Events/serializers'

const initTableParams = {
  pageSize: 10,
  ordering: { columnKey: 'dueDate', isDesc: false },
  search: '',
  page: 1,
  category: 'all'
}

const initialParams = {
  tasks: initTableParams,
  events: initTableParams
}

const loggedInUserID = +window?.credentials?.user?.id
const COMPLETED_STATUS_ID = 3
const hasTasks = hasModule(MODULE.TASKS)
const hasEvents = hasModule(MODULE.EVENT_MANAGEMENT)

const generateTasksUrl = (tableParams: Params) => {
  return `/task-management/tasks/?columnKey=due_date&isDesc=${Number(
    tableParams.ordering.isDesc
  )}&page_number=${tableParams.page}&page_size=${
    tableParams.pageSize
  }&assignees=is:${loggedInUserID}`
}

const generateEventsUrl = (tableParams: Params) => {
  return `/event-management/events/?columnKey=start_date&isDesc=${Number(
    tableParams.ordering.isDesc
  )}&page_number=${tableParams.page}&page_size=${tableParams.pageSize}&category=upcoming`
}

const TasksAndEvents = () => {
  const [params, setParams] = useState(initialParams)
  const [selectedTab, setSelectedTab] = useState<'tasks' | 'events'>(hasTasks ? 'tasks' : 'events')
  const dispatch = useDispatch()
  const [isLoading, withLoadingLocks] = useLoading()
  const [eventsCount, setEventsCount] = useState(0)
  const [tasksCount, setTasksCount] = useState(0)

  const tabsConfig: TabsConfig = {
    tasks: {
      redirectUrl: { url: '/v2/tasks', label: 'View all of my tasks' },
      columns: tasksColumns,
      serializer: (items: APITask[]) =>
        items.filter(t => t.status?.phase?.description !== 'Complete').map(toTask),
      generateUrl: generateTasksUrl
    },
    events: {
      columns: eventsColumns,
      serializer: toEvents,
      generateUrl: generateEventsUrl
    }
  }

  const fetchTasksCounts = async () => {
    try {
      const { myTasksCount } = await withLoadingLocks(
        makeGetRequest(`/task-management/tasks/counts?status=is_not:${COMPLETED_STATUS_ID}`)
      )
      setTasksCount(myTasksCount)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const fetchEventsCounts = async () => {
    try {
      const { upcomingEventsCount } = await withLoadingLocks(
        makeGetRequest(`/event-management/events/counts`)
      )
      setEventsCount(upcomingEventsCount)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    hasTasks && fetchTasksCounts()
    hasEvents && fetchEventsCounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateTable = (params: Params) => {
    setParams(currentParams => ({
      ...currentParams,
      [selectedTab]: params
    }))
  }

  const changeTab = (tab: 'tasks' | 'events') => {
    setSelectedTab(tab)
  }

  const getTable = (tab: 'tasks' | 'events', expanded: boolean) => (
    <div className={cn({ [s.hidden]: selectedTab !== tab })}>
      <DataTableWrapper
        url={tabsConfig[tab].generateUrl(params[tab])}
        serializer={tabsConfig[tab].serializer}
        params={params[tab]}
        categories={[]}
        columns={tabsConfig[tab].columns}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        className={cn(s.itemsTable, { [s.expanded]: expanded })}
        categoryKey="task"
        tableHeight="320px"
        fixedHeader
        hasInfinityScroll
        remotePagination
      />
    </div>
  )

  const getContent = (expanded?: boolean) => (
    <>
      <Tabs
        selectedTab={selectedTab}
        setSelectedTab={changeTab}
        tasksCount={tasksCount}
        eventsCount={eventsCount}
      />
      <div className={s.viewAllLink}>
        {tabsConfig[selectedTab].redirectUrl && (
          <a href={tabsConfig[selectedTab].redirectUrl?.url}>
            {tabsConfig[selectedTab].redirectUrl?.label}
          </a>
        )}
      </div>
      {hasTasks && getTable('tasks', !!expanded)}
      {hasEvents && getTable('events', !!expanded)}
    </>
  )

  if (isLoading) {
    return <DashboardSkeleton />
  }
  return (
    <Panel
      className="dashboard-panel"
      title="Tasks and Events"
      styles={{ height: 450 }}
      bodyClassName={s.tasksPanel}
      rightActions={[
        <Expand key={0} title="Tasks and Events">
          {getContent(true)}
        </Expand>
      ]}
    >
      {getContent()}
    </Panel>
  )
}

export default TasksAndEvents
