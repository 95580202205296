import { MouseEvent, useState } from 'react'
import cn from 'classnames'
import { Button, OutsideClickContainer } from 'simple-core-ui'
import SelectCustomInput from '../SelectCustomInput/SelectCustomInput'
import { Reminder } from 'common/Events/Reminder'
import { Option } from 'common/Events/types'
import { getReminderValues } from 'common/Events/utils'
import s from './EventReminderSelect.scss'

interface Props {
  reminder: { type: string | null; value: number } | null
  onConfirm: (value: { type: string; value: number } | null) => void
}

const EventReminderSelect = ({ reminder, onConfirm }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [type, setType] = useState<string | null>(reminder ? reminder.type : null)
  const [value, setValue] = useState<number | null>(reminder ? reminder.value : null)

  const clearValue = (e: MouseEvent) => {
    e.stopPropagation()
    setType(null)
    setValue(null)
  }

  return (
    <OutsideClickContainer closeComponent={() => setIsExpanded(false)}>
      <section className={cn(s.container, { [s.expanded]: isExpanded })}>
        <SelectCustomInput
          value={reminder?.type && reminder.value ? `${reminder.value} ${reminder.type}` : null}
          onClick={() => setIsExpanded(expanded => !expanded)}
          onClear={e => {
            clearValue(e)
            onConfirm(null)
          }}
        />
        {isExpanded && (
          <div className={s.expandableContainer}>
            <div className={s.reminderContent}>
              <Reminder
                reminder={value && type ? { value, type } : null}
                onChangeTypeValue={(item: Option) => {
                  setValue(item.value)
                }}
                onChangeType={(item: Option<string>): void => {
                  setType(item.value !== 'none' ? item.value : null)
                  setValue(getReminderValues(String(item?.value))[0]?.value || null)
                }}
                updateEvent={(value: { type: string; value: number | null }) => {
                  setValue(value.value)
                  setType(value.type)
                }}
              />
            </div>
            <div className={s.footer}>
              <Button hasNewDesign onClick={clearValue} isPrimary isOutline>
                Clear
              </Button>
              <Button
                hasNewDesign
                isPrimary
                onClick={() => {
                  setIsExpanded(false)
                  onConfirm(value && type ? { value, type } : null)
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        )}
      </section>
    </OutsideClickContainer>
  )
}

export default EventReminderSelect
