import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import debounce from 'debounce-promise'
import { makeGetRequest } from 'utils/api'
import { ModalContainer, CharLimitInput, useLoading } from 'simple-core-ui'
import ReactTooltip from 'react-tooltip'
import s from './EventTypeModal.scss'
import { EventType } from '../types'
import { checkByNameIfInArray } from 'utils/helpers'

interface Props {
  toggleEventTypeModal: () => void
  eventType: EventType
  updateEventType: (value: string) => void
  saveEventType: () => void
}

const MAX_NAME_LENGTH = 50

const EventTypeModal = ({
  toggleEventTypeModal,
  eventType,
  updateEventType,
  saveEventType
}: Props) => {
  const [showDuplicateError, setShowDuplicateError] = useState(false)
  const [typeName, setTypeName] = useState(eventType.name || '')
  const [isLoading, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const errorObject = (eventType: EventType) => {
    let hasError = false
    let errorMessage = 'Maximum character limit reached'

    if (eventType.name && eventType.name[0] === ' ') {
      hasError = true
      errorMessage = 'Name cannot begin with a space.'
    }
    if (showDuplicateError) {
      hasError = true
      errorMessage = 'Name must be unique.'
    }

    return { hasError, errorMessage }
  }

  const validateName = async (value: string) => {
    if (value?.length) {
      try {
        const { rows } = await withLoadingLocks(
          makeGetRequest(`/event-management/event-types/?search=${encodeURIComponent(value)}`)
        )
        setShowDuplicateError(checkByNameIfInArray(value, rows, { id: eventType.id }))
      } catch (error) {
        dispatch({ type: 'API_ERROR', error })
      }
    }
    updateEventType(value)
  }

  const debouncedValidation = useCallback(debounce(validateName, 400), [])

  return (
    <ModalContainer
      title={eventType.id ? 'Edit Event Type' : 'Add Event Type'}
      subtitle="Use types to categorize your events."
      hasNewButtons
      cancelText="Cancel"
      size="sm"
      cancelCb={toggleEventTypeModal}
      confirmText="Save"
      confirmCb={saveEventType}
      isDisabled={
        showDuplicateError || !eventType.name.trim() || eventType.name[0] === ' ' || isLoading
      }
      content={
        <div className={s.statusItem}>
          <div className={s.info}>Event Type</div>
          <CharLimitInput
            maxLength={MAX_NAME_LENGTH}
            value={typeName}
            onChangeCb={e => {
              const { value } = e.target
              setTypeName(value)
              debouncedValidation(value)
            }}
            placeholder="Enter an event type"
            dynamicCharCalculation
            customErrorMessage={errorObject(eventType).errorMessage}
            hasError={errorObject(eventType).hasError}
          />
        </div>
      }
    />
  )
}

export default EventTypeModal
