import { useEffect, useState, useMemo } from 'react'
import { ModalContainer, Button, useLoading, DataTableWrapper } from 'simple-core-ui'
import s from './DeleteCourtRuleModal.scss'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { makeGetRequest, makeDeleteRequest } from 'utils/api'
import { useDispatch } from 'react-redux'
import { APICourtRule, Event, Cell } from '../types'
import { format } from 'date-fns'
import { timezoneDate, timezoneUtils } from 'utils/helpers'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import cn from 'classnames'

interface Props {
  toggleModal: () => void
  selectedCourtRuleId: number | null
  refreshTable: () => void
}

interface CourtRuleEvent extends Event {
  expanded?: boolean
  [key: string]: any
}

interface CourtRule extends Omit<APICourtRule, 'needs_review' | 'events_count'> {
  events: CourtRuleEvent[]
}

const DeleteCourtRuleModal = ({ toggleModal, selectedCourtRuleId, refreshTable }: Props) => {
  const [, withLoadingLocks, resetLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const [courtRule, setCourtRule] = useState({} as CourtRule)

  const fetchRule = async () => {
    try {
      const response = await withLoadingLocks(
        makeGetRequest(
          `/event-management/calendar_rules/court-rules/details/${selectedCourtRuleId}`
        )
      )
      setCourtRule(response)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchRule()

    return () => {
      resetLoadingLocks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleExpand = (id: number) => {
    const updatedEvents = courtRule.events.map(event => {
      if (event.id === id) {
        return {
          ...event,
          expanded: !event.expanded
        }
      }
      return event
    })
    setCourtRule({
      ...courtRule,
      events: updatedEvents
    })
  }

  const columns = useMemo(() => {
    return [
      {
        columnKey: 'start_date',
        content: <b>Date</b>,
        isSortable: true,
        isFilterable: true,
        style: { width: '200px' },
        render: (cell: Cell) => {
          return cell.content !== '----'
            ? format(timezoneDate(cell.content as string), 'MM/dd/yyyy')
            : ''
        }
      },
      {
        columnKey: 'time',
        content: <b>Time</b>,
        isSortable: false,
        isFilterable: false,
        render: (_cell: Cell, row: CourtRuleEvent) => {
          return (
            format(timezoneDate(row.start_date), 'hh:mm a') + ' ' + timezoneUtils.getUserTimezone()
          )
        }
      },
      {
        columnKey: 'description',
        content: <b>Events</b>,
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: CourtRuleEvent) => {
          return (
            <div
              className={cn(s.description, {
                [s.descriptionCollapsed]: !row.expanded
              })}
            >
              {cell.content as string}
            </div>
          )
        }
      },
      {
        columnKey: 'expanded',
        isSortable: false,
        isFilterable: false,
        content: '',
        render: (_cell: Cell, row: CourtRuleEvent) => {
          return row.expanded ? (
            <FaChevronUp className={s.arrow} onClick={() => toggleExpand(row.id)} />
          ) : (
            <FaChevronDown className={s.arrow} onClick={() => toggleExpand(row.id)} />
          )
        }
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courtRule])

  const deleteRule = async () => {
    try {
      await withLoadingLocks(
        makeDeleteRequest(
          `/event-management/calendar_rules/court-rules/details/${selectedCourtRuleId}`
        )
      )
      toggleModal()
      refreshTable()

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: 'Success',
          message: `${courtRule.events?.length} event(s) and ${courtRule.name} were successfuly deleted.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  return (
    <ModalContainer
      hideButtons
      size="xl"
      cancelCb={toggleModal}
      content={
        <div className={s.container}>
          <div className={s.content}>
            <h2 className={s.title}>Delete Court Rule</h2>
            <AiOutlineInfoCircle className={s.info} /> Court Rules sets are populated through{' '}
            <b>CalendarRules</b> a third party application
            <div className={s.item}>
              <p style={{ fontWeight: '600' }}>
                You have {courtRule.events?.length} event(s) associated with this court rule.
                Deleting this court rule will cancel all events associated with it
              </p>
            </div>
            <div style={{ marginTop: 10 }}>
              <DataTableWrapper
                params={{
                  pageSize: 25,
                  ordering: { columnKey: 'date', isDesc: false },
                  search: '',
                  page: 1,
                  category: 'all'
                }}
                categories={[]}
                rows={courtRule.events}
                columns={columns}
                panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
                hasTooltip
                categoryKey="events"
                hasActionsHeader={false}
                hasPagination={false}
              />
            </div>
          </div>
          <div className={s.footer}>
            <Button
              style={{
                padding: '8px 15px',
                whiteSpace: 'nowrap',
                position: 'relative',
                bottom: 2
              }}
              onClick={toggleModal}
              isPrimary
              isOutline
              hasNewDesign
            >
              Cancel
            </Button>
            <Button
              style={{
                padding: '8px 15px',
                whiteSpace: 'nowrap',
                position: 'relative',
                bottom: 2
              }}
              onClick={deleteRule}
              isPrimary
              hasNewDesign
            >
              Delete Court Rule
            </Button>
          </div>
        </div>
      }
    />
  )
}

export default DeleteCourtRuleModal
