import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import noop from 'lodash/noop'
import cloneDeep from 'lodash/cloneDeep'
import cn from 'classnames'
import update from 'immutability-helper'

import { useFirstRender, useLoading, useUnsavedChanges, withConfirmation } from 'simple-core-ui'
import { makePostRequest } from 'utils/api'
import { getActiveRuleModules } from 'rules/utils'
import { useSimpleReviewContext } from 'simple_review/hooks'
import { validateBetweenDates, validateRule } from '../validators'
import EditorFooter from './footer'
import EditorHeader from './header'
import { EditorContent } from './content'
import { Condition, Operand, Rule } from 'simple_review/@types/editor'
import { RuleSerializer } from './serializers/rule.serializer'
import APP_ACT from 'app/actions'
import s from './IIWEditor.scss'
import { RuleType } from 'simple_review/@types/common'

// @ts-expect-error
function getToastStyles(base) {
  const styles = update(base, {
    Containers: { tc: { top: { $set: '40px' } } },
    NotificationItem: { DefaultStyle: { height: { $set: '35px' } } },
    Dismiss: { DefaultStyle: { top: { $set: '8px' } } }
  })
  return styles
}

interface Props {
  rule: Rule
  setRule(value: Rule): void
}

const activeRuleModules = getActiveRuleModules()

const IIWEditor = ({ rule, setRule }: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id = 'new' } = useParams()

  const [errors, setErrors] = useState<Array<Condition | Operand>>([])
  const [initialRule, setInitialRule] = useState(cloneDeep(rule))
  const [isPristine, setIsPristine] = useState(true)
  const [currentRuleId, setCurrentRuleId] = useState<string | null>(id)

  const [, withLoadingLocks] = useLoading()
  const { state: contextState } = useSimpleReviewContext()

  const reloadRule = useCallback(() => {
    if (isPristine) {
      setInitialRule(cloneDeep(rule))
      if (currentRuleId === null) {
        // currentRuleId will only ever be null when the user hits "save" after trying to navigate away having unsaved changes. In that case we send them to the list page
        navigate('/v2/rules/simplereview/list/')
      } else {
        navigate(`/v2/rules/simplereview/${currentRuleId}/`, { replace: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRuleId, isPristine])

  const firstRender = useFirstRender({
    reRenderCb: reloadRule
  })

  const onSave = async (shouldReload = true) => {
    const flatten = (
      operand: Condition | Operand
    ): Array<Condition | Operand> | Condition | Operand => {
      if ('operands' in operand) return operand.operands.flatMap(x => flatten(x))
      return operand
    }

    const invalidDateRhs = rule.condition.operands
      .flatMap(x => flatten(x))
      .filter(operand => {
        if (
          'lhs' in operand &&
          operand.rhs &&
          ['between', 'not between'].includes(operand.op?.symbol || '')
        ) {
          const isValid = validateBetweenDates(operand.op, operand.rhs)
          return !isValid
        }
        return false
      })

    setErrors(invalidDateRhs)
    try {
      const { isValid, invalidFields, shouldDeactivate } = validateRule({
        action: 'save',
        availableActions: contextState.availableActions,
        rule
      })

      if (!isValid) {
        const invalidFieldsText = invalidFields.join(', ').replace(/(\, )(?!.*\, )/, ' and ')
        return withConfirmation(noop, {
          title: 'Complete all required fields',
          text: `The following fields must be filled before saving rule: ${invalidFieldsText}.`,
          className: 'iiw-swal-design',
          buttons: [false, 'Ok']
        })()
      }

      const isActive = rule.isActive && !shouldDeactivate
      const rulePayload = JSON.parse(
        RuleSerializer().toJSON(rule, currentRuleId as string, isActive)
      )

      const result = await withLoadingLocks(
        makePostRequest('/rules/invoice_validation/save_webspec/', rulePayload)
      )

      if (!result.id) {
        dispatch({
          type: 'API_ERROR',
          error: {
            errorTitle: 'Error',
            response: {
              status: 500,
              data: {
                error: 'Failed to create rule. An unexpected error occurred'
              }
            }
          }
        })
        return
      }

      const title = rule.name ? `"${rule.name}" rule successfully saved` : 'Rule successfully saved'

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title,
          level: 'success',
          getStyles: getToastStyles
        }
      })

      setIsPristine(true)
      if (shouldReload) {
        setCurrentRuleId(result.id)
      }
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: {
          ...(error as object),
          errorTitle: 'Error'
        }
      })
    }
    return true
  }

  const onNavigateAway = async (args: Array<string>) => {
    const [action] = args
    if (action === 'cancel') {
      return
    } else if (action === 'discard') {
      setRule(initialRule)
      setIsPristine(true)
      setTimeout(() => {
        navigate('/v2/rules/simplereview/list')
      }, 500)
      return
    } else {
      const success = await onSave(false)
      if (!success) {
        setCurrentRuleId(null)
      }
    }
  }

  useUnsavedChanges(
    !isPristine,
    onNavigateAway,
    {
      className: 'iiw-swal-design',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: 'cancel',
          visible: true
        },
        discard: {
          text: "Don't Save",
          value: 'discard',
          className: 'swal-button--cancel'
        },
        confirm: {
          text: 'Save',
          value: 'confirm'
        }
      }
    },
    true
  )

  const isRuleCloned = () => window.location.href.includes('cloned')

  const handleToggleStatus = async () => {
    const newStatus = !rule.isActive

    const { isValid, invalidFields } = validateRule({
      action: 'toggle',
      availableActions: contextState.availableActions,
      rule
    })

    if (newStatus && !isValid) {
      // This finds the last occurrence of ', ' and replaces it with ' and '
      const invalidFieldsText = invalidFields.join(', ').replace(/(\, )(?!.*\, )/, ' and ')

      return withConfirmation(noop, {
        title: 'Complete all required fields',
        text: `The following fields must be filled before activating rule: ${invalidFieldsText}.`,
        className: 'iiw-swal-design',
        buttons: [false, 'Ok']
      })()
    }

    const isRuleSaved = isPristine && !isRuleCloned()
    if (!isRuleSaved) {
      const resp = await new Promise(resolve => {
        withConfirmation(
          (...args) => {
            const [action] = args as Array<string>

            resolve(action)
          },
          {
            title: 'You have unsaved edits',
            text: `Do you wish to save your edits before setting the rule to ${
              newStatus ? 'active' : 'inactive'
            }?`,
            className: 'iiw-swal-design',
            // @ts-expect-error
            buttons: {
              cancel: 'Cancel',
              discard: {
                text: "Don't Save",
                value: 'secondaryConfirm',
                className: 'swal-button--cancel'
              },
              confirm: {
                text: 'Save',
                value: true
              }
            }
          }
        )()
      })

      if (resp === 'cancel') return
      if (resp === 'secondaryConfirm') {
        setRule(initialRule)
        setIsPristine(true)
        return
      }
    }

    const rulePayload = JSON.parse(
      RuleSerializer().toJSON(rule, currentRuleId as string, newStatus)
    )

    try {
      const data = await withLoadingLocks(
        makePostRequest('/rules/invoice_validation/save_webspec/', rulePayload)
      )

      setIsPristine(true)

      const ruleName = rule.name ? `"${rule.name}" rule` : 'Rule'

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `${ruleName} successfully ${newStatus ? 'activated' : 'deactivated'}`,
          level: 'success',
          getStyles: getToastStyles
        }
      })

      setCurrentRuleId(data.id)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: {
          ...(error as object),
          errorTitle: 'Error'
        }
      })
    }
  }

  const handleClone = async () => {
    try {
      const ruleName = `(Clone) ${initialRule.name}`.slice(0, 150)
      const newRule = RuleSerializer().toJSON(
        {
          ...initialRule,
          name: ruleName
        },
        undefined,
        false
      )
      initialRule.name = ruleName
      const createdRule = await withLoadingLocks(
        makePostRequest('/rules/invoice_validation/save_webspec/', JSON.parse(newRule))
      )

      firstRender.current = true
      setIsPristine(true)
      setCurrentRuleId(createdRule.id)

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `${initialRule.name} was cloned successfully`,
          level: 'success',
          getStyles: getToastStyles
        }
      })

      navigate(`/v2/rules/simplereview/${createdRule.id}?cloned`)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: {
          ...(error as object),
          errorTitle: 'Error'
        }
      })
    }
  }

  const onChangeRule = (newRule: Rule) => {
    setIsPristine(false)
    setRule(newRule)
  }

  const onCancel = async () => {
    try {
      if (isRuleCloned()) {
        await withLoadingLocks(
          makePostRequest('/rules/invoice_validation/delete/', {
            ruleIds: [currentRuleId]
          })
        )
      }
      navigate('/v2/rules/simplereview/list')
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: {
          ...(error as object),
          errorTitle: 'Error'
        }
      })
    }
  }

  const isAI = rule.type === RuleType.AI || rule.type === RuleType.AI_CONDITIONAL
  const isReadOnly = isAI && !activeRuleModules.hasEditableAI

  return (
    <div className={cn('box', s.container)}>
      <EditorHeader
        ruleId={currentRuleId as string}
        ruleName={rule.name}
        isActive={rule.isActive}
        isAI={isAI}
        onClone={handleClone}
        onToggleStatus={handleToggleStatus}
      />
      <EditorContent
        rule={rule}
        onChangeRule={onChangeRule}
        isReadOnly={isReadOnly}
        isAI={isAI}
        errors={errors}
      />
      <EditorFooter
        isPristine={isPristine}
        onSave={() => onSave()}
        onCancel={onCancel}
        hideSave={isReadOnly}
      />
    </div>
  )
}

export default IIWEditor
