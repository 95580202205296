import { call, put, takeLatest, select } from 'redux-saga/effects'
import partial from 'lodash/partial'

import { makeGetRequest, makePostRequest, makePutRequest, handleError } from 'utils/api'
import {
  hasExistingReviewerConfig,
  hasExistingWithoutReviewerConfig,
  hasNewReviewerConfig,
  patchWithReviewerConfigId,
  stripTimeline,
  stripNullActions
} from './utils'
import { ENABLED_ENGINES, ENGINE, ENGINE_LABEL, ENGINE_REVIEWER_SCOPE_MAP } from 'rules/constants'

import {
  saveReviewerConfig,
  createReviewerConfig,
  cloneReviewerConfig
} from 'reviews/reviewer_config/sagas'

import ACT from './actions'
import { serializeParams } from './serializers'

function* saveInvoiceAI(action) {
  const { payload } = action
  try {
    yield makePutRequest('/rules/request_adjustment_code_flags/', payload)
    yield put({ type: ACT.FETCH_AI_SUCCESS, payload })
  } catch (error) {
    yield put({ type: 'API_ERROR', error })
  }
}

function* fetchAiRules() {
  try {
    const response = yield makeGetRequest('/rules/request_adjustment_code_flags/')

    yield put({
      type: ACT.FETCH_AI_SUCCESS,
      payload: response
    })
  } catch (e) {
    console.error(e)
    handleError(e)

    yield put({
      type: ACT.RULES_LIST_IS_NOT_LOADING
    })
  }
}

function* fetchEngineRules() {
  const engine = yield select(state => state.rules.engine)

  if (engine === ENGINE.INVOICE_AI) {
    yield fetchAiRules()

    return
  }

  const filters = yield select(state => state.rules[engine].filters)

  try {
    const response = yield makeGetRequest(`/client/rules/`, {
      params: serializeParams({ ...filters, engine })
    })

    yield put({
      type: ACT.FETCH_ENGINE_RULES_SUCCESS,
      payload: response
    })
  } catch (e) {
    console.error(e)
    handleError(e)

    yield put({
      type: ACT.RULES_LIST_IS_NOT_LOADING
    })
  }
}

function* fetchEngineNamespace() {
  const engine = yield select(state => state.rules.engine)

  yield put({ type: ACT.RULES_LIST_IS_LOADING })

  try {
    const response = yield makeGetRequest(`/rules/${engine}/all_rules_namespace/`)

    yield put({
      type: ACT.FETCH_ENGINE_NAMESPACE_SUCCESS,
      payload: response
    })
  } catch (e) {
    console.error(e)
    handleError(e)

    yield put({
      type: ACT.RULES_LIST_IS_NOT_LOADING
    })
  }
}

function sanitizeRule(unsanitaryRule) {
  const { clearable, ...rule } = stripNullActions(stripTimeline(unsanitaryRule))

  return rule
}

function getRule(engine, ruleIndex, state) {
  return state.rules[engine].rulesList[ruleIndex]
}

function* saveRule(action) {
  const { ruleIndex, engine } = action.payload

  yield put({ type: ACT.RULE_IS_SAVING })

  try {
    let rule = yield select(partial(getRule, engine, ruleIndex))
    const url = `/rules/${engine}/save_webspec/`

    let reviewerConfigId = null

    if (hasExistingReviewerConfig(rule.actions)) {
      // Rules Action without sub_type='ReviewerCofig'
      // Does not update customer_reviewers
      yield saveReviewerConfig({
        payload: {
          reviewerScope: ENGINE_REVIEWER_SCOPE_MAP[engine]
        }
      })
    } else if (hasNewReviewerConfig(rule.actions)) {
      reviewerConfigId = yield createReviewerConfig({
        payload: {
          reviewerScope: ENGINE_REVIEWER_SCOPE_MAP[engine]
        }
      })

      rule = patchWithReviewerConfigId(rule, reviewerConfigId)
    } else if (hasExistingWithoutReviewerConfig(rule.actions)) {
      // There are some rules without sub_type='ReviewerConfig'
      // while result no update, hence Added this to fix it.
      yield saveReviewerConfig({
        payload: {
          reviewerScope: ENGINE_REVIEWER_SCOPE_MAP[engine]
        }
      })
    }

    const { timeline, timelineIndex } = rule

    rule = sanitizeRule(rule)

    const ruleResponse = yield call(makePostRequest, url, rule)

    yield put({
      type: ACT.RULE_SAVE_SUCCESS,
      payload: {
        ruleResponse: { ...ruleResponse, timeline, timelineIndex },
        ruleIndex,
        reviewerConfigId
      }
    })

    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Success!',
        message: 'Your rule was successfully saved',
        autoDismiss: 2,
        level: 'success'
      }
    })
  } catch (e) {
    yield put({ type: ACT.RULE_IS_NOT_SAVING })
    handleError(e, 'The rule failed to save.')
  }
}

function* submitRulesDSL(action) {
  const { text, engine } = action.payload
  let eng = engine
  if (engine === ENGINE.SIMPLE_REVIEW) {
    eng = ENGINE.INVOICE_VALIDATION
  }

  const url = `/rules/${eng}/set_rules_dsl/`
  const body = { rules_text: text.trim() }

  yield put({ type: ACT.DSL_IS_LOADING })

  try {
    yield call(makePostRequest, url, body)
    const { rulesText } = yield fetchDSLByEngine(engine)

    yield put({
      type: ACT.SUBMIT_RULE_DSL_SUCCESS,
      payload: {
        rulesText,
        engine
      }
    })

    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Saved',
        message: `The ${ENGINE_LABEL[engine]} rules have been saved successfully.`,
        autoDismiss: 2,
        level: 'success'
      }
    })
  } catch (e) {
    yield put({ type: ACT.DSL_IS_NOT_LOADING })
    yield put({
      type: 'API_ERROR',
      error: e
    })
  }
}

async function fetchDSLByEngine(engine) {
  if (engine === ENGINE.INVOICE_AI) {
    engine = ENGINE.INVOICE_REVIEW
  } else if (engine === ENGINE.SIMPLE_REVIEW) {
    engine = ENGINE.INVOICE_VALIDATION
  }

  const url = `/rules/${engine}/all_rules_dsl/`
  const rulesDSL = await makeGetRequest(url)
  const rulesText = rulesDSL.rules_text

  return { rulesText }
}

function* fetchAllDSLRules() {
  yield put({ type: ACT.DSL_IS_LOADING })

  try {
    const engines = ENABLED_ENGINES

    const engineDSLList = yield Promise.all(engines.map(fetchDSLByEngine))

    const rulesByEngine = engineDSLList.reduce((acc, engineRules, idx) => {
      acc[engines[idx]] = engineRules
      return acc
    }, {})

    yield put({ type: ACT.FETCH_ALL_DSL_RULES_SUCCESS, payload: rulesByEngine })
  } catch (e) {
    yield put({ type: ACT.DSL_IS_NOT_LOADING })
    handleError(e)
  }
}

function* deleteRule(action) {
  const { id, engine } = action.payload
  if (!id) {
    return yield put({ type: ACT.RULE_DELETE_SUCCESS })
  }

  const url = `/rules/${engine}/delete/`
  const body = { ruleIds: [id] }
  try {
    const response = yield call(makePostRequest, url, body)
    yield put({ type: ACT.RULE_DELETE_SUCCESS, payload: { response } })
  } catch (e) {
    handleError(e)
  }
}

function* cloneRule(action) {
  yield put({ type: ACT.RULE_IS_SAVING })

  const { reviewerConfigId, ...restPayload } = action.payload
  const newReviewerConfigId = yield cloneReviewerConfig({ payload: { reviewerConfigId } })

  yield put({
    type: ACT.CLONE_RULE_SUCCESS,
    payload: { ...restPayload, reviewerConfigId: newReviewerConfigId }
  })

  yield put({ type: ACT.RULE_IS_NOT_SAVING })
}

const rulesSagas = [
  takeLatest(ACT.FETCH_ENGINE_RULES_REQUESTED, fetchEngineRules),
  takeLatest(ACT.FETCH_ENGINE_NAMESPACE_REQUESTED, fetchEngineNamespace),
  takeLatest(ACT.RULE_DELETE_REQUESTED, deleteRule),
  takeLatest(ACT.SAVE_INVOICE_AI_REQUESTED, saveInvoiceAI),
  takeLatest(ACT.RULE_SAVE_REQUESTED, saveRule),
  takeLatest(ACT.SUBMIT_RULE_DSL_REQUESTED, submitRulesDSL),
  takeLatest(ACT.FETCH_ALL_DSL_RULES_REQUESTED, fetchAllDSLRules),
  takeLatest(ACT.CLONE_RULE_REQUESTED, cloneRule)
]

export default rulesSagas
