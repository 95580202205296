import s from '../AddCourtRulesModal.scss'
import { EventSettingsObject, Option } from '../types'
import { Widget } from 'common/Widget'
import { Users } from 'common/Selects'
import { BsPersonPlus } from 'react-icons/bs'
import { AvatarList, KeyValuePairs } from 'simple-core-ui'
import { sortAlphabeticallyByProperty, hex2rgba } from 'utils/helpers'
import { StylesConfig } from 'react-select'
import { Reminder } from 'common/Events/Reminder'
import { getReminderValues } from 'common/Events/utils'

interface Props {
  updateEventSettingsObject: (
    value:
      | string
      | Option
      | null
      | Option[]
      | undefined
      | {
          type: string
          value: number | null
        },
    property: keyof EventSettingsObject,
    nestedProperty?: string
  ) => void
  eventSettingsObject: EventSettingsObject
  scopeId: string
}

interface LocalOption<T = number> extends Option<T> {
  color?: string
}

const customStyle: StylesConfig<LocalOption<string | number>, false> = {
  control: provided => ({
    ...provided,
    border: '0px solid black',
    backgroundColor: 'white',
    outline: 'none',
    boxShadow: 'none',
    minHeight: '32px',
    height: '32px',
    position: 'relative',
    top: '4px'
  }),
  valueContainer: provided => ({
    ...provided,
    height: '32px'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    display: 'none'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '32px'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  singleValue: (provided, state) => {
    const color = state.data.color
    if (!color) return provided
    return {
      ...provided,
      borderRadius: '4px',
      color: '#000000',
      padding: '2px 12px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      textAlign: 'center',
      minWidth: '100px',
      maxWidth: 'fit-content',
      backgroundColor: hex2rgba(color, 0.15),
      border: `1px solid ${color}`,
      position: 'relative',
      bottom: '1px'
    }
  },
  option: provided => {
    return {
      ...provided,
      wordWrap: 'break-word'
    }
  }
}

const EventSettings = ({ eventSettingsObject, updateEventSettingsObject, scopeId }: Props) => {
  const getUsersLabel = (): string | JSX.Element => {
    switch (eventSettingsObject.attendees?.length) {
      case 0:
        return ''
      case 1:
        return eventSettingsObject.attendees[0].label
      default:
        return (
          <AvatarList
            limit={3}
            size="xs"
            wrapperStyles={{ width: 24 }}
            avatarStyles={{ background: '#0957ae', marginLeft: 0, fontSize: 9 }}
            entries={sortAlphabeticallyByProperty(eventSettingsObject.attendees, 'label')}
          />
        )
    }
  }

  const getUsersIcon = (): JSX.Element => {
    if (eventSettingsObject.attendees.length === 1) {
      return (
        <AvatarList
          size="md"
          wrapperStyles={{
            width: 36,
            position: 'relative',
            bottom: 2,
            right: 2
          }}
          className={s.avatar}
          entries={[eventSettingsObject.attendees[0]]}
          avatarStyles={{ border: '2px solid #0957ae', lineHeight: '36px' }}
        />
      )
    }

    return <BsPersonPlus />
  }

  return (
    <>
      <div className={s.item}>
        <Widget
          label="ATTENDEE(S)"
          value={getUsersLabel()}
          icon={getUsersIcon()}
          iconClassName={s.attendeesIcon}
        >
          <Users
            value={eventSettingsObject.attendees}
            style={{ width: '400px' }}
            requestParams={{ matterId: +scopeId, canEdit: true, active: true }}
            onConfirm={(values: Option[]) => {
              updateEventSettingsObject(values, 'attendees')
            }}
          />
        </Widget>
      </div>
      <KeyValuePairs
        style={{ margin: '20px 0 0 0' }}
        pairs={[
          {
            key: 'Reminder',
            style: { marginBottom: '20px', height: 40 },
            keyClass: s.key,
            sectionClass: s.section,
            value: (
              <div
                style={{
                  display: 'flex'
                }}
              >
                <Reminder
                  reminder={eventSettingsObject.reminder}
                  customStyle={customStyle}
                  isPortal
                  onChangeTypeValue={item => {
                    updateEventSettingsObject(
                      item?.value ? String(item?.value) : '',
                      'reminder',
                      'value'
                    )
                  }}
                  onChangeType={(item): void => {
                    const reminderValue =
                      item.value === 'none'
                        ? null
                        : {
                            type: item.value,
                            value: getReminderValues(String(item.value))[0].value
                          }

                    updateEventSettingsObject(reminderValue, 'reminder')
                  }}
                  updateEvent={(value: { type: string; value: number | null }) => {
                    updateEventSettingsObject(value, 'reminder')
                  }}
                />
              </div>
            )
          },
          {
            key: 'Calendar',
            style: { marginBottom: '20px', height: 40 },
            keyClass: s.key,
            value: (
              <div
                className={s.level}
                style={{
                  backgroundColor: hex2rgba(eventSettingsObject.calendar.color, 0.15),
                  border: `1px solid ${eventSettingsObject.calendar.color}`
                }}
              >
                Team Calendar
              </div>
            )
          }
        ]}
      />
    </>
  )
}

export default EventSettings
