import { ModalContainer, Alert, ColorPicker, CharLimitInput, Popover } from 'simple-core-ui'
import s from './LabelModal.scss'
import ReactTooltip from 'react-tooltip'
import { useEffect } from 'react'

const LabelModal = ({
  label,
  toggleLabelModal,
  addLabel,
  updateLabel,
  showDuplicateError,
  isEdit,
  editLabel
}) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <ModalContainer
      size="sm"
      cancelCb={toggleLabelModal}
      title={isEdit ? 'Edit Label' : 'Add Label'}
      confirmText="Save"
      cancelText="Cancel"
      confirmCb={isEdit ? editLabel : addLabel}
      isDisabled={showDuplicateError || !label.name.trim()}
      content={
        <div className={s.modalBody}>
          {showDuplicateError && (
            <Alert
              className={s.alert}
              message="The document label name already exists. Please specify a different name."
              status="error"
            />
          )}
          <div className={s.labelItem}>
            <div className={s.info}>New label name</div>
            <CharLimitInput
              value={label.name}
              onChangeCb={e => updateLabel(e.target.value, 'name')}
              placeholder="Label"
            />
          </div>
          <div className={s.labelItem}>
            <div className={s.info}>Select label color</div>
            <Popover
              trigger={
                <span className={s.selectedColor} style={{ backgroundColor: label.color }} />
              }
              content={
                <ColorPicker
                  initialColor={label.color}
                  onChange={value => updateLabel(value, 'color')}
                />
              }
              border
              placement="right"
              stayOpenOnClick
            />
          </div>
        </div>
      }
    />
  )
}

export default LabelModal
