import { useState, useMemo } from 'react'
import s from './EventSelection.scss'
import { DataTableWrapper } from 'simple-core-ui'
import { Event, Cell } from '../types'
import { timezoneDate, timezoneUtils } from 'utils/helpers'
import { format } from 'date-fns'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import cn from 'classnames'

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const initialParams = {
  pageSize: Infinity,
  ordering: { columnKey: 'date', isDesc: false },
  search: '',
  page: 1,
  category: 'all'
}

interface Props {
  events: Event[]
  setRules: (rules: Event[]) => void
  selectedRows: number[]
  allRowsSelected: boolean
  selectAllRows: () => void
  selectRow: (id: number) => void
}

const EventsSelection = ({
  events,
  setRules,
  selectedRows,
  allRowsSelected,
  selectAllRows,
  selectRow
}: Props) => {
  const [params, setParams] = useState(initialParams)

  const toggleExpand = (id: number) => {
    const updatedEvents = events.map(event => {
      if (event.id === id) {
        return {
          ...event,
          expanded: !event.expanded
        }
      }
      return event
    })
    setRules(updatedEvents)
  }

  const columns = useMemo(() => {
    return [
      {
        columnKey: 'date',
        content: <b>Date</b>,
        isSortable: true,
        isFilterable: true,
        style: { width: '200px' },
        render: (cell: Cell) => {
          return format(timezoneDate(cell.content), 'MM/dd/yyyy')
        }
      },
      {
        columnKey: 'time',
        content: <b>Time</b>,
        isSortable: false,
        isFilterable: false,
        render: (_cell: Cell, row: Event) => {
          return format(timezoneDate(row.date), 'hh:mm a') + ' ' + timezoneUtils.getUserTimezone()
        }
      },
      {
        columnKey: 'description',
        content: <b>Events</b>,
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: Event) => {
          return (
            <div
              className={cn(s.description, {
                [s.descriptionCollapsed]: !row.expanded
              })}
            >
              {cell.content}
            </div>
          )
        }
      },
      {
        columnKey: 'expanded',
        isSortable: false,
        isFilterable: false,
        content: '',
        render: (cell: Cell, row: Event) => {
          return row.expanded ? (
            <FaChevronUp className={s.arrow} onClick={() => toggleExpand(row.id)} />
          ) : (
            <FaChevronDown className={s.arrow} onClick={() => toggleExpand(row.id)} />
          )
        }
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events])

  const updateTable = (params: Params) => {
    setParams(params)
  }

  return (
    <div style={{ marginTop: 10 }}>
      <p style={{ fontWeight: 600, fontSize: 16, position: 'relative', top: 15, left: 10 }}>
        {selectedRows.length}/{events.length} Court Rule Events selected
      </p>
      <DataTableWrapper
        params={params}
        categories={[]}
        rows={events}
        columns={columns}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        hasTooltip
        categoryKey="events"
        hasActionsHeader={false}
        hasPagination={false}
        selectAllRows={selectAllRows}
        selectRow={row => selectRow(row.id)}
        selectedRows={new Set(selectedRows)}
        allRowsSelected={allRowsSelected}
        checkboxSize="md"
      />
    </div>
  )
}

export default EventsSelection
