import get from 'lodash/get'
import toPairs from 'lodash/toPairs'
import moment from 'moment'

import { capitalize } from 'utils/formatting'
import { isCounselGoUser, isCounselGoAdmin } from 'contacts/utils'
import { extractCgRoles, extractLabels } from 'contacts/AssignedContactsTableContainer/utils'

import { SCOPE, SPECIAL_ACCESS_ROLES } from 'utils/constants'

const isRevisedVendorContactsView = window.serverContext.get('data_for_react')
  ?.allow_vendor_collaboration_assigned_roles

export const fromOrg = ({
  addr_city, // eslint-disable-line camelcase
  addr_state, // eslint-disable-line camelcase
  ...org
}) => org

export const fromRole = ({
  id,
  display_name, // eslint-disable-line camelcase
  system_name, // eslint-disable-line camelcase
  singular,
  dependency
}) => ({
  id,
  value: id,
  label: system_name === '_vl' ? 'Vendor Manager' : capitalize(display_name), // SI-839: Renamed Vendor Lead to Vendor Manager,
  systemName: system_name,
  singular,
  dependency
})

export const fromRoles = roles =>
  roles.map(role => {
    const roleConstant = SPECIAL_ACCESS_ROLES.find(r => r.systemName === role.system_name)
    return fromRole({ ...role, dependency: get(roleConstant, 'dependency', '') })
  })

export const fromVendor = ({ name, id }) => ({
  label: name,
  value: id
})

export const toContact = ({ firstName, lastName, title, company, vendor, email, phone }) => ({
  given_name: firstName,
  family_name: lastName,
  vendor,
  company,
  title: title,
  primary_email: email,
  primary_phone: phone
})

export const fromContact = (
  {
    id,
    user_id, // eslint-disable-line camelcase
    prefix,
    given_name, // eslint-disable-line camelcase
    addl_name, // eslint-disable-line camelcase
    family_name, // eslint-disable-line camelcase
    suffix,
    title,
    primary_phone, // eslint-disable-line camelcase
    primary_email, // eslint-disable-line camelcase
    org,
    vendor,
    roles,
    role_timestamp, // eslint-disable-line camelcase
    created_date, // eslint-disable-line camelcase
    url,
    notes,
    active,
    full_name // eslint-disable-line camelcase
  },
  roleSet
) => ({
  id,
  userId: user_id,
  prefix,
  firstName: given_name,
  middleName: addl_name,
  lastName: family_name,
  fullName: full_name,
  suffix,
  title,
  org: fromOrg(org),
  vendor: org.type === 'vendor' ? fromVendor(org) : null,
  phone: primary_phone,
  email: primary_email,
  url,
  notes,
  roles: roleSet ? roleSet.filter(roleSetItem => roles.includes(roleSetItem.id)) : [],
  type: org.type,
  address: { city: org.addr_city, state: org.addr_state },
  roleTimestamp: role_timestamp,
  createdDate: created_date ? moment(created_date).format('L') : created_date, // eslint-disable-line camelcase
  active,
  value: id,
  label: `${given_name} ${family_name} - ${primary_email}`
})

export const fromContacts = (contactSet = [], roleSet) =>
  contactSet.map(contact => fromContact(contact, roleSet))

export const fromAffiliationRow = (apiRow, roleSet, categoryValue) => ({
  id: apiRow.id,
  cells: [
    {
      columnKey: 'name',
      content: apiRow.name
    },
    ...(categoryValue === SCOPE.VENDOR && isRevisedVendorContactsView
      ? [
          {
            columnKey: 'isCounselGoUser',
            content: roleSet.find(isCounselGoUser)
          },
          {
            columnKey: 'isCounselGoAdmin',
            content: roleSet.find(isCounselGoAdmin)
          },
          {
            columnKey: 'rolesString',
            content: extractCgRoles(roleSet)
          },
          {
            columnKey: 'labelsString',
            content: extractLabels(roleSet)
          }
        ]
      : [
          {
            columnKey: 'roles',
            content: roleSet
          }
        ]),
    {
      columnKey: 'permissions',
      content: [
        { hasPermission: apiRow.isViewer, label: 'Viewer' },
        { hasPermission: apiRow.isApprover, label: 'Approver' }
      ].reduce(
        (permissionLabels, permission) =>
          permission.hasPermission ? [...permissionLabels, permission.label] : permissionLabels,
        []
      )
    }
  ]
})

export const fromAffiliationRows = (apiRows, roleIdSet, categoryValue) =>
  apiRows.map(apiRow => {
    const roleSet = roleIdSet.filter(({ id }) => get(apiRow, 'roles', []).includes(id))
    return fromAffiliationRow(apiRow, roleSet, categoryValue)
  })

export const fromAffiliation = (
  apiAffiliation,
  roleSet,
  columns,
  page,
  previousPage,
  nextPage,
  categoryValue,
  categoryCounts
) => ({
  rows: fromAffiliationRows(apiAffiliation.rows, roleSet, categoryValue),
  columns,
  page,
  previousPage,
  nextPage,
  totalEntries: apiAffiliation.totalEntries,
  filteredTotal: apiAffiliation.filteredEntries,
  categoryValue,
  categoryCounts
})

export const toPartialContact = fields => {
  const keyMap = {
    prefix: 'prefix',
    firstName: 'given_name',
    middleName: 'addl_name',
    lastName: 'family_name',
    suffix: 'suffix',
    title: 'title',
    url: 'url',
    email: 'primary_email',
    phone: 'primary_phone',
    notes: 'notes',
    company: 'company'
  }

  return toPairs(fields).reduce(
    (updatedFields, [key, value]) => ({ ...updatedFields, ...{ [keyMap[key]]: value } }),
    {}
  )
}

export const fromAdditionalPhone = ({
  id,
  phone_type,
  phone // eslint-disable-line camelcase
}) => ({
  id,
  type: phone_type,
  text: phone
})

export const fromAdditionalPhones = apiPhones => apiPhones.map(fromAdditionalPhone)

export const toAdditionalPhone = ({ type, text }) => ({
  phone_type: type,
  phone: text
})

export const fromAdditionalEmail = ({
  id,
  email_type,
  email // eslint-disable-line camelcase
}) => ({
  id,
  type: email_type,
  text: email
})

export const fromAdditionalEmails = apiEmails => apiEmails.map(fromAdditionalEmail)

export const toAdditionalEmail = ({ type, text }) => ({
  email_type: type,
  email: text
})
