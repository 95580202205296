import { useEffect, useState } from 'react'
import { DataTableWrapper, If, Modal, SearchableTreeView, useLoading } from 'simple-core-ui'
import ActionsPopover from './ActionsPopover/ActionsPopover'
import { ExtensionImage } from 'common/FileUpload/ExtensionImage'
import s from './VendorFilesTab.scss'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { useParams } from 'react-router-dom'
import { resultsSerializer, getNormalizedCellContent } from './helpers'
import { toTreeViewData } from '../MatterAttachmentsTab/serializers'
import { useDispatch } from 'react-redux'
import APP_ACT from 'app/actions'
import { FileSystemNode, SerializedVendorFile } from './types'
import { parseErrorMessage } from 'utils/helpers'
import { AxiosError } from 'axios'
import { filesize } from 'filesize'

interface Params {
  pageSize: number
  ordering: {
    columnKey: string
    isDesc: boolean
  }
  search: string
  page: number
  category: string
}

interface Row {
  id: number
  fileURL: string
}

const INITIAL_PARAMS = {
  pageSize: 10,
  ordering: {
    columnKey: 'fileName',
    isDesc: false
  },
  search: '',
  page: 1,
  category: 'all'
}

const columns = [
  {
    columnKey: 'type',
    content: 'Type',
    isSortable: true,
    isFilterable: true,
    render: (cell: { content: string }) => (
      <div className={s.fileNameContainer}>
        <ExtensionImage file={{ path: cell.content }} />
      </div>
    )
  },
  { columnKey: 'fileName', content: 'File Name', isSortable: true, isFilterable: true },
  { columnKey: 'created', content: 'Last Updated', isSortable: true, isFilterable: true },
  { columnKey: 'cg_user', content: 'Updated By', isSortable: true, isFilterable: true },
  { columnKey: 'vendor_name', content: 'Vendor', isSortable: true, isFilterable: true },
  {
    columnKey: 'fileSize',
    content: 'Size',
    isSortable: true,
    render: (cell: { content: string }) => filesize(cell.content)
  }
]

const VendorFilesTab = () => {
  const [params, setParams] = useState(INITIAL_PARAMS)
  const [newParent, setNewParent] = useState<FileSystemNode | null>(null)
  const [copyDocumentRowId, setCopyDocumentRowId] = useState<number | null>(null)
  const [rows, setRows] = useState<SerializedVendorFile[]>([])
  const [data, setData] = useState(null)
  const [isLoading, withLoadingLocks] = useLoading()

  const { id: matterId } = useParams()
  const dispatch = useDispatch()

  const fetchFilesList = async () => {
    const url = `/doc_management/native_docs/matter/vendor_files/${matterId}/`
    const { data } = await makeGetRequest(url)

    setRows(resultsSerializer(data))
  }

  useEffect(() => {
    fetchFilesList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateTable = (updatedParams: Params) => {
    setParams(updatedParams)
  }

  const renderCustomAction = (row: Row) => (
    <ActionsPopover rowId={row.id} copyDocument={setCopyDocumentRowId} fileUrl={row.fileURL} />
  )

  useEffect(() => {
    if (!!copyDocumentRowId) {
      const url = `/doc_management/native_docs/directories/matters/${matterId}`
      ;(async () => {
        const result = await makeGetRequest(url, {
          params: {
            tree: 1
          }
        })
        setData(toTreeViewData(result.data))
      })()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyDocumentRowId])

  const copyItem = async () => {
    const copyUrl = `/doc_management/native_docs/matter/${matterId}/copy_vendor_file/${copyDocumentRowId}/`
    setCopyDocumentRowId(null)

    try {
      await withLoadingLocks(
        makePostRequest(copyUrl, {
          folder_id: newParent?.id,
          folder_path: newParent?.path
        })
      )

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: 'File successfully copied',
          level: 'success'
        }
      })
    } catch (error) {
      const err = error as AxiosError<{ errors: string[] }>
      const msg = parseErrorMessage(err.response?.data?.errors)

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: 'Error',
          message: msg,
          level: 'error'
        }
      })
    }
  }

  return (
    <div data-testid="matter-vendor-files">
      <DataTableWrapper
        params={params}
        customSearchOptions={{
          placeholder: 'Start typing to search',
          styles: { width: 270, fontStyle: 'italic' }
        }}
        isLoading={isLoading}
        tableHeight="500px"
        categories={[]}
        rows={rows}
        columns={columns}
        totalEntries={rows.length}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        hasActions
        hasTooltip
        customActionsMemoizationDeps={[rows]}
        alwaysShowActions
        customAction={renderCustomAction}
        getNormalizedCellContent={getNormalizedCellContent}
        statusText="No Files Available."
      />
      <If condition={!!copyDocumentRowId}>
        <Modal
          title="Copy Document"
          size="sm"
          confirmText="Copy"
          isDisabled={!newParent}
          confirmCb={copyItem}
          cancelCb={() => setCopyDocumentRowId(null)}
          isVisible
          content={
            <div
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <SearchableTreeView
                inputData={data}
                inputLabel="Search and select new folder"
                onNodeSelect={(node: FileSystemNode) => {
                  setNewParent(node)
                }}
                hasNewDesign
              />
            </div>
          }
          hasNewButtons
        />
      </If>
    </div>
  )
}

export default VendorFilesTab
