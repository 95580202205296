import { useState, useEffect, useMemo } from 'react'
import s from './RelatedMatters.scss'
import { Button, ModalContainer, useLoading, Table, Panel } from 'simple-core-ui'
import { AddMatterModal } from './AddMatterModal'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { useDispatch } from 'react-redux'
import { toTableRows } from './serializers'

const RelatedMatters = ({ scopeId, readOnly }) => {
  const [isAddMatterModalVisible, setIsAddMatterModalVisible] = useState(false)
  const [parentMatter, setParentMatter] = useState(null)
  const [relatedMatters, setRelatedMatters] = useState([])
  const [isParent, setIsParent] = useState(false)
  const [canEdit, setCanEdit] = useState(false)
  const [hasParent, setHasParent] = useState(false)
  const dispatch = useDispatch()
  const [isLoading, withLoadingLocks] = useLoading()
  const [modalType, setModalType] = useState('')
  const [selectedMatter, setSelectedMatter] = useState(null)

  const columns = useMemo(() => {
    return [
      {
        columnKey: 'name',
        content: 'Name',
        isSortable: false,
        isFilterable: false,
        render: (cell, row) => {
          const content = cell.content
          return +scopeId !== +row.id ? (
            <a href={`/v2/matters/${row.id}`}>{content}</a>
          ) : (
            'current matter'
          )
        }
      },
      {
        columnKey: 'client_matter_id',
        content: 'Client Matter ID',
        isSortable: false,
        isFilterable: false
      },
      {
        columnKey: 'practice_area',
        content: 'Practice Area',
        isSortable: false,
        isFilterable: false
      },
      { columnKey: 'open_date', content: 'Open Date', isSortable: false, isFilterable: false },
      { columnKey: 'closed_date', content: 'Closed Date', isSortable: false, isFilterable: false }
    ]
  }, [scopeId])

  const parentRows = useMemo(() => {
    if (!parentMatter) return []
    return toTableRows([parentMatter])
  }, [parentMatter])

  const relatedRows = useMemo(() => {
    if (!relatedMatters.length) return []
    return toTableRows(relatedMatters)
  }, [relatedMatters])

  const assignState = ({
    has_edit_capability,
    is_parent_matter,
    parent_matters,
    related_matters,
    has_parent
  }) => {
    setCanEdit(has_edit_capability)
    setIsParent(is_parent_matter)
    setRelatedMatters(related_matters)
    setParentMatter(parent_matters)
    setHasParent(has_parent)
  }

  useEffect(() => {
    ;(async () => {
      try {
        const response = await withLoadingLocks(
          makeGetRequest(`/manage/matters/related/${scopeId}/`)
        )
        assignState(response)
      } catch (error) {
        if (error.response.status === 403) {
          return
        }
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetModal = () => {
    setSelectedMatter(null)
    setModalType('')
    setIsAddMatterModalVisible(false)
  }

  const addMatter = async () => {
    try {
      let url = ''
      let payload = {}
      if (modalType === 'parent') {
        url = `/manage/matters/assign_parent/${scopeId}/`
        payload = { parent_matter: selectedMatter.value }
      } else {
        url = `/manage/matters/assign_related/${scopeId}/`
        payload = { related_matter: selectedMatter.value }
      }

      const response = await withLoadingLocks(makePostRequest(url, payload))
      assignState(response)
      resetModal()
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: { ...error, errorTitle: 'Error' }
      })

      resetModal()
    }
  }

  const removeParent = async id => {
    try {
      const response = await withLoadingLocks(
        makePostRequest(`/manage/matters/assign_parent/${id || scopeId}/`, { parent_matter: -1 })
      )
      if (!id) {
        assignState(response)
      } else {
        const index = relatedMatters.findIndex(m => +m.id === +id)
        const arr = [...relatedMatters]
        arr.splice(index, 1)
        setRelatedMatters(arr)
      }
      resetModal()
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: { ...error, errorTitle: 'Error' }
      })

      resetModal()
    }
  }
  return (
    <Panel isBodyOnly>
      <div className={s.wrapper}>
        {!readOnly && (
          <>
            {canEdit && !hasParent && !parentMatter && (
              <Button
                className={s.editBtn}
                isPrimary
                onClick={() => {
                  setIsAddMatterModalVisible(true)
                  setModalType('parent')
                }}
              >
                Add Parent Matter
              </Button>
            )}
            {canEdit && isParent && (
              <Button
                className={s.editBtn}
                isPrimary
                onClick={() => {
                  setIsAddMatterModalVisible(true)
                  setModalType('related')
                }}
              >
                Add Related Matters
              </Button>
            )}
          </>
        )}
        <h3 className={s.title}>Parent Matters</h3>
        {!parentMatter ? (
          <div className={s.category}>
            <h4 className={s.subtitle}>Parent Matter</h4>
            <p>There is currently no parent matter assigned to this matter.</p>
          </div>
        ) : (
          <Table
            rows={parentRows}
            columns={columns}
            deleteRow={readOnly ? null : () => removeParent()}
            hasActions={canEdit && !readOnly}
          />
        )}
        <div className={s.category}>
          <h4 className={s.subtitle}>Related Matters</h4>
          {!relatedMatters.length ? (
            <p>There are currently no related matters.</p>
          ) : (
            <Table
              rows={relatedRows}
              columns={columns}
              deleteRow={readOnly ? null : row => removeParent(row.id)}
              hasActions={canEdit && isParent && !readOnly}
            />
          )}
        </div>
      </div>
      {isAddMatterModalVisible && (
        <ModalContainer
          title={modalType === 'parent' ? 'Add a Parent Matter' : 'Add a Related Matter'}
          cancelText="Cancel"
          size="md"
          cancelCb={resetModal}
          confirmText="Submit"
          confirmCb={addMatter}
          shouldModalClose={false}
          isDisabled={!selectedMatter}
          content={
            <AddMatterModal
              modalType={modalType}
              selectedMatter={selectedMatter}
              setSelectedMatter={setSelectedMatter}
            />
          }
        />
      )}
    </Panel>
  )
}

export default RelatedMatters
