import { createReducer } from 'redux-create-reducer'
import ACT from './actions'
import get from 'lodash/get'
import { DataTableService } from 'simple-core-ui/services'
import update from 'immutability-helper'
import { vendorColumns, columns } from './ContactAffiliation/tableDefinitions'

import { SCOPE } from 'utils/constants'

import { filterAssignedContactsByIndex, updateAssignedContacts } from './utils'

import { fromAffiliation } from './serializer'

const isRevisedVendorContactsView = window.serverContext.get('data_for_react')
  ?.allow_vendor_collaboration_assigned_roles

export const initialState = {
  contact: { isLoading: false },
  additionalPhones: [],
  additionalEmails: [],
  additionalFieldsLoading: false,
  assignedContacts: [],
  roles: [],
  rolesByScope: { matter: [], vendor: [] },
  rolesSettings: {
    maxAllowedCGAdmins: 0,
    currentCGAdminsNo: 0,
    userCanEditContactCG: false
  },
  activity: {
    logs: [],
    isLoading: true,
    isError: false,
    hasMore: true
  },
  affiliation: {
    rows: [],
    columns: [
      {
        columnKey: 'name',
        content: 'Name',
        isSortable: true,
        isFilterable: true,
        isDesc: true
      },
      {
        columnKey: 'roles',
        content: 'Roles',
        isFilterable: true
      },
      {
        columnKey: 'permissions',
        content: 'Permissions'
      }
    ],
    previousPage: null,
    nextPage: null,
    totalEntries: 0,
    categoryValue: null,
    categoryCounts: {
      [SCOPE.MATTER]: 0,
      [SCOPE.VENDOR]: 0,
      [SCOPE.LEGAL_ENTITY]: 0,
      [SCOPE.PRACTICE_AREA]: 0
    },
    isLoading: true,
    isError: false
  },
  addressBook: {
    filteredTotal: 0,
    totalEntries: 0,
    rows: [],
    isLoading: false
  },
  avatar: {
    src: null,
    version: 0,
    isLoading: false
  },
  settings: {
    legalEntity: null,
    userRole: null,
    approvalAuthority: 0,
    approvalAuthorityString: '',
    invoiceEscalation: null,
    emailPreference: null,
    defaultTemplate: null,
    lastLogin: '',
    forcedLogout: '',
    connectedToGoogle: false,
    clientUsingSSO: false,
    googleConnectUrl: '',
    tabUserActive: false,
    loginPreference: null,
    canEditLoginPreference: false,
    tabUserId: 0,
    userId: 0,
    timezone: null
  },
  loading: {
    settings: false
  }
}

const contactsReducer = createReducer(initialState, {
  [ACT.CONTACT_ROLES_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      rolesByScope: action.payload
    }
  },
  [ACT.CONTACT_FETCH_LOADING](state, action) {
    return {
      ...state,
      contact: {
        ...state.contact,
        isLoading: true
      }
    }
  },
  [ACT.CONTACT_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      contact: {
        ...action.payload,
        isLoading: false
      }
    }
  },
  [ACT.ASSIGNED_CONTACTS_FETCH_SUCCESS](state, action) {
    const {
      assignedContacts,
      roles,
      currentCGAdminsNo,
      maxAllowedCGAdmins,
      userCanEditContactCG
    } = action.payload
    return {
      ...state,
      assignedContacts,
      roles,
      rolesSettings: {
        currentCGAdminsNo,
        maxAllowedCGAdmins,
        userCanEditContactCG
      }
    }
  },
  [ACT.ASSIGNED_CONTACTS_UPDATE_SUCCESS](state, action) {
    const { contact, roles, overwrite } = action.payload
    return {
      ...state,
      assignedContacts: updateAssignedContacts(state.assignedContacts, contact, roles, overwrite)
    }
  },
  [ACT.ASSIGNED_CONTACT_REMOVAL_SUCCESS](state, action) {
    const { contact, roles } = action.payload
    return {
      ...state,
      assignedContacts: roles
        ? updateAssignedContacts(state.assignedContacts, contact, roles, true)
        : state.assignedContacts.filter(assignedContact => assignedContact.id !== contact.id)
    }
  },
  [ACT.ASSIGNED_CONTACT_ROLE_REMOVAL_SUCCESS](state, action) {
    const { roleId, contact } = action.payload
    const assignedContacts = filterAssignedContactsByIndex(
      state.assignedContacts,
      roleId,
      contact.id
    )

    return {
      ...state,
      assignedContacts
    }
  },
  [ACT.CONTACT_CREATION_SUCCESS](state, action) {
    return state
  },
  [ACT.CONTACT_UPDATE_SUCCESS](state, action) {
    return {
      ...state,
      assignedContacts: state.assignedContacts.map(contact =>
        contact.id === action.payload.id ? { ...contact, ...action.payload } : contact
      )
    }
  },
  [ACT.CONTACT_DELETE_SUCCESS](state, action) {
    return {
      ...state,
      assignedContacts: state.assignedContacts.filter(({ id }) => id !== action.payload)
    }
  },
  [ACT.ROLE_TIMESTAMP_UPDATE](state, action) {
    const { contactId, roleTimestamp } = action.payload

    return {
      ...state,
      assignedContacts: state.assignedContacts.map(contact =>
        contact.id === contactId ? { ...contact, roleTimestamp } : contact
      )
    }
  },
  [ACT.CONTACT_ACTIVITY_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      activity: {
        logs: [...state.activity.logs, ...action.payload.logs],
        isLoading: false,
        isError: false,
        hasMore: action.payload.hasMore
      }
    }
  },
  [ACT.CONTACT_ACTIVITY_LOADING](state, action) {
    return {
      ...state,
      activity: {
        ...state.activity,
        isLoading: true
      }
    }
  },
  [ACT.CONTACT_ACTIVITY_ERROR](state, action) {
    return {
      ...state,
      activity: {
        ...state.activity,
        isLoading: false,
        isError: true
      }
    }
  },
  [ACT.CONTACT_AFFILIATION_FETCH_SUCCESS](state, action) {
    const { getSurroundingPages, reorderColumns } = DataTableService

    const { apiAffiliation, category, ordering, page, pageSize } = action.payload

    const { previousPage, nextPage } = getSurroundingPages(
      page,
      pageSize,
      apiAffiliation.filteredEntries
    )

    const affiliationParams = [
      apiAffiliation,
      get(state, ['rolesByScope', category], []),
      reorderColumns(
        category === SCOPE['VENDOR'] && isRevisedVendorContactsView ? vendorColumns : columns,
        ordering
      ),
      page,
      previousPage,
      nextPage,
      category,
      state.affiliation.categoryCounts
    ]

    return {
      ...state,
      affiliation: fromAffiliation(...affiliationParams)
    }
  },
  [ACT.CONTACT_AFFILIATION_LOADING](state, action) {
    return {
      ...state,
      affiliation: {
        ...state.affiliation,
        isLoading: true
      }
    }
  },
  [ACT.CONTACT_AFFILIATION_COUNTS_SUCCESS](state, action) {
    return {
      ...state,
      affiliation: {
        ...state.affiliation,
        categoryCounts: action.payload
      }
    }
  },
  [ACT.CONTACT_AFFILIATION_ERROR](state, action) {
    return {
      ...state,
      affiliation: {
        ...state.affiliation,
        isLoading: false,
        isError: true
      }
    }
  },
  [ACT.CONTACT_ATTRIBUTES_UPDATE_SUCCESS](state, action) {
    return {
      ...state,
      contact: {
        ...state.contact,
        ...action.payload
      }
    }
  },
  [ACT.CONTACT_AVATAR_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      avatar: {
        src: action.payload,
        version: state.avatar.version,
        isLoading: false
      }
    }
  },
  [ACT.CONTACT_AVATAR_UPLOAD_SUCCESS](state, action) {
    return {
      ...state,
      avatar: {
        src: action.payload,
        version: state.avatar.version + 1,
        isLoading: false
      }
    }
  },
  [ACT.CONTACT_AVATAR_DELETE_SUCCESS](state) {
    return {
      ...state,
      avatar: {
        src: null,
        version: state.avatar.version,
        isLoading: false
      }
    }
  },
  [ACT.CONTACT_AVATAR_LOADING](state) {
    return {
      ...state,
      avatar: {
        ...state.avatar,
        isLoading: true
      }
    }
  },
  [ACT.CONTACT_ADDITIONAL_FIELDS_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      additionalFieldsLoading: false,
      ...action.payload
    }
  },
  [ACT.CONTACT_ADDITIONAL_FIELDS_LOADING](state, action) {
    return {
      ...state,
      additionalFieldsLoading: true
    }
  },
  [ACT.CONTACT_ADDITIONAL_FIELD_UPDATE_SUCCESS](state, action) {
    const { field, value } = action.payload
    const updatedValues = state[field].map(activeValue =>
      activeValue.id === value.id ? value : activeValue
    )

    return {
      ...state,
      ...{ [field]: updatedValues }
    }
  },
  [ACT.CONTACT_ADDITIONAL_FIELD_CREATE_SUCCESS](state, action) {
    const { field, value } = action.payload
    const updatedValues = [...state[field], value]

    return {
      ...state,
      ...{ [field]: updatedValues }
    }
  },
  [ACT.CONTACT_ADDITIONAL_FIELD_DELETE_SUCCESS](state, action) {
    const { field, value } = action.payload
    const updatedValues = state[field].filter(activeValue => activeValue.id !== value.id)

    return {
      ...state,
      ...{ [field]: updatedValues }
    }
  },
  [ACT.CONTACT_ADDRESS_BOOK_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      addressBook: {
        ...action.payload,
        isLoading: false
      }
    }
  },
  [ACT.CONTACT_ADDRESS_BOOK_LOADING](state, action) {
    return {
      ...state,
      addressBook: {
        ...state.addressBook,
        isLoading: true
      }
    }
  },
  [ACT.CONTACT_USER_SETTINGS_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      settings: action.payload,
      loading: {
        ...state.loading,
        settings: false
      }
    }
  },
  [ACT.CONTACT_USER_SETTING_UPDATE_SUCCESS](state, action) {
    return {
      ...state,
      settings: {
        ...state.settings,
        ...action.payload
      },
      loading: {
        ...state.loading,
        settings: false
      }
    }
  },
  [ACT.CONTACT_USER_SETTINGS_LOADING](state, action) {
    return {
      ...state,
      loading: {
        ...state.loading,
        settings: true
      }
    }
  },
  [ACT.CURRENT_CG_ADMINS_NO_CHANGE](state, action) {
    const { currentCGAdminsNo } = action.payload
    return update(state, {
      rolesSettings: {
        currentCGAdminsNo: {
          $set: currentCGAdminsNo
        }
      }
    })
  }
})

export default contactsReducer
