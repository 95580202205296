import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { FlexyHalf, If, Button, Tooltip } from 'simple-core-ui'

import { IoIosClose } from 'react-icons/io'

import ScopeContext from 'context/ScopeContext'
import { Input, Field } from 'components'
import { ConfirmableContainer } from 'containers'
import VendorsSelect from '../VendorsSelect/VendorsSelect'
import RolesSelect from '../RolesSelect/RolesSelect'
import CounselGoWarning from '../CounselGoWarning/CounselGoWarning'
import { SingularRoleWarning } from '../SingularRoleWarning'
import UnassignText from '../UnassignText/UnassignText'
import DeletionText from '../DeletionText/DeletionText'
import ContactLockReason from '../ContactLockReason/ContactLockReason'

import { SCOPE } from 'utils/constants'
import { isClientContact, isVendorContact, inferContactType } from '../utils'

import s from './ContactQuickForm.scss'

const getDeleteButton = (cb, userCanEditContactCG, contactToUpdate) => {
  return userCanEditContactCG ? (
    <Button onClick={cb} isSecondary>
      Delete
    </Button>
  ) : (
    <Tooltip
      trigger={
        <Button isSecondary isDisabled>
          Delete
        </Button>
      }
      content="You don't have permission to delete contacts with CounselGo roles."
    />
  )
}

const ContactQuickForm = ({
  fields,
  cancelCb,
  submitCb,
  changeCb,
  changeRolesCb,
  deleteCb,
  promptDeleteCb,
  isValid,
  isUpdating,
  isDeleting,
  isCustomCompany,
  showLockReason,
  checkIfUnassignmentPrompt,
  contactToUpdate,
  hasCGAccess,
  singularRoleIntersect,
  filterCompanyOptions,
  warningMessageType,
  userCanEditContactCG
}) => {
  return (
    <ConfirmableContainer
      promptText={
        isDeleting ? (
          <DeletionText />
        ) : (
          <UnassignText counselGoOnly={!isEmpty(fields.roles)} removesCounselGo={hasCGAccess} />
        )
      }
      confirmCallback={isDeleting ? deleteCb : () => submitCb({ onlyUnassign: true })}
      cancelCallback={() => promptDeleteCb(false)}
      render={openPrompt => (
        <section className={s.container}>
          <IoIosClose className={s.close} onClick={cancelCb} />
          {showLockReason && <ContactLockReason type={get(contactToUpdate, 'type')} />}
          <FlexyHalf size="small">
            <Field label="First Name" isRequired>
              <Input
                type="text"
                value={fields.firstName}
                testid="testid_contact_first_name"
                onChange={value => changeCb('firstName', value)}
                disabled={isClientContact(contactToUpdate)}
              />
            </Field>
            <Field label="Last Name" isRequired>
              <Input
                type="text"
                value={fields.lastName}
                testid="testid_contact_last_name"
                onChange={value => changeCb('lastName', value)}
                disabled={isClientContact(contactToUpdate)}
              />
            </Field>
          </FlexyHalf>
          <Field label="Title">
            <Input
              type="text"
              value={fields.title}
              testid="testid_contact_title"
              onChange={value => changeCb('title', value)}
              disabled={isClientContact(contactToUpdate)}
            />
          </Field>
          <Field label="Company" isRequired>
            {!isClientContact(contactToUpdate) && (
              <ScopeContext.Consumer>
                {({ scope, scopeId }) => (
                  <VendorsSelect
                    id="testid_contact_company"
                    className={s.vendorsSelect}
                    value={fields.vendor}
                    onChange={value => changeCb('vendor', value)}
                    filterOptions={filterCompanyOptions}
                    disabled={isVendorContact(contactToUpdate)}
                    scopeId={scopeId}
                    isVendorScoped={scope === SCOPE.VENDOR}
                  />
                )}
              </ScopeContext.Consumer>
            )}
            {isCustomCompany && (
              <Field label="Company Name" isRequired>
                <Input
                  testid="testid_contact_company_name"
                  type="text"
                  value={fields.company}
                  onChange={value => changeCb('company', value)}
                  placeholder="Enter a company name..."
                  disabled={isClientContact(contactToUpdate)}
                />
              </Field>
            )}
          </Field>
          <Field label="Email" isRequired>
            <Input
              testid="testid_contact_email"
              type="text"
              value={fields.email}
              onChange={value => changeCb('email', value)}
              disabled={isClientContact(contactToUpdate)}
            />
          </Field>
          <Field label="Phone">
            <Input
              testid="testid_contact_phone"
              type="text"
              value={fields.phone}
              onChange={value => changeCb('phone', value)}
              disabled={isClientContact(contactToUpdate)}
            />
          </Field>
          <hr className={s.separator} />
          <If condition={warningMessageType && userCanEditContactCG}>
            <CounselGoWarning
              warningMessageType={warningMessageType}
              contactName={`${fields.firstName} ${fields.lastName}`}
            />
          </If>
          <If condition={singularRoleIntersect.length}>
            {singularRoleIntersect.map(({ role, contact }, key) => (
              <SingularRoleWarning key={key} role={role} contact={contact} />
            ))}
          </If>
          <Field label="Assigned Roles">
            <RolesSelect
              id="testid_contact_roles"
              selectedContact={inferContactType(contactToUpdate, {
                vendor: get(fields, 'vendor', null),
                roles: get(fields, 'roles', [])
              })}
              value={fields.roles}
              onChange={changeRolesCb}
              multi
            />
          </Field>
          <section className={s.buttonGroup}>
            <section>
              <Button onClick={cancelCb} className={s.button} isSecondary>
                Cancel
              </Button>
              <Button
                testid="testid_create_contact"
                onClick={() => (checkIfUnassignmentPrompt() ? openPrompt() : submitCb())}
                isDisabled={!isValid}
                isPrimary
              >
                {isUpdating ? 'Update' : 'Create'}
              </Button>
            </section>
            {isUpdating &&
              get(contactToUpdate, 'type') !== 'client' &&
              getDeleteButton(
                () => {
                  promptDeleteCb(true)
                  openPrompt()
                },
                userCanEditContactCG,
                contactToUpdate
              )}
          </section>
        </section>
      )}
    />
  )
}

export default ContactQuickForm
