import moment from 'moment'
import { format, getYear, isBefore } from 'date-fns'
import { FaLocationDot } from 'react-icons/fa6'
import { Ellipsis, Tooltip } from 'simple-core-ui'
import { hex2rgba } from 'utils/helpers'
import { Task, Cell as TaskCell } from './types'
import { Event, Cell as EventCell } from 'common/Events/types'
import s from '../../styles/HomeDashboard.scss'
import ss from './TasksAndEvents.scss'

export const tasksColumns = [
  {
    columnKey: 'name',
    content: 'Task Name',
    isSortable: false,
    isFilterable: true,
    render: (cell: TaskCell, row: Task) => {
      return (
        <a
          style={{ cursor: 'pointer' }}
          href={`/v2/matters/${row?.relatedTo?.id}?id=${row?.id}&tab=tasks&category=mine`}
        >
          <Ellipsis width={190}>{typeof cell.content === 'string' && cell.content}</Ellipsis>
        </a>
      )
    }
  },
  {
    columnKey: 'relatedTo',
    content: 'Related to',
    isSortable: false,
    isFilterable: true,
    render: (cell: TaskCell, row: Task) => {
      return (
        <a style={{ cursor: 'pointer' }} href={`/v2/matters/${row?.relatedTo?.id}`}>
          <Ellipsis width={160} lines={1}>
            {typeof cell.content === 'object' && cell.content.name}
          </Ellipsis>
        </a>
      )
    }
  },
  {
    columnKey: 'status',
    content: 'Status',
    isSortable: false,
    isFilterable: true,
    render: (cell: TaskCell, row: Task) => {
      if (cell.content === '----') {
        return '--'
      }
      const color = row.status?.color
      return (
        <div
          className={s.level}
          style={{
            backgroundColor: color && hex2rgba(color, 0.15),
            border: `1px solid ${color}`
          }}
        >
          {typeof cell.content === 'object' && cell.content.name}
        </div>
      )
    }
  },
  {
    columnKey: 'dueDate',
    content: 'Due',
    isSortable: true,
    isFilterable: true,
    render: (cell: TaskCell) => {
      const content = cell.content as string
      return (
        <span style={moment(content).isBefore(new Date()) ? { color: '#bb342f' } : {}}>
          {content === '----'
            ? '--'
            : moment(content).format(
                moment(content).isSame(new Date(), 'year') ? 'MMM DD' : 'MMM DD YYYY'
              )}
        </span>
      )
    }
  }
]

export const eventsColumns = [
  {
    columnKey: 'name',
    content: 'Event Name',
    isSortable: false,
    isFilterable: true,
    render: (cell: EventCell, row: Event) => {
      return (
        <div style={{ float: 'left' }}>
          <a
            href={`/v2/matters/${row?.relatedMatter?.value}?id=${row?.id}&tab=events&category=all`}
          >
            <Ellipsis width={220} lines={1}>
              {typeof cell.content === 'string' && cell.content}
            </Ellipsis>
          </a>
        </div>
      )
    }
  },
  {
    columnKey: 'relatedMatter',
    content: 'Related To',
    isSortable: false,
    isFilterable: true,
    filterableBy: 'label',
    render: (cell: EventCell) => {
      if (typeof cell.content === 'object' && 'label' in cell.content) {
        return (
          <a href={`/v2/matters/${cell.content.value}?tab=overview`}>
            <Ellipsis width={120} lines={1}>
              {cell.content.label}
            </Ellipsis>
          </a>
        )
      }
      return '--'
    }
  },
  {
    columnKey: 'startDate',
    content: 'Date',
    isSortable: true,
    isFilterable: true,
    render: (cell: EventCell) => {
      if (cell.content === '----') {
        return '--'
      }
      return (
        <span style={isBefore(cell.content as Date, new Date()) ? { color: '#bb342f' } : {}}>
          {!(cell.content instanceof Date)
            ? '--'
            : format(
                cell.content,
                getYear(cell.content) === getYear(new Date()) ? 'MMM dd' : 'MMM dd yyyy'
              )}
        </span>
      )
    }
  },
  {
    columnKey: 'endDate',
    content: 'Time',
    isSortable: false,
    isFilterable: true,
    render: (cell: EventCell, row: Event) => {
      return (
        <span>
          {cell.content === '----' ||
          !(row.startDate instanceof Date) ||
          !(row.endDate instanceof Date)
            ? '--'
            : `${format(row.startDate, 'h:mm a')} - ${format(row.endDate, 'h:mm a')}`}
        </span>
      )
    }
  },
  {
    columnKey: 'location',
    content: <FaLocationDot className={ss.tableHeaderIcon} />,
    isSortable: false,
    isFilterable: false,
    render: (cell: EventCell, row: Event) => {
      const content = cell.content

      let location: JSX.Element | string = ''

      if (typeof content === 'object' && 'address' in content) {
        location = content.address as string

        if (
          'name' in content &&
          content.name.trim() !== content.address.trim() &&
          !content.address.includes(content.name.trim())
        ) {
          location = (
            <>
              <b>{content.name}</b> {content.address}
            </>
          )
        }
      }

      const locationIcon = (
        <FaLocationDot data-for={`location-${row.id}`} className={ss.tableIcons} />
      )

      return (
        <div className={ss.locationCell}>
          {content && content !== '----' ? (
            <>
              <Tooltip trigger={locationIcon} content={location} placement="top" />
              <span className={ss.linkColor}>1</span>
            </>
          ) : (
            <>
              {locationIcon}
              <span>0</span>
            </>
          )}
        </div>
      )
    }
  }
]
