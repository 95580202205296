import { useMemo, useState } from 'react'
import { Alert, CharLimitInput, ModalContainer } from 'simple-core-ui'

interface Props {
  onConfirm: (fileName: string) => void
  onCancel: () => void
  fileId: string | null
  fileName: string
  type: string
  allFileNames: {
    fileName: string
    fileType: string
    id: string
  }[]
}

const RenameFile = ({ onConfirm, onCancel, fileId, fileName, type, allFileNames }: Props) => {
  const [name, setName] = useState(fileName)

  const duplicatedFileName = useMemo(
    () =>
      allFileNames.some(
        ({ fileName, fileType, id }) => fileName === name && id !== fileId && fileType === type
      ),
    [name]
  )

  return (
    <ModalContainer
      title="Rename File"
      hasNewButtons
      cancelText="Cancel"
      size="sm"
      cancelCb={onCancel}
      confirmText="Rename"
      confirmCb={() => onConfirm(`${name}${type}`)}
      isDisabled={duplicatedFileName}
      content={
        <div>
          {duplicatedFileName && (
            <Alert
              message="This file name already exists. Please specify another name."
              status="error"
            />
          )}
          <label>File Name</label>
          <CharLimitInput
            value={name}
            onChangeCb={e => setName(e.target.value)}
            placeholder="File Name"
            hasError={duplicatedFileName}
            dynamicCharCalculation
          />
        </div>
      }
      contentStyle={{ padding: '10px 24px 30px', minHeight: 0 }}
    />
  )
}

export default RenameFile
