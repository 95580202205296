import { Routes, Route } from 'react-router-dom'
import cn from 'classnames'

import { PrivateRoute } from 'components'
import { CustomGuard } from 'components/Utils/PrivateRoute/guards'
import RulesDSLContainer from 'rules/dsl'
import { getHasSimpleReview } from 'rules/utils'
import { SimpleReview, SimpleReviewEditor, SimpleReviewList } from 'simple_review'
import RulesListContainer from '../containers/RulesListContainer'
import s from '../styles/Rules.scss'

const SIMPLE_REVIEW_PATH = 'simplereview'

const Rules = () => {
  const isSimpleReview = window.location.href.includes(SIMPLE_REVIEW_PATH)
  return (
    <section className={cn(s.container, { [s.widthSized]: !isSimpleReview })}>
      <Routes>
        <Route path="list" element={<RulesListContainer />} />
        <Route path="dsl" element={<RulesDSLContainer />} />
        <Route
          path={SIMPLE_REVIEW_PATH}
          element={
            <PrivateRoute component={SimpleReview} guards={[CustomGuard(getHasSimpleReview)]} />
          }
        >
          <Route path="list" element={<SimpleReviewList />} />
          <Route path="new" element={<SimpleReviewEditor />} />
          <Route path=":id" element={<SimpleReviewEditor />} />
        </Route>
      </Routes>
    </section>
  )
}

export default Rules
