import { connect } from 'react-redux'
import AsyncCreatableSelect from 'react-select/async-creatable'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'
import partial from 'lodash/partial'
import debounce from 'debounce-promise'
import get from 'lodash/get'

import ACT from '../actions'

import { If } from 'simple-core-ui'
import Contact from '../Contact/Contact'

import { fromContact } from '../serializer'
import { SPECIAL_ACCESS_ROLES } from 'utils/constants'
import { makeGetRequest } from 'utils/api'
import { filterContactsByRoles, scopedRequestConfig, CONTACTS_SEARCH_URL } from '../utils'

import s from './ContactsSelect.scss'

const isRevisedVendorContactsView = window.serverContext.get(
  'allow_vendor_collaboration_assigned_roles'
)

const Option = props => {
  return (
    <components.Option {...props}>
      {props.data.__isNew__ || props.data.created ? (
        props.data.label
      ) : (
        <Contact
          firstName={props.data.firstName}
          lastName={props.data.lastName}
          title={props.data.title}
          company={get(props.data, 'org.name')}
          phone={props.data.phone}
          email={props.data.email}
          hasNewForm={isRevisedVendorContactsView}
          readOnly
        />
      )}
    </components.Option>
  )
}

const SingleValue = props => {
  return (
    <components.SingleValue {...props}>
      {props.data.__isNew__ || props.data.created ? (
        props.data.label
      ) : (
        <Contact
          firstName={props.data.firstName}
          lastName={props.data.lastName}
          title={props.data.title}
          company={get(props.data, 'org.name')}
          phone={props.data.phone}
          email={props.data.email}
          isNewForm={isRevisedVendorContactsView}
          readOnly
        />
      )}
    </components.SingleValue>
  )
}

const MultiValueLabel = props => {
  return (
    <components.MultiValueLabel {...props}>
      <section>
        <If
          condition={!props.data.created}
          fallback={<span className={s.multiPrimaryLabel}>{props.data.email}</span>}
        >
          <span className={s.multiPrimaryLabel}>
            {props.data.firstName} {props.data.lastName}
          </span>
          <span className={s.multiSecondaryLabel}>{props.data.email}</span>
        </If>
      </section>
    </components.MultiValueLabel>
  )
}

const loadOptions = async (roleSet, scope, scopeId, requestConfig, search) => {
  const { url, params } = requestConfig
  let response
  try {
    response = await makeGetRequest(url, { params: { i: 'true', ...params, q: search } })
  } catch (e) {
    console.error(e)
    return []
  }

  return response.results.map(result => {
    const contact = fromContact(result, roleSet)
    return {
      ...contact,
      label: `${contact.firstName} ${contact.lastName} - ${contact.email}`,
      value: contact.id
    }
  })
}

const debouncedLoadOptions = debounce(loadOptions, 500)

const ContactsSelect = ({
  roleSet,
  selectedRoles,
  value,
  placeholder,
  onChange,
  multi,
  scope,
  scopeId,
  requestConfig,
  createable,
  filterOptions,
  cache,
  maxHeight,
  isPortal
}) => {
  const normalizedRequestConfig = requestConfig
    ? { url: CONTACTS_SEARCH_URL, ...requestConfig }
    : scopedRequestConfig(ACT.CONTACTS_FETCH_REQUESTED, scope, scopeId)

  const partialDebouncedLoadOptions = partial(
    debouncedLoadOptions,
    roleSet,
    scope,
    scopeId,
    normalizedRequestConfig
  )

  const CustomSelect = createable ? AsyncCreatableSelect : AsyncSelect

  const nonCreateableProps = {
    filterOption: option => filterContactsByRoles(option, selectedRoles, SPECIAL_ACCESS_ROLES),
    noOptionsMessage: () => 'No contacts found...'
  }

  const createableProps = {
    onCreateOption: newOption => {
      const contact = {
        value: newOption,
        label: newOption,
        email: newOption,
        created: true
      }
      const newValue = [...value, contact]
      onChange(newValue)
    }
  }

  return (
    <>
      <CustomSelect
        id="testid_contacts_select"
        className={multi ? s.multiContainer : s.container}
        name="contacts-select"
        placeholder={placeholder}
        value={Array.isArray(value) ? value : value && value.id ? value : null}
        onChange={onChange}
        loadOptions={partialDebouncedLoadOptions}
        isClearable={!multi}
        isMulti={multi}
        {...{ cacheOptions: cache ? true : false }}
        {...(createable ? createableProps : nonCreateableProps)}
        {...(filterOptions ? { filterOption: filterOptions } : {})}
        components={
          isRevisedVendorContactsView
            ? { Option, MultiValueLabel, SingleValue }
            : { Option, MultiValueLabel }
        }
        styles={isPortal ? { menuPortal: base => ({ ...base, zIndex: 9999 }) } : {}}
        menuPortalTarget={isPortal ? document.body : null}
        defaultOptions
      />
    </>
  )
}

ContactsSelect.defaultProps = {
  placeholder: 'Select a contact...',
  maxHeight: '300px',
  cache: true,
  isPortal: true
}

export default connect(({ app, contacts }) => {
  let { scope } = app
  const { roles } = contacts

  return { scope: scope.scope, scopeId: scope.id, roleSet: roles }
})(ContactsSelect)
