import { memo } from 'react'
import s from './Filters.scss'
import cn from 'classnames'
import { format } from 'date-fns'
import { Filter } from './Filter'
import { DropdownPopper } from 'common/DropdownPopper'
import { CheckboxList, Users } from 'common/Selects'
import { FilterOption, Filters as FiltersType, EventFilters, Option, DateFormat } from '../types'
import { toNamesOptions, toEventIdsOptions, toMattersOptions } from '../serializers'
import Select, { StylesConfig } from 'react-select'
import { DateRangePopper } from 'common/DateRangePopper'
import { DueDatePicker } from 'common/DueDatePicker'
import { DATE_FORMATS } from 'utils/constants'
import { OPERATORS } from '../constants'
import isEqual from 'lodash/isEqual'
import { TimePicker } from 'common/TimePicker'
import { timezoneUtils } from 'utils/helpers'

const {
  IS,
  IS_NOT,
  IS_SET,
  IS_NOT_SET,
  IS_BEFORE,
  IS_AFTER,
  IS_BETWEEN,
  WITHOUT_ATTACHMENTS,
  WITH_ATTACHMENTS,
  WITHOUT_COMMENTS,
  WITH_COMMENTS
} = OPERATORS

const { DEFAULT_DATE_FNS } = DATE_FORMATS

const userTimezone = timezoneUtils.getUserTimezoneLabel()

const customStyle: StylesConfig<FilterOption, false> = {
  control: (provided, state) => {
    if (state.hasValue) {
      return {
        ...provided,
        border: '1px solid #3c99fd',
        '&:hover': {
          borderColor: '#3c99fd'
        },
        '&:focus': {
          borderColor: '#3c99fd'
        },
        boxShadow: 'none'
      }
    }
    return {
      ...provided,
      boxShadow: 'none'
    }
  },
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  singleValue: (provided, state) => {
    if (!state.data) return provided
    return {
      ...provided,
      color: '#3c99fd'
    }
  }
}

export const formatDate = (date: number | string | undefined): string => {
  if (date === undefined) return ''
  return format(new Date(date), DEFAULT_DATE_FNS)
}

interface Props {
  className?: string
  eventFilters: EventFilters
  setEventFilters: (filters: EventFilters) => void
  clearFilters: () => void
  scopeId: string
  context?: 'workbench' | 'matter'
  baseUrl: string
  hasCalendarRules: boolean | null
}
interface InjectedProps {
  setFilters: (values: FilterOption[]) => void
  filters: FiltersType | null
}

const getPickerValue = (filters: InjectedProps['filters'], index: number) => {
  if (filters?.values && filters.values[index]) {
    return formatDate(filters.values[index].value) as DateFormat | undefined | Date
  }
  return undefined
}

const Filters = memo(
  ({
    className,
    eventFilters,
    setEventFilters,
    clearFilters,
    scopeId,
    context,
    baseUrl,
    hasCalendarRules
  }: Props) => {
    return (
      <div className={cn(s.filters, className)}>
        <Filter
          label="Event Name"
          value={eventFilters.id}
          onConfirm={(value, cb) => {
            setEventFilters({ ...eventFilters, id: value })
            cb?.()
          }}
          operatorsList={[
            {
              label: 'Is',
              value: IS
            },
            {
              label: 'Is not',
              value: IS_NOT
            }
          ]}
        >
          {({ filters, setFilters }: InjectedProps) => (
            <DropdownPopper
              value={filters?.values ?? []}
              placeholder="Select event(s)"
              label="event"
            >
              <CheckboxList
                url={`${baseUrl}/events/?is_external=is_not_set`}
                value={filters?.values ?? []}
                onConfirm={setFilters}
                isPaginated
                firstPageIndex={1}
                secondaryColumn="eventId"
                serialiser={toNamesOptions}
              />
            </DropdownPopper>
          )}
        </Filter>
        {context === 'workbench' && (
          <Filter
            label="Matter Name"
            value={eventFilters.relatedMatter ?? null}
            onConfirm={(value, cb) => {
              setEventFilters({ ...eventFilters, relatedMatter: value })
              cb?.()
            }}
            operatorsList={[
              {
                label: 'Is',
                value: IS
              },
              {
                label: 'Is not',
                value: IS_NOT
              }
            ]}
          >
            {({ filters, setFilters }: InjectedProps) => (
              <DropdownPopper
                value={filters?.values ?? []}
                placeholder="Select matter(s)"
                label="matter"
              >
                <CheckboxList
                  url="/manage/matters/v2/simple_matter_list/"
                  value={filters?.values ?? []}
                  onConfirm={setFilters}
                  isPaginated
                  serialiser={toMattersOptions}
                  secondaryColumn="clientMatterId"
                  secondaryColumnPosition="below"
                />
              </DropdownPopper>
            )}
          </Filter>
        )}
        <Filter
          label="Event Id"
          value={eventFilters.eventId}
          onConfirm={(value, cb) => {
            setEventFilters({ ...eventFilters, eventId: value })
            cb?.()
          }}
          operatorsList={[
            {
              label: 'Is',
              value: IS
            },
            {
              label: 'Is not',
              value: IS_NOT
            }
          ]}
        >
          {({ filters, setFilters }: InjectedProps) => (
            <DropdownPopper
              value={filters?.values ?? []}
              placeholder="Select event id(s)"
              label="id"
            >
              <CheckboxList
                url={`${baseUrl}/events/`}
                value={filters?.values ?? []}
                isPaginated
                firstPageIndex={1}
                onConfirm={setFilters}
                serialiser={toEventIdsOptions}
              />
            </DropdownPopper>
          )}
        </Filter>
        <Filter
          label="Attendee"
          value={eventFilters.attendees}
          onConfirm={(value, cb) => {
            setEventFilters({ ...eventFilters, attendees: value })
            cb?.()
          }}
        >
          {({ filters, setFilters }: InjectedProps) => (
            <DropdownPopper
              value={filters?.values ?? []}
              placeholder="Select attendee(s)"
              label="attendee"
            >
              <Users
                value={(filters?.values as Option[]) ?? []}
                style={{ width: '400px' }}
                requestParams={{ matterId: +scopeId, canEdit: true, active: true }}
                onConfirm={setFilters}
              />
            </DropdownPopper>
          )}
        </Filter>
        <Filter
          label="Date"
          value={eventFilters.date}
          onConfirm={(value, cb) => {
            setEventFilters({ ...eventFilters, date: value })
            cb?.()
          }}
          validation={(value: FiltersType | null) => {
            // return true and let the filter default validation take care of it
            if (!value?.operator || !value.values) return true
            if (value.operator.value === IS_BETWEEN && value.values?.length < 2) {
              return false
            }
            return true
          }}
          operatorsList={[
            {
              label: 'Is',
              value: IS
            },
            {
              label: 'Is between',
              value: IS_BETWEEN
            },
            {
              label: 'Is before',
              value: IS_BEFORE
            },
            {
              label: 'Is after',
              value: IS_AFTER
            },
            {
              label: 'Is set',
              value: IS_SET
            },
            {
              label: 'Is not set',
              value: IS_NOT_SET
            }
          ]}
          customLabel={() => {
            const filters = eventFilters.date
            if (!filters) return 'Date'
            switch (filters.operator?.value) {
              case IS:
                return `Event(s) on ${formatDate(filters.values?.[0].value)}`
              case IS_BETWEEN:
                return `Event(s) between ${formatDate(filters.values?.[0].value)} - ${formatDate(
                  filters.values?.[1].value
                )}`
              case IS_AFTER:
                return `Event(s) after ${formatDate(filters.values?.[0].value)}`
              case IS_BEFORE:
                return `Event(s) before ${formatDate(filters.values?.[0].value)}`
              case IS_SET:
                return 'Date is set'
              case IS_NOT_SET:
                return 'Date is not set'
              default:
                return 'Date'
            }
          }}
        >
          {({ filters, setFilters }: InjectedProps) => {
            return (
              <DateRangePopper value={filters?.values ?? []}>
                <DueDatePicker
                  value={getPickerValue(filters, 0)}
                  onConfirm={(value: DateFormat | undefined | Date) => {
                    setFilters([
                      ...(value
                        ? [
                            {
                              value: value?.toString() ?? '',
                              label: 'Start Date'
                            }
                          ]
                        : []),
                      ...(filters?.values?.[1] ? [filters?.values?.[1]] : [])
                    ])
                  }}
                />
                {filters?.operator?.value === IS_BETWEEN ? (
                  <DueDatePicker
                    value={getPickerValue(filters, 1)}
                    onConfirm={(value: DateFormat | undefined | Date) => {
                      setFilters([
                        ...(filters?.values?.[0] ? [filters?.values?.[0]] : []),
                        ...(value
                          ? [
                              {
                                value: value?.toString() ?? '',
                                label: 'End Date'
                              }
                            ]
                          : [])
                      ])
                    }}
                  />
                ) : null}
              </DateRangePopper>
            )
          }}
        </Filter>
        <Filter
          label="Time"
          value={eventFilters.time}
          onConfirm={(value, cb) => {
            setEventFilters({ ...eventFilters, time: value })
            cb?.()
          }}
          validation={(value: FiltersType | null) => {
            // return true and let the filter default validation take care of it
            if (!value?.operator || !value.values) return true
            if (value.operator.value === IS_BETWEEN && value.values?.length < 2) {
              return false
            }
            return true
          }}
          operatorsList={[
            {
              label: 'Is between',
              value: IS_BETWEEN
            },
            {
              label: 'Is before',
              value: IS_BEFORE
            },
            {
              label: 'Is after',
              value: IS_AFTER
            },
            {
              label: 'Is set',
              value: IS_SET
            },
            {
              label: 'Is not set',
              value: IS_NOT_SET
            }
          ]}
          customLabel={() => {
            const filters = eventFilters.time
            if (!filters) return 'Time'
            switch (filters.operator?.value) {
              case IS_BETWEEN:
                return `Time is between ${format(
                  new Date(`1970-01-01T${filters.values?.[0].value}`),
                  'h:mm a'
                )} - ${format(
                  new Date(`1970-01-01T${filters.values?.[1].value}`),
                  'h:mm a'
                )} ${userTimezone}`
              case IS_AFTER:
                return `Time is after ${format(
                  new Date(`1970-01-01T${filters.values?.[0].value}`),
                  'h:mm a'
                )} ${userTimezone}`
              case IS_BEFORE:
                return `Time is before ${format(
                  new Date(`1970-01-01T${filters.values?.[0].value}`),
                  'h:mm a'
                )} ${userTimezone}`
              case IS_SET:
                return 'Time is set'
              case IS_NOT_SET:
                return 'Time is not set'
              default:
                return 'Time'
            }
          }}
        >
          {({ filters, setFilters }: InjectedProps) => {
            return (
              <DropdownPopper
                value={() => {
                  if (filters?.values && filters.values.length > 0) {
                    return filters.values.length > 1 && filters.operator?.value === IS_BETWEEN
                      ? filters.values
                          .map(item => format(new Date(`1970-01-01T${item.value}`), 'h:mm a'))
                          .join(' and ') +
                          ' ' +
                          userTimezone
                      : format(new Date(`1970-01-01T${filters.values[0].value}`), 'h:mm a') +
                          ' ' +
                          userTimezone
                  }
                  return null
                }}
                placeholder="Select time"
                label="time"
                floatingStyles={{ overflow: 'initial' }}
              >
                <TimePicker
                  onConfirm={(values: (string | null)[]) => {
                    setFilters([
                      ...(values[0] ? [{ value: values[0] ?? '', label: 'Start Time' }] : []),
                      ...(values[1] ? [{ value: values[1] ?? '', label: 'End Time' }] : [])
                    ])
                  }}
                  isRange={filters?.operator?.value === IS_BETWEEN}
                  values={filters?.values?.map(item => String(item.value)) ?? []}
                  hasDefaultValues={false}
                />
              </DropdownPopper>
            )
          }}
        </Filter>
        <Filter
          label="Type"
          value={eventFilters.eventType}
          onConfirm={(value, cb) => {
            setEventFilters({ ...eventFilters, eventType: value })
            cb?.()
          }}
        >
          {({ filters, setFilters }: InjectedProps) => (
            <DropdownPopper value={filters?.values ?? []} placeholder="Select type(s)" label="type">
              <CheckboxList
                url="/event-management/event-types/"
                value={filters?.values ?? []}
                onConfirm={setFilters}
                firstPageIndex={1}
                isPaginated
              />
            </DropdownPopper>
          )}
        </Filter>
        <Filter
          label="Comments"
          value={eventFilters.comments}
          hasOperators={false}
          onConfirm={(value, cb) => {
            setEventFilters({ ...eventFilters, comments: value })
            cb?.()
          }}
          customLabel={() => {
            const value = eventFilters.comments?.values?.[0]
            return value ? `Events ${value.label.toLowerCase()}` : 'Comments'
          }}
          placeholder="Events"
        >
          {({ filters, setFilters }: InjectedProps) => (
            <Select
              value={filters?.values ?? []}
              onChange={value => {
                setFilters(value ? [value] : [])
              }}
              className={s.select}
              styles={customStyle}
              options={[
                {
                  label: 'With comments',
                  value: WITH_COMMENTS
                },
                {
                  label: 'Without comments',
                  value: WITHOUT_COMMENTS
                }
              ]}
            />
          )}
        </Filter>
        <Filter
          label="Files"
          value={eventFilters.fileAttachments}
          hasOperators={false}
          onConfirm={(value, cb) => {
            setEventFilters({ ...eventFilters, fileAttachments: value })
            cb?.()
          }}
          customLabel={() => {
            const value = eventFilters.fileAttachments?.values?.[0]
            return value ? `Events ${value.label.toLowerCase()}` : 'Files'
          }}
          placeholder="Events"
        >
          {({ filters, setFilters }: InjectedProps) => (
            <Select
              value={filters?.values ?? []}
              onChange={value => {
                setFilters(value ? [value] : [])
              }}
              className={s.select}
              styles={customStyle}
              options={[
                {
                  label: 'With attached files',
                  value: WITH_ATTACHMENTS
                },
                {
                  label: 'Without attached files',
                  value: WITHOUT_ATTACHMENTS
                }
              ]}
            />
          )}
        </Filter>
        <Filter
          placeholder="Created By"
          label="Creator"
          labelPosition="right"
          value={eventFilters.createdBy}
          onConfirm={(value, cb) => {
            setEventFilters({ ...eventFilters, createdBy: value })
            cb?.()
          }}
          operatorsList={[
            {
              label: 'Is',
              value: IS
            },
            {
              label: 'Is not',
              value: IS_NOT
            }
          ]}
          customLabel={
            eventFilters.createdBy?.values && eventFilters.createdBy?.values?.length > 1
              ? undefined
              : () => {
                  const filters = eventFilters.createdBy
                  if (!filters) return 'Created By'
                  switch (filters.operator?.value) {
                    case IS:
                      return `Created by ${filters.values?.[0].label}`
                    case IS_NOT:
                      return `Is not Created by ${filters.values?.[0].label}`
                    default:
                      return 'Created By'
                  }
                }
          }
        >
          {({ filters, setFilters }: InjectedProps) => (
            <DropdownPopper
              value={filters?.values ?? []}
              placeholder="Select creator"
              label="creator"
            >
              <Users
                value={(filters?.values as Option[]) ?? []}
                style={{ width: '400px' }}
                requestParams={{ active: true }}
                onConfirm={setFilters}
              />
            </DropdownPopper>
          )}
        </Filter>
        <Filter
          label="Created Date"
          value={eventFilters.createdDate}
          onConfirm={(value, cb) => {
            setEventFilters({ ...eventFilters, createdDate: value })
            cb?.()
          }}
          validation={(value: FiltersType | null) => {
            // return true and let the filter default validation take care of it
            if (!value?.operator || !value.values) return true
            if (value.operator.value === IS_BETWEEN && value.values?.length < 2) {
              return false
            }
            return true
          }}
          operatorsList={[
            {
              label: 'Is',
              value: IS
            },
            {
              label: 'Is between',
              value: IS_BETWEEN
            },
            {
              label: 'Is before',
              value: IS_BEFORE
            },
            {
              label: 'Is after',
              value: IS_AFTER
            }
          ]}
          customLabel={() => {
            const filters = eventFilters.createdDate
            if (!filters) return 'Created Date'
            switch (filters.operator?.value) {
              case IS:
                return `Created ${formatDate(filters.values?.[0].value)}`
              case IS_BETWEEN:
                return `Created ${formatDate(filters.values?.[0].value)} - ${formatDate(
                  filters.values?.[1].value
                )}`
              case IS_AFTER:
                return `Created after ${formatDate(filters.values?.[0].value)}`
              case IS_BEFORE:
                return `Created before ${formatDate(filters.values?.[0].value)}`
              case IS_SET:
                return 'Created Date is set'
              case IS_NOT_SET:
                return 'Created Date is not set'
              default:
                return 'Created Date'
            }
          }}
        >
          {({ filters, setFilters }: InjectedProps) => {
            return (
              <DateRangePopper value={filters?.values ?? []}>
                <DueDatePicker
                  value={getPickerValue(filters, 0)}
                  onConfirm={(value: DateFormat | undefined | Date) => {
                    setFilters([
                      ...(value
                        ? [
                            {
                              value: value?.toString() ?? '',
                              label: 'Start Date'
                            }
                          ]
                        : []),
                      ...(filters?.values?.[1] ? [filters?.values?.[1]] : [])
                    ])
                  }}
                />
                {filters?.operator?.value === IS_BETWEEN ? (
                  <DueDatePicker
                    value={getPickerValue(filters, 1)}
                    onConfirm={(value: DateFormat | undefined | Date) => {
                      setFilters([
                        ...(filters?.values?.[0] ? [filters?.values?.[0]] : []),
                        ...(value
                          ? [
                              {
                                value: value?.toString() ?? '',
                                label: 'End Date'
                              }
                            ]
                          : [])
                      ])
                    }}
                  />
                ) : null}
              </DateRangePopper>
            )
          }}
        </Filter>
        {hasCalendarRules && (
          <Filter
            label="Court Rule Name"
            value={eventFilters.courtRulesName}
            onConfirm={(value, cb) => {
              setEventFilters({ ...eventFilters, courtRulesName: value })
              cb?.()
            }}
            operatorsList={[
              {
                label: 'Is',
                value: IS
              },
              {
                label: 'Is not',
                value: IS_NOT
              }
            ]}
          >
            {({ filters, setFilters }: InjectedProps) => (
              <DropdownPopper
                value={filters?.values ?? []}
                placeholder="Select court rule(s)"
                label="court rule"
              >
                <CheckboxList
                  url={`/event-management/calendar_rules/court-rule-names/${scopeId}`}
                  value={filters?.values ?? []}
                  onConfirm={setFilters}
                  serialiser={toNamesOptions}
                  isPaginated
                  firstPageIndex={1}
                />
              </DropdownPopper>
            )}
          </Filter>
        )}
        <div className={s.reset} onClick={clearFilters}>
          Reset
        </div>
      </div>
    )
  },
  (prevProps, nextProps) => {
    return (
      isEqual(prevProps.eventFilters, nextProps.eventFilters) &&
      prevProps.hasCalendarRules === nextProps.hasCalendarRules
    )
  }
)

export default Filters
