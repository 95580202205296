import { useState, CSSProperties, useEffect, useMemo } from 'react'
import s from './CalendarView.scss'
import { Calendar, dateFnsLocalizer, DateLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import { CustomHeader } from './CustomHeader'
import cn from 'classnames'
import { makeGetRequest } from 'utils/api'
import { hasModule, sortAlphabeticallyByProperty } from 'utils/helpers'
import { MODULE } from 'utils/constants'
import { useLoading, CheckboxContainer, Popover } from 'simple-core-ui'
import { useDispatch } from 'react-redux'
import { Task, Event } from './types'
import { toTasks, toEvents } from './serializers'
import { AiFillCheckCircle } from 'react-icons/ai'
import { DayPicker } from 'react-day-picker8'
import { BsCalendar2Fill } from 'react-icons/bs'
import { TaskDetails } from './TaskDetails'
import { EventDetails } from './EventDetails'

const locales = {
  'en-US': enUS
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
  dayFormat: (date: Date, culture: string, localizer: DateLocalizer) =>
    localizer.format(date, 'd', culture)
})

const eventStyleGetter = (event: Event) => {
  return {
    className: event.isExternal ? 'externalEvent' : 'internalEvent'
  }
}

const addTaskIcon = (task: Task) => {
  return (
    <>
      <AiFillCheckCircle style={{ fontSize: 18 }} className={s.taskEventIcon} /> {task.title}
    </>
  )
}

const addEventIcon = (event: Event) => {
  return (
    <div style={{ display: 'flex' }}>
      <div className={event.isExternal ? s.ruleIconWrapper : s.eventIconWrapper}>
        <BsCalendar2Fill className={s.eventIcon} />
      </div>
      <p
        style={{
          marginLeft: 5,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {event.title}
      </p>
    </div>
  )
}

const CustomWeekHeader = ({
  date,
  weekdayStyles,
  dayStyles,
  styles = {}
}: {
  date: Date
  weekdayStyles?: CSSProperties
  dayStyles?: CSSProperties
  styles?: CSSProperties
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', ...styles }}>
      <span className={cn('weekday', s.weekday)} style={weekdayStyles}>
        {format(date, 'E').toUpperCase()}
      </span>
      <span className={cn('day', s.day)} style={dayStyles}>
        {format(date, 'dd')}
      </span>
    </div>
  )
}

const DATE_FORMAT = 'yyyy-MM-dd'

const isTasksEnabled = hasModule(MODULE.TASKS)
const isEventsEnabled = hasModule(MODULE.EVENT_MANAGEMENT)

const CalendarView = () => {
  const [date, setDate] = useState(new Date())
  const [currentView, setCurrentView] = useState('month')
  const [, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const [tasks, setTasks] = useState<Task[]>([])
  const [events, setEvents] = useState<Event[]>([])
  const [tasksEventsSelected, setTasksEventsSelected] = useState(true)
  const [eventsSelected, setEventsSelected] = useState(true)
  const [rulesSelected, setRulesSelected] = useState(true)

  const filteredEvents = useMemo(() => {
    if (!eventsSelected && !rulesSelected) {
      return []
    }

    return events.filter(
      event => (eventsSelected && !event.isExternal) || (rulesSelected && event.isExternal)
    )
  }, [events, eventsSelected, rulesSelected])

  const computeEndpoint = (type: string) => {
    const startDate = startOfMonth(date)
    const endDate = endOfMonth(date)
    let endpoint = ''
    const baseUrl = type === 'events' ? '/event-management/events/' : '/task-management/tasks/'

    if (currentView === 'month') {
      endpoint = `${baseUrl}?columnKey=start_date&isDesc=0&start_date=is_between:${format(
        startDate,
        DATE_FORMAT
      )},${format(endDate, DATE_FORMAT)}`
    } else if (currentView === 'week') {
      const firstDayOfWeek = startOfWeek(date)
      const lastDayOfWeek = endOfWeek(date)
      endpoint = `${baseUrl}?columnKey=start_date&isDesc=0&start_date=is_between:${format(
        firstDayOfWeek,
        DATE_FORMAT
      )},${format(lastDayOfWeek, DATE_FORMAT)}`
    } else if (currentView === 'day') {
      endpoint = `${baseUrl}?columnKey=start_date&isDesc=0&start_date=is:${format(
        date,
        DATE_FORMAT
      )}`
    }

    return endpoint
  }

  const fetchEvents = async () => {
    try {
      const endpoint = computeEndpoint('events')

      const { rows } = await withLoadingLocks(makeGetRequest(endpoint))

      const serializedEvents = toEvents(rows, (events: Event[]) => {
        return events.map(t =>
          t?.relatedMatter?.status === 'closed' ? { ...t, canEdit: false } : t
        )
      })
      setEvents(sortAlphabeticallyByProperty(serializedEvents, 'name'))
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const fetchTasks = async () => {
    try {
      const endpoint = computeEndpoint('tasks')

      const { rows } = await withLoadingLocks(makeGetRequest(endpoint))

      const serializedTasks = toTasks(rows, (tasks: Task[]) => {
        return tasks.map(t =>
          t?.relatedMatter?.status === 'closed' ? { ...t, canEdit: false } : t
        )
      })
      setTasks(sortAlphabeticallyByProperty(serializedTasks, 'name'))
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    isTasksEnabled && fetchTasks()
    isEventsEnabled && fetchEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, currentView])

  const handleDaySelect = (date: Date) => {
    setDate(date)
  }

  return (
    <div style={{ display: 'flex' }}>
      <div
        className={cn(s.calendarWrapper, {
          [s.weekView]: currentView === 'week',
          [s.dayView]: currentView === 'day',
          [s.monthView]: currentView === 'month'
        })}
      >
        <Calendar
          localizer={localizer}
          views={['month', 'week', 'day']}
          components={{
            toolbar: props => (
              <CustomHeader
                {...props}
                date={date}
                onNavigate={setDate}
                currentView={currentView}
                setCurrentView={setCurrentView}
              />
            ),
            eventWrapper: (props: any) => {
              return (
                <Popover
                  id={`calendar-event-${props.event.id}`}
                  placement="left"
                  contentClassName={s.popoverContent}
                  hasArrow={false}
                  stayOpenOnClick
                  trigger={props.children}
                  content={
                    props.event.type === 'task' ? (
                      <TaskDetails task={props.event} setTasks={setTasks} />
                    ) : (
                      <EventDetails event={props.event} />
                    )
                  }
                />
              )
            },
            week: {
              header: CustomWeekHeader
            },
            timeGutterHeader: () => {
              if (currentView !== 'day') return null
              return (
                <CustomWeekHeader
                  date={date}
                  styles={{ alignItems: 'center' }}
                  weekdayStyles={{ color: '#0957AE', marginBottom: -1, marginTop: 3 }}
                  dayStyles={{ color: '#0957AE' }}
                />
              )
            }
          }}
          date={date}
          onNavigate={setDate}
          startAccessor="start"
          endAccessor="end"
          events={tasksEventsSelected ? [...tasks, ...filteredEvents] : filteredEvents}
          popup
          // @ts-expect-error
          titleAccessor={e => (e.type === 'task' ? addTaskIcon(e) : addEventIcon(e))}
          allDayMaxRows={3}
          eventPropGetter={e => (e.type === 'event' ? eventStyleGetter(e as Event) : {})}
          formats={{
            dateFormat: 'd',
            timeGutterFormat: (date, culture, localizer) =>
              localizer ? localizer.format(date, 'h a', culture) : ''
          }}
        />
      </div>
      <div className={s.dayPicker}>
        <DayPicker
          mode="single"
          defaultMonth={date}
          selected={date}
          onSelect={date => (date ? handleDaySelect(date) : null)}
          modifiersStyles={{
            selected: { background: '#E2F0FF', border: '2px solid #3C98FD', color: '#000000' }
          }}
        />
        <div className={s.calendars}>
          <p className={s.label}>Calendars</p>
          {isTasksEnabled && (
            <div style={{ display: 'flex', marginBottom: 20 }}>
              <CheckboxContainer
                className={cn(s.tasksCheckbox, tasksEventsSelected && s.selected)}
                size="md"
                isChecked={tasksEventsSelected}
                cb={() => setTasksEventsSelected(!tasksEventsSelected)}
              />
              <div style={{ marginRight: 5 }}>
                <AiFillCheckCircle className={s.taskEventIcon} />
              </div>
              <span
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  color: '#047857',
                  position: 'relative',
                  top: 3
                }}
              >
                Tasks
              </span>
            </div>
          )}
          {isEventsEnabled && (
            <>
              <div style={{ display: 'flex', marginBottom: 20 }}>
                <CheckboxContainer
                  className={cn(s.eventsCheckbox, eventsSelected && s.selected)}
                  size="md"
                  isChecked={eventsSelected}
                  cb={() => setEventsSelected(!eventsSelected)}
                />
                <div
                  style={{ position: 'relative', top: 3, marginRight: 5 }}
                  className={s.eventIconWrapper}
                >
                  <BsCalendar2Fill className={s.eventIcon} />
                </div>
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    color: '#0369A1',
                    position: 'relative',
                    top: 3
                  }}
                >
                  Events
                </span>
              </div>
              <div style={{ display: 'flex' }}>
                <CheckboxContainer
                  className={cn(s.rulesCheckbox, rulesSelected && s.selected)}
                  size="md"
                  isChecked={rulesSelected}
                  cb={() => setRulesSelected(!rulesSelected)}
                />
                <div
                  style={{ position: 'relative', top: 3, marginRight: 5 }}
                  className={s.ruleIconWrapper}
                >
                  <BsCalendar2Fill className={s.eventIcon} />
                </div>
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    color: '#F59E0B',
                    position: 'relative',
                    top: 3
                  }}
                >
                  Court Rules
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CalendarView
