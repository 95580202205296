import { useState } from 'react'
import { ModalContainer } from 'simple-core-ui'
import { UsersCheckboxSelect } from 'common/Selects'
import { Option, User } from '../types'

interface Props {
  toggleAddUserModal: () => void
  onSave: (users: Option[] | User[]) => void
  scopeId: number
  view: 'fileSharing' | 'courtRules'
  scope: string
}

const AddUserModal = ({ toggleAddUserModal, onSave, scopeId, view, scope }: Props) => {
  const [users, setUsers] = useState<Option[] | User[]>([])

  const isCourtRulesView = view === 'courtRules'

  return (
    <ModalContainer
      title="Add User"
      subtitle={
        isCourtRulesView
          ? 'Choose who receives notifications when Events with Court Rules are updated'
          : 'The selected user will get emails for files shared by the vendor'
      }
      hasNewButtons
      cancelText="Cancel"
      size={isCourtRulesView ? 'md' : 'lg'}
      cancelCb={toggleAddUserModal}
      confirmText={isCourtRulesView ? 'Save' : 'Add'}
      contentStyle={{ minHeight: 'auto' }}
      confirmCb={() => {
        onSave(users)
      }}
      isDisabled={!users.length}
      content={
        <UsersCheckboxSelect
          value={users as Option[]}
          requestParams={{ active: true, matterId: scopeId, canEdit: true }}
          updateSelectedUsers={(values: Option[]) => {
            setUsers(values)
          }}
          scope={scope}
        />
      }
    />
  )
}

export default AddUserModal
