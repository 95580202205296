import { useRef, useEffect } from 'react'
import s from './Subtasks.scss'
import { MdAddTask } from 'react-icons/md'
import { AiOutlineCheckCircle, AiFillCheckCircle } from 'react-icons/ai'
import { Subtask, DateFormat, RelativeDueDate, Option, Task } from './types'
import shortid from 'shortid'
import { AvatarList, Button, CharLimitInput, UpdateToPremiumLabel } from 'simple-core-ui'
import cn from 'classnames'
import { useImmer } from 'use-immer'
import { ActionsPopover } from './ActionsPopover'
import { Widget } from 'common/Widget'
import { Users } from 'common/Selects'
import { BsPersonPlus } from 'react-icons/bs'
import { FaRegCalendarCheck } from 'react-icons/fa'
import { DueDatePicker } from 'common/DueDatePicker'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import { IoIosClose } from 'react-icons/io'
import { MAX_NAME_LENGTH, MAX_SUBTASKS_IN_VIEW } from './constants'
import { sortAlphabeticallyByProperty } from 'utils/helpers'
import ReactTooltip from 'react-tooltip'

interface Props {
  value: Subtask[]
  onDelete?: (id: number) => void
  onSave: (value: Subtask[]) => void
  onEditSubtask?: (task: Subtask) => void
  duplicateTask?: (task: Task | Subtask, parentId?: number | string) => Promise<void>
  isEdit?: boolean
  parentId?: number | string
  scopeId?: string
  canMarkCompleted?: boolean
  readOnly?: boolean
  customDatePicker?: {
    render: (
      value: Subtask['relativeDueDate'],
      onConfirm: (value: Subtask['relativeDueDate']) => void
    ) => JSX.Element
    props: { customLabel?: (value: Subtask['relativeDueDate']) => string }
  }
  limit?: number
  isPremium?: boolean
  hasTooltip?: boolean
  tooltipText?: string
  disabledUsersWidget?: boolean
  disabledUsersWidgetTooltipText?: string
}

const Subtasks = ({
  value,
  onSave,
  onDelete,
  onEditSubtask,
  isEdit = false,
  duplicateTask,
  parentId,
  scopeId,
  canMarkCompleted = true,
  readOnly,
  customDatePicker,
  limit,
  hasTooltip,
  tooltipText = 'Contact customer success to upgrade.',
  disabledUsersWidget,
  disabledUsersWidgetTooltipText,
  isPremium
}: Props) => {
  const [subtasks, setSubtasks] = useImmer<Subtask[]>(value)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (value && subtasks !== value) {
      const draftSubtasks = subtasks.filter(
        subtask => subtask.draft && !value.some(task => task.id === subtask.id)
      )
      const currentValue = [...value, ...draftSubtasks]
      setSubtasks(currentValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const changeProperty = (
    value: string | boolean | DateFormat | undefined | Option[] | Date | RelativeDueDate,
    property: keyof Subtask,
    i: number
  ) => {
    setSubtasks(draft => {
      draft[i] = {
        ...draft[i],
        [property]: value
      }
    })
  }

  useEffect(() => {
    if (!isEdit) {
      subtasks.forEach((subtask, i) => {
        if (subtask.draft) {
          changeProperty([], 'assignees', i)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopeId])

  const addDraftSubtask = () => {
    setSubtasks([
      ...subtasks,
      {
        id: shortid.generate(),
        name: '',
        completed: false,
        draft: true,
        assignees: [],
        dueDate: undefined,
        isNew: true
      }
    ])
  }

  const deleteSubtask = (i: number) => {
    if (onDelete && !subtasks[i].draft) {
      onDelete(+subtasks[i].id)
    } else {
      setSubtasks(draft => {
        draft.splice(i, 1)
      })
    }
  }

  const toggleDraft = (i: number) => {
    changeProperty(true, 'draft', i)
  }

  const getUsersLabel = (subtask: Subtask): string | JSX.Element => {
    switch (subtask.assignees?.length) {
      case 0:
      case 1:
        return ''
      default:
        return (
          <AvatarList
            limit={3}
            size="xs"
            avatarStyles={{ background: '#0957ae', marginLeft: 0, fontSize: 9 }}
            entries={sortAlphabeticallyByProperty(subtask.assignees, 'label')}
            className={s.avatar}
          />
        )
    }
  }

  const getUsersIcon = (subtask: Subtask): JSX.Element => {
    if (subtask.assignees.length === 1) {
      return (
        <AvatarList
          size="xs"
          wrapperStyles={{
            width: 24,
            position: 'relative',
            bottom: 2,
            right: 2,
            minWidth: 'initial'
          }}
          className={s.avatar}
          entries={[subtask.assignees[0]]}
          avatarStyles={{
            border: '2px solid #0957ae',
            lineHeight: '18px',
            background: '#0957ae',
            fontSize: 9
          }}
        />
      )
    }

    return <BsPersonPlus />
  }

  const errorObject = (task: Subtask) => {
    let hasError = false
    let errorMessage = 'Maximum character limit reached'

    if (!task.name) {
      hasError = true
      errorMessage = 'Name is required.'
    }
    if (task.name && task.name[0] === ' ') {
      hasError = true
      errorMessage = 'Name cannot begin with a space.'
    }

    return { hasError, errorMessage }
  }

  const getRegularDueDateLabel = (dueDate: Subtask['dueDate']) => {
    return (
      dueDate &&
      moment(dueDate as DateFormat).format(
        moment(dueDate as DateFormat).isSame(new Date(), 'year') ? 'MMM DD' : 'MMM DD, YYYY'
      )
    )
  }

  const getDueDateLabel = ({ dueDate, relativeDueDate }: Subtask) =>
    !customDatePicker || !customDatePicker.props.customLabel
      ? getRegularDueDateLabel(dueDate)
      : customDatePicker.props.customLabel(relativeDueDate)

  return (
    <>
      <div className={s.container}>
        {subtasks.length > 0 && <label className={s.label}>{subtasks.length} subtasks</label>}
        <div
          className={cn(s.listWrapper, {
            [s.editable]: isEdit
          })}
        >
          {subtasks.map((subtask, i) => (
            <div
              key={subtask.id}
              className={cn(s.subtask, {
                [s.draft]: subtask.draft,
                [s.error]: subtask.name.length === MAX_NAME_LENGTH && subtask.draft
              })}
            >
              <div
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  ...(isEdit && !subtask.draft ? { maxWidth: '57%' } : {})
                }}
              >
                {canMarkCompleted && (
                  <>
                    {subtask.completed ? (
                      <AiFillCheckCircle
                        className={cn(s.markAsCompleteIcon, s.complete)}
                        onClick={() => {
                          if (!subtask.draft && isEdit) return
                          changeProperty(false, 'completed', i)
                        }}
                      />
                    ) : (
                      <AiOutlineCheckCircle
                        className={cn(s.markAsCompleteIcon, {
                          [s.editable]: subtask.draft || !isEdit
                        })}
                        onClick={() => {
                          if (!subtask.draft && isEdit) return
                          changeProperty(true, 'completed', i)
                        }}
                      />
                    )}
                  </>
                )}
                <div style={{ width: '95%', marginRight: '10px', marginLeft: '5px' }}>
                  {subtask.draft ? (
                    <CharLimitInput
                      value={subtask.name}
                      placeholder="Subtask name"
                      onChangeCb={e => changeProperty(e.target.value, 'name', i)}
                      maxLength={MAX_NAME_LENGTH}
                      hasError={errorObject(subtask).hasError}
                      customErrorMessage={errorObject(subtask).errorMessage}
                      dynamicCharCalculation
                      focused
                      refObject={inputRef}
                    />
                  ) : !subtask.draft && isEdit ? (
                    <a className={s.name} onClick={() => onEditSubtask?.(subtask)}>
                      {subtask.name}
                    </a>
                  ) : (
                    <p className={s.name}>{subtask.name}</p>
                  )}
                </div>
              </div>
              <div style={{ display: 'flex', position: 'relative', top: '2px' }}>
                <>
                  {isEdit && <span className={s.taskId}>{subtask.taskId}</span>}
                  <Widget
                    size="sm"
                    value={getUsersLabel(subtask)}
                    icon={getUsersIcon(subtask)}
                    style={{ marginRight: '8px', position: 'relative', top: '2px' }}
                    iconClassName={s.widgetIcon}
                    portal={true}
                    placement="left"
                    readOnly={!subtask.draft && isEdit}
                    hideIcon={!!getUsersLabel(subtask)}
                    contentClassName={s.widgetContent}
                    isDisabled={disabledUsersWidget}
                    hasTooltip={disabledUsersWidget}
                    tooltipText={disabledUsersWidgetTooltipText}
                  >
                    <Users
                      value={subtask.assignees}
                      style={{ width: '400px' }}
                      requestParams={{
                        active: true,
                        ...(scopeId ? { matterId: +scopeId, canEdit: true } : {})
                      }}
                      onConfirm={(values: Option[]) => {
                        changeProperty(values, 'assignees', i)
                      }}
                    />
                  </Widget>
                  <Widget
                    size="sm"
                    icon={<FaRegCalendarCheck />}
                    style={{ marginRight: '5px', position: 'relative', top: '2px' }}
                    value={getDueDateLabel(subtask)}
                    iconClassName={s.widgetIcon}
                    readOnly={!subtask.draft && isEdit}
                    portal={true}
                    placement="top-end"
                    contentClassName={s.widgetContent}
                  >
                    {!customDatePicker ? (
                      <DueDatePicker
                        onConfirm={(value: Date | DateFormat | undefined) => {
                          changeProperty(value, 'dueDate', i)
                        }}
                        value={subtask.dueDate}
                      />
                    ) : (
                      customDatePicker.render(subtask.relativeDueDate, value =>
                        changeProperty(value, 'relativeDueDate', i)
                      )
                    )}
                  </Widget>
                  {subtask.draft ? (
                    <Button
                      style={{
                        background: '#0957ae',
                        color: '#fff',
                        height: '25px',
                        position: 'relative',
                        top: 2
                      }}
                      onClick={() => {
                        changeProperty(false, 'draft', i)
                        const arr = cloneDeep(subtasks)
                        arr[i].draft = false
                        onSave?.(arr)
                      }}
                      isDisabled={!subtask.name || subtask.name[0] === ' '}
                    >
                      Save
                    </Button>
                  ) : isEdit && !readOnly ? (
                    <ActionsPopover
                      subtask={onEditSubtask ? subtask : null}
                      parentId={parentId}
                      index={i}
                      deleteSubtask={deleteSubtask}
                      duplicateTask={duplicateTask}
                      toggleDraft={toggleDraft}
                      onEditSubtask={onEditSubtask}
                    />
                  ) : null}
                  {(!isEdit || (isEdit && subtask.draft)) && (
                    <IoIosClose onClick={() => deleteSubtask(i)} className={s.remove} />
                  )}
                </>
              </div>
            </div>
          ))}
        </div>
        {!readOnly && (
          <>
            <p
              className={cn(s.addWrapper, {
                [s.fixed]: isEdit && subtasks.length > MAX_SUBTASKS_IN_VIEW,
                [s.disabled]: limit && subtasks.length >= limit
              })}
              onClick={limit && subtasks.length >= limit ? undefined : addDraftSubtask}
            >
              <span data-for="upgradeTier" data-tip>
                <MdAddTask className={s.addIcon} /> Add a subtask
                {limit && subtasks.length >= limit && isPremium === false && (
                  <UpdateToPremiumLabel />
                )}
              </span>
            </p>
            {hasTooltip && limit && subtasks.length >= limit && (
              <ReactTooltip id="upgradeTier" type="light" effect="solid" place="top" border>
                {tooltipText}
              </ReactTooltip>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default Subtasks
