import clone from 'lodash/clone'
import get from 'lodash/get'
import set from 'lodash/set'
import isNull from 'lodash/isNull'
import qs from 'query-string'
import update from 'immutability-helper'
import moment from 'moment'

import { sortAlphabeticallyByProperty } from 'utils/helpers'
import { CONDITIONS_TO_INCLUDE } from './utils/constants'
import { AvailableField, Category, Field, Func, Model, Operator } from './@types/api'
import { OperatorByType } from './@types/common'
import { Filters, SerializedParams } from './@types/list'
import { getActiveRuleModules } from 'rules/utils'

const isCustomField = ({ field_type }: Field) => field_type === 'custom'

const getChoicesByType = (models: Array<Model>) => {
  const choicesByType: Partial<Record<
    string,
    Array<{ attr_id: string; model_name: string; label: string; value: string }>
  >> = {}

  for (const model of models) {
    for (const field of model.fields) {
      if (isCustomField(field)) {
        if (choicesByType[field.type] === undefined) choicesByType[field.type] = []

        const choiceLabel = `${model.display_name} · ${field.display_name}`

        choicesByType[field.type]!.push({
          attr_id: field.name,
          model_name: model.name,
          label: choiceLabel,
          value: choiceLabel
        })
      }
    }
  }

  return choicesByType
}

const addOtherAttributesAsChoices = (models: Array<Model>): Array<AvailableField> => {
  const choicesByType = getChoicesByType(models)
  const newValue = clone(models)

  for (const [modelIndex, model] of newValue.entries()) {
    for (const [fieldIndex, field] of model.fields.entries()) {
      if (isNull(field.choices) && isCustomField(field)) {
        update(newValue, {
          [modelIndex]: {
            fields: {
              [fieldIndex]: {
                choices: {
                  $set: get(choicesByType, field.type, []).filter(
                    ({ attr_id, model_name }) =>
                      !(model.name === model_name && field.name === attr_id)
                  )
                }
              }
            }
          }
        })
      }
    }
  }

  return newValue
}

export const toAvailableFields = (
  funcs: Array<Func>,
  models: Array<Model>
): Array<AvailableField> => {
  const fields = [
    ...funcs.map(func => ({ ...func, isFunction: true })),
    ...addOtherAttributesAsChoices(models)
  ]

  const filtered = fields
    .filter(field => get(CONDITIONS_TO_INCLUDE, field.name))
    .map(field => {
      const obj = clone(field)
      obj.display_name = get(CONDITIONS_TO_INCLUDE, field.name)
      return obj
    })

  const sorted = sortAlphabeticallyByProperty(filtered, 'display_name') as Array<AvailableField>

  return sorted
}

export const toOperatorsByType = (operators: Array<Operator>): OperatorByType => {
  const operatorsByType = {} as OperatorByType

  const opsToInclude: Record<string, Array<string>> = {
    number: ['is not', '<', '>', 'is null', 'is not null'],
    currency: ['is not', '<', '<=', '>', '>=']
  }

  for (const op of operators) {
    for (const type of op.types) {
      if (operatorsByType[type] === undefined) operatorsByType[type] = []

      if (!opsToInclude[type] || opsToInclude[type].includes(op.symbol)) {
        operatorsByType[type]!.push(op)
      }
    }
  }
  return operatorsByType
}

export const toCategories = (categories: Category[]) =>
  categories.map(c => ({
    value: c.id,
    label: c.name
  }))

export const getRuleTypeFilter = (): 'iiw' | 'ai' | undefined => {
  const activeRuleModules = getActiveRuleModules()
  if (activeRuleModules.hasAI && activeRuleModules.hasIIW) return undefined
  if (activeRuleModules.hasAI) return 'ai'
  if (activeRuleModules.hasIIW) return 'iiw'
}

export const serializeParams = (params: Filters): SerializedParams => {
  const {
    pageSize,
    page,
    search,
    ordering,
    modifiedBy,
    conditions,
    actions,
    ruleCategory,
    rules,
    category
  } = params

  interface LastModified {
    value: {
      min: Date | ''
      max: Date | ''
    }
  }

  const lastModified = (params.lastModified as unknown) as LastModified | undefined

  interface AllRulesParams {
    pageSize: number
    page: number
    engine: string
    search: string
    columnKey: string
    isDesc: 0 | 1
    rule_type?: 'ai' | 'iiw'
    modified_by_ids?: Array<number>
    conditions?: Array<string>
    categories_ids?: Array<number>
    actions?: Array<string>
    is_draft?: 0 | 1
    rules_ids?: Array<number>
    start_date?: string
    end_date?: string
  }

  const newParams: AllRulesParams = {
    pageSize,
    page,
    engine: 'invoice_validation',
    search: search,
    columnKey: ordering.columnKey,
    isDesc: ordering.isDesc ? 1 : 0,
    ...(modifiedBy
      ? {
          modified_by_ids: modifiedBy.map(m => m.value)
        }
      : {}),
    ...(conditions
      ? {
          conditions: conditions.map(m => m.value)
        }
      : {}),
    ...(ruleCategory
      ? {
          categories_ids: ruleCategory.map(m => m.value)
        }
      : {}),
    ...(actions
      ? {
          actions: actions.map(m => m.value)
        }
      : {}),
    ...(category !== 'All'
      ? {
          is_draft: category === 'Active' ? 0 : 1
        }
      : {}),
    ...(rules
      ? {
          rules_ids: rules?.map(r => r.value)
        }
      : {}),
    rule_type: getRuleTypeFilter()
  }

  if (lastModified && moment(lastModified.value.min).isValid()) {
    newParams.start_date = moment(lastModified.value.min).format('YYYY-MM-DD')
  }
  if (lastModified && moment(lastModified.value.max).isValid()) {
    const formattedValue = moment(lastModified.value.max)
      .add(1, 'day')
      .format('YYYY-MM-DD')
    /**
     * Set end_date value only if it's different from start_date, otherwise it would show no results.
     * E.g.: The user selects "Today" from the Calendar input.
     */
    if (formattedValue !== newParams.start_date) {
      newParams.end_date = formattedValue
    }
  }

  return newParams
}

export const flattenObject = (
  obj: Record<string, unknown>,
  temp?: Record<string, unknown>,
  parentKey?: string
): Record<string, unknown> => {
  const flat = temp || {}

  Object.entries(obj).forEach(([key, value]) => {
    const k = parentKey ? `${parentKey}.${key}` : key
    if (value && typeof value === 'object' && !moment.isDate(value)) {
      flattenObject(value as Record<string, unknown>, flat, k)
    } else {
      flat[k] = moment.isDate(value) ? value.toISOString() : value
    }
  })

  return flat
}

export const encodeFilterSearch = (filterSearch: Filters): string => {
  const flat = flattenObject({ ...filterSearch })
  return qs.stringify(flat)
}

export const decodeFilterSearch = (searchParams: string): Filters => {
  const parsed = qs.parse(searchParams, { parseBooleans: true, parseNumbers: true })
  const temp = {}
  for (const key in parsed) {
    set(temp, key, parsed[key])
  }
  return temp as Filters
}
